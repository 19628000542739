<template>
    <div class="modal fade" id="modalNotification" tabindex="-1" aria-labelledby="modal_modalNotification_label" aria-hidden="true">
        <div class="modal-dialog change-modal modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="h4 modal-title">{{ notification.title }}</h5>
                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <span class="fm-close"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="warning-text" v-html="notification.message"></div>
                </div>
                <div class="modal-footer modal-footer_center">
                    <button @click="setNotificationSeen" data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Ок</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['notifications'],

    data() {
        return {
            show: true,
            notification: {}
        }
    },

    methods: {
        setNotificationSeen(){
            let self = this;
            axios.post(this.route('account.notificationSetSeen'), {id: this.notification.id})
                .then(() => {

                })
                .catch((error) => {

                });
        },
        jsInit(){
            let self = this;
            $(document).off('hidden.bs.modal', '#modalNotification');
            $(document).on('hidden.bs.modal', '#modalNotification', function (e) {
                self.setNotificationSeen();
            })
        }
    },
    watch: {
        notifications(newValue) {
            this.notification = newValue[0] ?? {};

            if (this.notification.id)
                $('#modalNotification').modal();
        },
    },
    mounted() {
        this.jsInit();
    }
}
</script>
