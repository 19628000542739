<template>
    <span class="whitespace-nowrap">{{formattedValue}}</span>
</template>

<script>
import numeral from 'numeral'

export default {
    name: 'Percent',
    props: {
        value: {
            type: Number,
            required: true
        },
    },
    computed: {
        formattedValue() {
            numeral.locale('sys_per');

            return numeral(this.value).format('0,0.00 $');
        }
    },
}
</script>

<style scoped>

</style>
