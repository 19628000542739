<template>
    <td data-title="Товар" :data-id="item.products[0].product.nmId">
        <div class="item">
            <button class="table-accordion__trigger" type="button" aria-label="Раскрыть/скрыть" data-toggle-table-row :data-order="item.products[0].product.nmId" aria-selected="false"></button>
            <img class="item__img" v-if="groupBy !== 'brand'" :src="item.products[0].product.image" onerror="this.src='/img/product1.jpg'" width="40" height="40" alt="product">
            <div class="item__body" v-if="groupBy === 'brand'">
                <ProductTitle :title="item.products[0].product.brand != '' ? item.products[0].product.brand : 'Без бренда'" :warning="true"/>
            </div>
            <div class="item__body" v-else>
                <ProductTitle :title="item.products[0].product.title" :warning="true"/>
                <span class="item__product-id">
                    <button class="copy-btn copy_button" :data-id="item.products[0].product.nmId" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                    <a v-if="item.products[0].product.link" class="item__link" :href="item.products[0].product.link" target="_blank">{{ item.products[0].product.nmId }}</a>
                    <span v-else>{{ item.products[0].product.nmId }}</span>
                    <span v-if="item.products[0].product.supplier_article"> / {{ item.products[0].product.supplier_article }} </span>
                </span>
            </div>
        </div>

        <!--
        <div class="product_wrapper product_wrapper_acc">
            <button class="table-accordion__trigger" type="button" aria-label="Раскрыть/скрыть" data-toggle-table-row :data-order="item.products[0].product.nmId" aria-selected="false"></button>

            <div class="product_image">
                <img class="product-thumbnail" :src="item.products[0].product.image" width="40" height="40" alt="product">
            </div>

            <div class="product_content">
                <div class="name" :title="item.products[0].product.title">
                    <span>{{ item.products[0].product.title }}</span>
                </div>
                <div>
                    <button class="copy_button" :data-id="item.products[0].product.nmId" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                    <a :href="item.products[0].product.link" target="_blank">{{ item.products[0].product.nmId }}</a>
                </div>
            </div>
        </div>
        -->
    </td>
    <td>{{ item.summary.quantity }}</td>
    <td>{{ item.summary.refundQuantity }}</td>
    <td><Currency :value="item.summary.realSalesAmount"/></td>
    <td><Currency :value="item.summary.refundsAmount"/></td>
    <td><Currency :value="item.summary.deduction"/></td>
    <!-- <td><Currency :value="item.summary.logisticCost"/></td> -->
    <!-- <td><Currency :value="item.summary.commission"/></td> -->
    <!-- <td><Currency :value="item.summary.grossProfit"/></td> -->
    <!-- <td><Currency :value="item.summary.expenses"/></td> -->
    <td><Currency :value="item.summary.profit"/></td>
    <td><Currency :value="item.summary.profitPerItem"/></td>
    <!-- <td><Currency :value="item.summary.wbPay"/></td> -->
    <template v-if="item.summary.quantity">
        <td><Percent :value="item.summary.margin"/></td>
        <td><Percent :value="item.summary.roi"/></td>
        <template v-if="item.summary.stable">
            <td><Percent :value="item.summary.redemption"/></td>
        </template>
        <template v-else>
            <td>-</td>
        </template>
    </template>
    <template v-else>
        <td>-</td>
        <td>-</td>
        <td>-</td>
    </template>
    <!-- <td>{{ item.summary.countries.join(', ') }}</td> -->
    <td>
        <!-- <DashboardMoreButton v-bind:data="item.summary"/> -->
        <button
            aria-label="Больше"
            class="product-more-btn fm-chevron-right"
            type="button"
            @click="setSummary(item.summary, item.products[0].product, groupBy === 'brand')"
        />
    </td>
</template>

<script>

import DashboardMoreButton from '@/Components/Dashboard/DashboardMoreButton'
import { Link } from '@inertiajs/inertia-vue3'
import Currency from '@/Components/Common/Currency.vue'
import Percent from '@/Components/Common/Percent.vue'
import ProductTitle from '@/Components/Products/Title.vue'

export default {
    props: [
        'item',
        'groupBy',
    ],
    components: {
        ProductTitle,
        Percent,
        Currency,
        DashboardMoreButton,
        Link,
    },
    emits: ['setSummary'],

    data() {
        return {

        };
    },

    watch: {

    },
    methods: {
        setSummary(summary, product, isBrandSelected) {
            this.$emit('setSummary', summary, product, isBrandSelected);
        },
    },
}
</script>
