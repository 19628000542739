<template>
    <Head title="Dashboard"/>

    <BreezeAuthenticatedLayout>
        <template #header-title>
            <h1 class="page_title">{{ __("global.hint.expenses.title") }}</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">
                <BreezeDashboardInfo></BreezeDashboardInfo>
                <div class="filter-panel">
                    <div class="filter-panel__inner">
                        <div class="input input_combo input_combo_2">
                            <div class="input__inner">
                                <input id="selectSearchProductExpenses" v-model="filter.query"
                                       class="input__text-input" placeholder="Наименование расхода" type="text"
                                       @keyup.enter="filterSubmit(null)">
                                <div class="input__actions">
                                    <button v-if="query" aria-label="Сбросить" class="input__btn fm-x" type="button"
                                            @click="filterReset()"></button>
                                    <button class="input__btn fm-search" type="button"
                                            @click="filterSubmit(null)"></button>
                                </div>
                            </div>
                        </div>

                        <div class="top_filter_row__column top_filter_row__column_expenses-category">
                            <Select2
                                v-model="filter.category"
                                :options="category_list_filter"
                                :settings="{theme: 'sd_select', minimumResultsForSearch: -1, prompt: 'Все категории', placeholder: 'Выберите категорию', allowClear: false, width: '240px',}"
                                @select="filterSubmit(null,true)"
                            />
                        </div>

                        <button :class="{active: defaults && (query || dateFrom !== defaults.dateFrom || dateTo !== defaults.dateTo)}"
                                class="dashboard-nav__btn dashboard-nav__btn_products dashboard-nav__btn_mob"
                                data-target="#expensesFilterModalMob" data-toggle="modal" type="button">
                            <i class="fm-filter"></i>
                        </button>

                        <button :class="{active: defaults && (query || dateFrom !== defaults.dateFrom || dateTo !== defaults.dateTo)}"
                                class="dashboard-nav__btn dashboard-nav__btn_desktop dashboard-nav__btn_products query"
                                data-target="#expensesFilterModal" data-toggle="modal" type="button">
                            <i class="fm-calendar"></i>
                        </button>
                    </div>
                </div>
                <!--
                <div class="dashboard_panel">
                    <div class="toggle_filter">
                        <button type="button" class="select_filter_button js_trigger__click"
                                data-element=".top_filter_row" data-toggle="false"><span class="fm-filter"></span>Отбор
                        </button>
                    </div>
                    <div class="top_filter_row">
                        <div id="search_outer" class="search_outer desktop_select">
                            <label for="selectSearchProductExpenses"><span class="fm-search"></span></label>
                            <input id="selectSearchProductExpenses" class="form-control pl-5" @keyup.enter="filterSubmit"
                                   v-model="filter.query" type="text"
                                   placeholder="Наименование расхода">
                        </div>

                        <div class="filter_button">
                            <button type="button" class="index_filter_button"
                                    :class="{active: canFilter}"
                                    @click="filterSubmit" data-toggle="false"><span class="fm-filter"></span>
                                Фильтр
                            </button>
                        </div>

                        <div class="datePicker_outer datePicker_outer_ml-auto">
                            <div id="reportRange" class="datePicker_inner">
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                -->
                <div class="table_outer">
                    <div :class="{'dashboard-wrapper no_items': !expenses.length}" class="expenses-wrapper">
                        <div v-if="!expenses.length" class="empty_banner">
                            <div class="empty_banner_search">
                                <span class="fm-carbon_search"></span>
                            </div>
                            <h4 class="empty_banner_title">Ничего не найдено</h4>
                            <p class="empty_banner_description">Попробуйте поменять параметры фильтра и попробовать
                                снова</p>
                        </div>

                        <table v-if="expenses.length"
                               class="table table-fw-widget expenses-table">
                            <thead>
                            <tr>
                                <th class="checkbox-td" style="width: 1%;">
                                    <div class="input">
                                        <div class="input__inner">
                                            <input class="input__checkbox" id="s-all" type="checkbox" :indeterminate="multiSelectedItems.length > 0 && multiSelectedItems.length != expenses.length" :checked="isAllChecked" @change="checkAll()">
                                            <label class="input__checkbox-label" for="s-all"></label>
                                        </div>
                                    </div>
                                </th>
                                <th :class="{'reorder_outer': sort.orderBy === 'date'}" class="sortable-header"
                                    style="width: 8%;text-align: left;"
                                    @click="sortByColumn('date')">Дата
                                    <button v-show="sort.orderBy === 'date'"
                                            :class="{active: !sort.desc || sort.desc === -1}"
                                            class="reorder_button" type="button"></button>
                                </th>
                                <th style="text-align: left;">Наименование</th>
                                <th style="text-align: left;">Тип расхода</th>
                                <th style="text-align: left;">Категория</th>
                                <th style="text-align: left;">Товар</th>
                                <th style="width: 9%; min-width: 80px; text-align: left;">Сумма</th>
                                <th style="width: 1%; text-align: center;"><span
                                    class="d-none d-sm-inline">Действия</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(model, key) in expenses">
                                <tr>
                                    <td class="checkbox-td">
                                        <div class="input">
                                            <div class="input__inner">
                                                <input class="input__checkbox" :id="'s-'+model.id" type="checkbox" :checked="multiSelectedItems.includes(model.id)" @change="checkIndeterminate(model)">
                                                <label class="input__checkbox-label" :for="'s-'+model.id"></label>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Дата" @click="expenseEdit(model)">
                                        <div class="inner-relative"><span>{{ formatDate(model.date) }}</span></div>
                                    </td>
                                    <td data-title="Наименование" @click="expenseEdit(model)">
                                        <div class="inner-relative"><span>{{ model.title }}</span></div>
                                    </td>
                                    <td data-title="Тип расхода" @click="expenseEdit(model)">
                                        <div class="inner-relative">
                                            <span>{{ getTypeLabel(model.type) }}</span></div>
                                    </td>
                                    <td data-title="Категория" @click="expenseEdit(model)">
                                        <div class="inner-relative"><span>{{
                                                getLabelForCategory(model.category)
                                            }}</span></div>
                                    </td>
                                    <td data-title="Товар">
                                        <!--
                                        <div v-for="(product, key) in model.products" class="inner-relative">
                                            <div class="have_image">
                                                <img class="product-thumbnail" :src="product.image ? product.image : '/img/product1.jpg'" alt="product">
                                                <p class="name"
                                                   style="margin:0; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                                    <span class="name_title">{{ product.barcode }}</span>
                                                    <span class="c-dark_grey">{{ product.nm_id }}</span>
                                                </p>
                                            </div>
                                        </div>
                                        -->

                                        <!--                                        <div v-if="model.product" class="inner-relative">-->
                                        <!--                                            <div class="have_image">-->
                                        <!--                                                <img class="product-thumbnail" :src="model.product.image ? model.product.image : '/img/product1.jpg'" alt="product">-->
                                        <!--                                                <p class="name"-->
                                        <!--                                                   style="margin:0; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">-->
                                        <!--                                                    <span class="name_title">{{ model.product.barcode }}</span>-->
                                        <!--                                                    <span class="c-dark_grey">{{ model.product.nm_id }}</span>-->
                                        <!--                                                </p>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->

                                        <div>
                                            <template v-for="(products, key) in model.productsGrouped">
                                                <button class="product-summary" data-product_summary type="button">
                                                    {{ products[0].nm_id }}
                                                </button>
                                                <div data-product_summary_content style="display: none;">
                                                    <div class="product-summary__details">
                                                        <div class="product-summary__header">
                                                            <img :src="products[0].image ? products[0].image : '/img/product1.jpg'"
                                                                 alt="product"
                                                                 class="product-summary__img" height="41" width="41">
                                                            <div class="product-summary__header__inner">
                                                                <div class="product-summary__product-title">
                                                                    {{ products[0].title }}
                                                                </div>
                                                                <div>{{ products[0].nm_id }}</div>
                                                            </div>
                                                        </div>
                                                        <div v-for="(product, k) in products"
                                                             class="product-summary__variation">{{ product.barcode }} {{
                                                                (product.tech_size != '' && product.tech_size != 0 && product.tech_size != 'null') ? ', Размер: ' + product.tech_size : ''
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                {{ (key < model.productsGrouped.length - 1) ? ', ' : '' }}
                                            </template>

                                        </div>
                                    </td>
                                    <td data-title="Сумма" @click="expenseEdit(model)">
                                        <div class="inner-relative"><span><Currency :value="model.amount"/></span></div>
                                    </td>
                                    <td class="action-td">
                                        <button aria-label="Больше" class="product-more-btn fm-chevron-right"
                                                type="button" @click="expenseEdit(model)"></button>
                                        <!--
                                        <div class="inner-relative">
                                            <button type="button" class="dropdown_toggle" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                <span class="fm-more-horizontal"></span>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li>
                                                    <button class="dropdown-item" @click="expenseEdit(model)">Изменить
                                                        расход
                                                    </button>
                                                </li>
                                                <li>
                                                    <button class="dropdown-item" @click="expenseDelete(model.transaction_id)"
                                                            data-toggle="modal"
                                                            data-target="#modal_notificationDelete">Удалить расход
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        -->
                                    </td>
                                </tr>
                                <tr v-if="model.summary" class="total_table_row total_table_row_mob">
                                    <td colspan="3">{{ model.summary.label }}:</td>
                                    <td colspan="2">
                                        <Currency :value="model.summary.total"/>
                                    </td>
                                </tr>
                                <tr v-if="model.summary" class="total_table_row total_table_row_sm">
                                    <td colspan="4">{{ model.summary.label }}:</td>
                                    <td colspan="2">
                                        <Currency :value="model.summary.total"/>
                                    </td>
                                </tr>
                                <tr v-if="model.summary" class="total_table_row total_table_row_desktop">
                                    <td colspan="6">{{ model.summary.label }}:</td>
                                    <td colspan="2">
                                        <Currency :value="model.summary.total"/>
                                    </td>
                                </tr>
                            </template>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!--
        <div class="buttons-row fixed_bottom_row justify-between">
            <button class="btn-default btn-regular" type="button" data-toggle="modal" data-target="#expensesImportModal">Импорт расходов</button>


        <div class="buttons-row fixed_bottom_row right_position">

            <a href="javascript:" @click.prevent="expensesCreate" class="btn-default btn-regular">Добавить</a>
        </div>
        -->

        <Teleport v-if="pageLoaded" to="#app_child">
            <div class="buttons-panel">
                <div v-if="isMultiDeleteOn" class="buttons-panel__inner">
                    <button class="btn-default btn-regular buttons-panel_add-expense"
                            @click="openDeleteModal('multi')" type="button">Удалить</button>
                </div>
                <div v-else class="buttons-panel__inner">
                    <!--<button v-if="isSupported" class="btn-default btn-regular" data-target="#expensesImportModal" data-toggle="modal"
                            type="button">Импорт расходов
                    </button>-->
                    <button class="btn-default btn-regular buttons-panel_add-expense" type="button"
                            @click="expensesCreate">Добавить
                    </button>
                </div>
            </div>
        </Teleport>

        <Teleport to="body">
            <div id="expensesImportModal" aria-hidden="true" aria-labelledby="expensesImportModalLabel"
                 class="modal modal-basic reports-import-modal" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button aria-label="Back" class="modal-back" data-dismiss="modal" type="button">
                                <i class="fm-arrow-left"></i>
                            </button>
                            <h5 id="expensesImportModalLabel" class="h4 modal-title">Импорт расходов</h5>
                            <button aria-label="Close" class="modal-close" data-dismiss="modal" type="button">
                                <span class="fm-close"></span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <div class="info-banner-outer warning p-0 mb-2">
                                <div class="info-banner">
                                    <div class="icon__block"><span class="fm-icon-info"></span></div>
                                    <div class="info-banner-text">
                                        <div class="">
                                            <h3>Загружайте фин отчеты только до 28 января включительно</h3>
                                            <p>
                                                С 29 января данные автоматически загружаются по ключу
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="reports-import-modal__help-panel">
                                <div>
                                    <h5 class="reports-import-modal__help-panel__title">Как импортировать удержания&nbsp;WB,
                                        не&nbsp;передающиеся через API?</h5>
                                    <!--                                    <p>Aliquam aliquam vitae ut sit morbi lacus.</p>-->
                                </div>
                                <a aria-label="Читать инструкцию" class="reports-import-modal__help-panel__link"
                                   href="/expenses/import" target="_blank">
                                    <span class="fm-arrow-right"></span>
                                </a>
                            </div>

                            <DropFileInput
                                :inputLabel="'Нажмите сюда и выберите файлы удержаний или перетащите их в эту область'"
                                v-bind:fileErrors="fileErrors"
                                v-bind:fileLoaded="fileLoaded"
                                @upload='fileUpload'>
                            </DropFileInput>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <div id="expensesCreate" aria-hidden="true" aria-labelledby="modal_change_label"
             class="modal modal-fullscreen expenses-create-modal" data-expense-modal>
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <form id="addForm" class="modal-content" @submit.prevent="submit">
                    <div class="modal-header">
                        <button v-if="(filterSection === 'products' || filterSection === 'category')"
                                aria-label="Back" class="modal-back modal-back_show" type="button"
                                @click="changeFilterSection('')">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <button v-else aria-label="Back" class="modal-back" data-dismiss="modal" type="button">
                            <i class="fm-arrow-left"></i>
                        </button>

                        <h5 v-if="expenseIsCreating" class="modal-title">Добавить расход</h5>
                        <h5 v-else-if="filterSection === 'products'" class="modal-title"
                            @click="changeFilterSection('')">Товары</h5>
                        <h5 v-else-if="filterSection === 'category'" class="modal-title"
                            @click="changeFilterSection('')">Категория</h5>
                        <h5 v-else class="modal-title">Изменить расход</h5>
                        <button aria-label="Close" class="modal-close" data-dismiss="modal" type="button">
                            <span class="fm-close"></span>
                        </button>
                    </div>

                    <input v-model="form.id" type="hidden">
                    <div class="modal-body scrollbar">
                        <div v-show="filterSection === ''" class="info-list">
                            <div class="info-list__row">
                                <div class="info-list__input">
                                    <div class="info-list__input__inner">
                                        <label class="info-list__input__label" for="xp-1">Наименование</label>
                                        <input id="xp-1" v-model="form.title" :class="{error: $page.props.errors.title}"
                                               :readonly="!expenseIsCreating && !form.editable"
                                               class="info-list__input__text-input" placeholder="Название расхода"
                                               type="text">
                                    </div>
                                    <p class="invalid-feedback">{{ $page.props.errors.title }}</p>
                                </div>

                                <div class="info-list__input">
                                    <div :class="{error: $page.props.errors.amount}" class="info-list__input__inner">
                                        <label class="info-list__input__label" for="expense-amount">Сумма</label>
                                        <input id="expense-amount" :class="{error: $page.props.errors.amount}" class="info-list__input__text-input"
                                               placeholder="0"
                                               type="text"
                                               v-bind:value="form.amount"
                                               @change="form.amount = $event.target.value"
                                               @focusin="hideZeroCost($event)"
                                               v-on:input="form.amount = $event.target.value">
                                    </div>
                                </div>
                            </div>

                            <div class="info-list__input">
                                <div class="info-list__input__inner">
                                    <label class="info-list__input__label" for="dateFrom">Дата</label>
                                    <input :id="expenseIsCreating || form.editable ? 'dateFrom_'+form.randIndex : ''" v-model="form.dateFrom"
                                           :class="{error: $page.props.errors.dateFrom}"
                                           :readonly="!expenseIsCreating || form.editable" class="info-list__input__text-input"
                                           type="text"
                                    >
                                </div>
                                <p class="invalid-feedback">{{ $page.props.errors.dateFrom }}</p>
                            </div>
                            <div v-if="expensesTypesOptions.length <= 2" class="info-list__input">
                                <div class="costprice-modal__label">Тип</div>
                                <div class="options-group">
                                    <div v-for="(expenseType, key) in expensesTypesOptions" class="input">
                                        <div class="input__inner">
                                            <input :id="'expense-type-'+expenseType.id" v-model="form.type"
                                                   :readonly="!expenseIsCreating && !form.editable" :value="expenseType.id"
                                                   class="input__hidden" name="type"
                                                   type="radio"
                                            >
                                            <label :for="'expense-type-'+expenseType.id" class="input__radio-btn">
                                                {{ expenseType.text }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="info-list__input">
                                <div id="cat-select" class="info-list__input__inner">
                                    <div class="info-list__input__basic-label">Тип
                                        <div v-if="form.parent_type > 0 && form.parent_id > 0" class=""
                                             @click="toParentElement(form.parent_id)">
                                            <button class="product-summary ml-1" data-type_summary type="button">
                                                Родительский расход
                                            </button>
                                            <div data-type_summary_content style="display: none;">
                                                <div class="product-summary__details">
                                                    <div class="product-summary__header">
                                                        <div class="product-summary__header__inner">
                                                            <div class="product-summary__product-title">
                                                                {{ form.parent_date }}
                                                            </div>
                                                            <div>
                                                                {{ form.parent_title }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Select2
                                        v-model="form.type"
                                        :class="form.parent_type > 0 && form.parent_id > 0 ? 'disabled' : ''"
                                        :disabled="form.parent_type > 0 && form.parent_id > 0"
                                        :options="expensesTypesOptions"
                                        :settings="{theme: 'sd_select sd_select_modal', placeholder: 'Выберите тип расхода', allowClear: false, width: '100%', tags: false, minimumResultsForSearch: 'Infinity'}"
                                    />
                                </div>
                            </div>
                            <div v-if="form.type != 0 && form.type != 3" class="info-list__input">
                                <div class="input__inner">
                                    <input id="amortization" v-model="form.amortizationPerDay"
                                           :checked="!!form.amortizationPerDay" class="input__checkbox"
                                           type="checkbox">
                                    <label class="input__checkbox-label" for="amortization">Амортизация по дням</label>
                                </div>
                            </div>
                            <!--
                            <div class="info-list__input info-list__input_combo">
                                <div class="info-list__input__inner">
                                    <div :class="form.category ? 'info-list__input__label' : 'info-list__input__basic-label'">Категория</div>
                                    <div class="info-list__input__text-input" v-show="form.category">
                                        {{getLabelForCategory(form.category)}}
                                    </div>
                                </div>
                                <button class="info-list__input__btn fm-chevron-right" type="button" @click="changeFilterSection('category')"></button>
                            </div>
                            -->

                            <div class="info-list__input">
                                <div class="info-list__input__inner">
                                    <div class="info-list__input__basic-label">Категория</div>
                                    <Select2
                                        v-model="form.category"
                                        :options="category_list"
                                        :settings="{theme: 'sd_select sd_select_add', placeholder: 'Выберите или создайте категорию', allowClear: false, width: '100%', tags: true, }"
                                    />
                                </div>
                            </div>

                            <div class="info-list__input info-list__input_combo info-list__input_last">
                                <div class="info-list__input__inner">
                                    <div class="info-list__input__basic-label">Товары</div>
                                    <template v-for="(products, key) in productsGroupedModal">
                                        <button class="product-summary" data-product_summary type="button">
                                            {{ products[0].nm_id }}
                                        </button>
                                        <div data-product_summary_content style="display: none;">
                                            <div class="product-summary__details">
                                                <div class="product-summary__header">
                                                    <img :src="products[0].image ? products[0].image : '/img/product1.jpg'"
                                                         alt="product"
                                                         class="product-summary__img" height="41" width="41">
                                                    <div class="product-summary__header__inner">
                                                        <div class="product-summary__product-title">{{
                                                                products[0].title
                                                            }}
                                                        </div>
                                                        <div>{{ products[0].nm_id }}</div>
                                                    </div>
                                                </div>
                                                <div v-for="(product, k) in products"
                                                     class="product-summary__variation">{{ product.barcode }} {{
                                                        (product.tech_size != '' && product.tech_size != 0 && product.tech_size != 'null') ? ', Размер: ' + product.tech_size : ''
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        {{ (key < productsGroupedModal.length - 1) ? ', ' : '' }}
                                    </template>
                                </div>
                                <button class="info-list__input__btn fm-chevron-right" type="button"
                                        @click="changeFilterSection('products')"></button>
                            </div>
                        </div>

                        <div v-show="filterSection === 'products'" class="filter-products">
                            <ProductsSelector
                                ref="ProductsSelector"
                                v-bind:isInline="true"
                                v-bind:products="form.products"
                                v-bind:showMore="true"
                                @changed="selectedProductsChanged">
                            </ProductsSelector>
                        </div>

                        <div v-show="filterSection === 'category'" class="info-list">
                            <template v-for="(category, index) in category_list">
                                <div class="info-list__input">
                                    <div class="info-list__input__inner">
                                        <input :id="'c-'+category.id" v-model="form.category" :value="category.id"
                                               class="input__checkbox" name="category" type="radio">
                                        <label :for="'c-'+category.id" class="input__checkbox-label">{{
                                                category.text
                                            }}</label>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-if="!expenseIsCreating" v-show="filterSection === ''" aria-label="Close"
                                class="btn-default" data-dismiss="modal" type="button"
                                @click="expenseDelete(form.transaction_id)">Удалить
                        </button>
                        <button v-if="(filterSection === 'products' || filterSection === 'category')"
                                class="btn-default btn-regular" @click="changeFilterSection('')">Сохранить
                        </button>
                        <button v-else :class="{'btn-regular': expensesFormPopulated}" :disabled="!expensesFormPopulated" aria-label="Close"
                                class="btn-default"
                                type="submit">Сохранить
                        </button>
                    </div>


                </form>
            </div>
        </div>

        <div id="modalDeleteItem" aria-hidden="true" aria-labelledby="modal_notificationDelete_label"
             class="modal modal-fullscreen logout-confirm"
             tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-label="Back" class="modal-back" @click="clearDelete()" data-dismiss="modal" type="button">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title">Подтверждение</h5>
                        <button aria-label="Close" class="modal-close" @click="clearDelete()" data-dismiss="modal" type="button">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h6 class="logout-confirm__title text-center">Вы уверены?</h6>
                    </div>
                    <div class="modal-footer">
                        <button aria-label="Close" class="btn-default" @click="clearDelete()" data-dismiss="modal">Отменить</button>
                        <button aria-label="Close" class="btn-default btn-regular" @click="confirmDelete">Удалить
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div v-if="notification.show" id="notificationMessage"
                 :class="notification.type" class="notification-message is-display-block notification-message_offset">
                <span>{{ notification.message }}</span>
            </div>
        </transition>
    </BreezeAuthenticatedLayout>

    <ExpensesFilterModal ref="expensesFilterModal"
                         v-bind:filter="filter"
                         @apply="filterSubmit"/>

    <ExpensesFilterModalMob ref="expensesFilterModalMob"
                            v-bind:category="category_list_filter"
                            v-bind:filter="filter"
                            @apply="filterSubmit"/>


    <!--
    <Teleport to="body">
        <div class="modal modal-fullscreen" id="expensesImportModal" tabindex="-1" aria-labelledby="expensesImportModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title" id="expensesImportModalLabel">Импорт расходов</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ol class="ordered-list">
                            <li>Перейдите в&nbsp;раздел &laquo;<a href="#" target="_blank">Финансовые отчеты (еженедельные)</a>&raquo; </li>
                            <li>Нажмите на&nbsp;кнопку &laquo;Скачать Excel&raquo; над списком отчетов</li>
                            <li>Дождитесь скачивания файла</li>
                            <li>Нажмите на&nbsp;кнопку &laquo;Выбрать файл&raquo; и&nbsp;найдите загруженный файл</li>
                            <li>Нажмите на&nbsp;кнопку &laquo;Загрузить&raquo;</li>
                        </ol>
                        <form action="">
                            <div class="input input_file">
                                <div class="input__file" v-show="filename !== ''">
                                    <div class="input__file__ico">
                                        <span class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                    </div>
                                    <div class="input__file__body">
                                        <div class="input__file__title">{{ filename }}</div>
                                        <div class="input__file__progress"><div class="input__file__progress__bar" style="width: 50%;"></div></div>
                                    </div>
                                </div>
                                <div class="input__inner">
                                    <input class="input__file-input" type="file" id="expenses-file" @change="fileUpload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                    <label class="btn-default btn-regular cursor-pointer" :class="(filename !== '') ? 'disabled' : ''" for="expenses-file">Загрузить</label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
    -->


</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import BreezeDashboardInfo from '@/Components/Dashboard/DashboardInfo.vue'
import {Head} from '@inertiajs/inertia-vue3';

import BreezeValidationErrors from '@/Components/ValidationErrors.vue'
import {pickBy, throttle} from "lodash-es";

import TextInput from '@/Components/TextInput'
import {Inertia} from "@inertiajs/inertia";
import Inputmask from "inputmask";
import Select2 from 'vue3-select2-component';

import ProductsSelector from "@/Components/Products/ProductsSelector";

import ExpensesFilterModal from '@/Components/Expenses/ExpensesFilterModal.vue'
import ExpensesFilterModalMob from '@/Components/Expenses/ExpensesFilterModalMob.vue'
import Currency from '@/Components/Common/Currency.vue'
import DropFileInput from '@/Components/Common/DropFileInput.vue'

import { store } from '@/store';

export default {
    components: {
        Currency,
        BreezeAuthenticatedLayout,
        BreezeValidationErrors,
        BreezeDashboardInfo,
        Head,
        TextInput,
        Inputmask,
        Select2,
        ProductsSelector,
        ExpensesFilterModal,
        ExpensesFilterModalMob,
        DropFileInput
    },
    props: {
        data: Object,
        query: String,
        desc: String,
        dateFrom: String,
        dateTo: String,
        expenseTypes: Object,
        defaults: Object,
        category: Object,
    },
    data() {
        let data = {
            form: this.$inertia.form({
                "id": null,
                "transaction_id": null,
                "title": '',
                "type": 0,
                "dateFrom": '',
                "category": '',
                "dateTo": "2021-01-10",//fake date
                "amortizationPerDay": false,
                "amount": '',
                "productId": null,
                "products": [],
                "productsFull": [],
                "productsFullGrouped": [],
                "is_grouped": false,
                "parent_type": 0,
                "parent_id": 0,
                "parent_title": '',
                "parent_date": '',
                "editable": false,
            }),
            expensesFormPopulated: true,
            delete_id: null,
            expenses: {},
            pageLoaded: false,
            filter: {
                query: null,
                dateFrom: null,
                dateTo: null,
                category: null,
            },
            canFilter: false,
            sort: {
                orderBy: 'date',
                desc: this.$props.desc ?? 1
            },
            notification: {
                show: false,
                message: 'Успешно скопировано',
                type: 'info'
            },
            expenseIsCreating: true,
            editingExpense: null,
            select2: null,
            productsGroupedModal: [],
            category_list: [],
            category_list_filter: [],
            expensesTypesOptions: this.$props.expenseTypes,
            expensesTypesSettings: {
                theme: 'default expense-type-select',
                minimumResultsForSearch: -1,
                width: '100%'
            },
            select2Options: null,
            multiselectOpen: false,
            cancelTokenSource: null,
            expenseProducts: {
                request: false,
                isLoading: false,
                page: 1,
                search: '',
                is_grouped: 1,
                data: {
                    results: null,
                    pagination: {
                        isMore: false
                    }
                }
            },
            expenseProductsSearchTimeoutId: null,
            filterSection: '',
            dateFormat: 'DD.MM.YYYY',
            filename: '',
            fileErrors: [],
            fileLoaded: [],
            isSubmitting: false,
            store,
            multiSelectedItems: [],
            deleteEvent: null,
        };
        data.select2Settings = {
            multiple: true,
            ajax: {
                url: '/expenses/products',
                dataType: 'json',
                data: function (params) {
                    let query = {
                        search: params.term,
                        page: params.page || 1,
                        is_grouped: Number(data.form.is_grouped)
                    }

                    // Query parameters will be ?search=[term]&page=[page]
                    return query;
                }
            },
            theme: 'default modal_select',
            dropdownParent: '#select2ExpensesContainer > div',
            dropdownCssClass: 'single-select',
            placeholder: 'Товар',
            closeOnSelect: false,
            templateResult: function (opt) {
                $('input.select2-search__field').prop('placeholder', 'Имя, артикул или баркод');
                if (!opt.id) {
                    return opt.text.toUpperCase();
                }
                let optimage = opt.image;
                if (!optimage) {
                    return opt.text.toUpperCase();
                } else {

                    let $opt = $('<span class="opt_image"><img src="' + optimage + '" width="40px" alt="' + opt.text.toUpperCase() + '" /></span><span class="opt_text"><span class="opt_text_block">' + opt.text + '</span> <span class="opt_text_block">' + ((opt.group_nm_count > 1) ? '' : opt.barcode + ' / ') + opt.nm_id + '</span></span>');
                    return $opt;
                }
            },
            templateSelection: function (opt) {
                let id = opt.id;
                if (!id) {
                    return opt.text.toUpperCase();
                }

                let image = opt.image;
                let text = opt.text
                let barcode = opt.barcode;
                let nm_id = opt.nm_id;
                if (!image) {
                    return opt.text.toUpperCase();
                } else {
                    let $opt = $(
                        '<span class="opt_image"><img src="' + image + '" width="40px" alt="' + text.toUpperCase() + '" /></span><span class="opt_text"><span class="opt_text_block">' + text + '</span> <span class="opt_text_block">' + barcode + ' / ' + nm_id + '</span></span>'
                    );
                    return $opt;
                }
            },
            allowClear: true,
            width: 'resolve',
            language: {
                noResults: function () {
                    return "Совпадений не найдено";
                },
                searching: function () {
                    return "Загрузка...";
                }
            }
        };


        return data;
    },
    computed: {
        filterClientDataNS() {
            return `account-${this.$page.props.auth.user.user_account.account_id}/expenses/filter`.toUpperCase();
        },
        isSupported: function () {
            return this.store.account.data_source === 'wildberries';
        },
        isAllChecked: function () {
            return this.multiSelectedItems.length === this.expenses.length;
        },
        isMultiDeleteOn: function () {
            return this.multiSelectedItems.length > 0;
        },
    },
    watch: {
        form: {
            deep: true,
            handler(newValue, oldValue) {
                if (this.form.title === '' || this.form.amount === '' || this.form.dateFrom === '')
                    this.expensesFormPopulated = false;
                else
                    this.expensesFormPopulated = true;
            }
        },
        filter: {
            deep: true,
            handler(newValue, oldValue) {
                localStorage.setItem(this.filterClientDataNS, JSON.stringify(newValue));
                if (newValue.query == '')
                    newValue.query = null;
                if (this.$props.query !== newValue.query || this.$props.dateFrom !== newValue.dateFrom || this.$props.dateTo !== newValue.dateTo)
                    this.canFilter = true;
                else
                    this.canFilter = false;
            }
        },
        sort: {
            deep: true,
            handler: throttle(function () {
                let self = this;
                let expensesUrl = '/expenses';

                const currentParams = new URLSearchParams(window.location.search);

                if (currentParams.has('expensesImportModal')) {
                    expensesUrl = '/expenses?expensesImportModal=' + currentParams.get('expensesImportModal');
                }

                this.$inertia.get(expensesUrl, pickBy({
                    orderBy: self.sort.orderBy,
                    desc: self.sort.desc,
                    query: self.filter.query,
                    dateFrom: self.filter.dateFrom,
                    dateTo: self.filter.dateTo,
                    category: self.filter.category
                }), {
                    replace: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.expenses = this.data;
                    }
                })
            }, 150),
        },
    },
    mounted() {
        this.expenses = this.data;
        this.pageLoaded = true;

        const clientFilterData = localStorage.getItem(this.filterClientDataNS);

        this.sort = {
            orderBy: 'date',
            desc: 1
        };

        if (clientFilterData) {
            const filterData = JSON.parse(clientFilterData);
            let dateRangeSets = this.__getFilterDateRangeSets();
            if (filterData.activeRange !== 'custom') {
                const range = dateRangeSets.find((r) => r.id === filterData.activeRange);
                if (range) {
                    filterData.dateFrom = range.dateFrom;
                    filterData.dateTo = range.dateTo;
                }
            }

            const fromObj = filterData.dateFrom.match(/\d{4}-\d{2}-\d{2}/);
            const toObj = filterData.dateTo.match(/\d{4}-\d{2}-\d{2}/);

            if (fromObj === null || toObj === null) {
                filterData.dateFrom = this.$props.dateFrom;
                filterData.dateTo = this.$props.dateTo;
                filterData.activeRange = 2;
            }

            this.filterSubmit(filterData,true);
        } else {
            this.filter.query = this.query;
            this.filter.dateFrom = this.$props.dateFrom;
            this.filter.dateTo = this.$props.dateTo;
            this.filter.activeRange = 2;
        }

        this.checkExpensesImportModal();

        this.category_list = [{id: '', text: '--'}, ...this.$props.category];
        this.category_list_filter = [{id: '0', text: 'Все'}, ...this.$props.category];
        this.init();

        let amountEl = document.getElementById('expense-amount');
        Inputmask({
            regex: "^[-0-9.,]+$",
            placeholder: '',
            showMaskOnHover: false,
        }).mask(amountEl);
        setTimeout(() => {
            this.tippyTooltips();
        }, 2000);

        if (document.location.hash) {
            // Code generated by GH CoPilot
            let hash = document.location.hash;
            let hashParts = hash.split('-');
            if (hashParts.length > 1) {
                let expenseId = hashParts[1];

                let expenseModel = this.expenses.find((expense) => {
                    return expense.id == expenseId;
                });
                if (expenseModel) {
                    setTimeout(this.expenseEdit(expenseModel), 200);
                }
            }
        }
        this.jsInit();
    },
    methods: {
        async selectedProductsChanged(data) {
            let self = this;
            await axios.get(this.route('expenses.productsGroupedSearch'), {
                params: pickBy({
                    productsIds: data.productIds,
                })
            }).then(function (response) {
                self.productsGroupedModal = Object.values(response.data.productsGrouped);
            })
                .catch(function (error) {
                    console.log(error);
                });
            this.form.productsFull = data.products;
            this.form.products = data.productIds;

            setTimeout(() => {
                this.tippyTooltips();
            }, 1000);

        },
        hideZeroCost: function (event) {
            if (event.target.value == 0) {
                event.target.value = '';
            }
        },
        clearFormProducts() {
            this.form.products = [];
            $('[data-multiselect-check-all]').prop('checked', false);
        },
        triggerSelectedAll(key, event) {
            let selectAll = $(event.target).prop('checked');
            let emptyProducts = (!this.form.products || !this.form.products.length);
            this.expenseProducts.data.results[key].products.forEach((product, index) => {
                if (emptyProducts && selectAll) {
                    this.form.products.push(product.id);
                } else {
                    this.form.products.forEach((prodId, idx) => {
                        if (selectAll) {
                            if (!this.form.products.length || !this.form.products.includes(product.id))
                                this.form.products.push(product.id)
                        } else {
                            if (product.id == prodId) {
                                this.form.products.splice(idx, 1);
                            }

                        }
                    });
                }

            });
        },
        checkSelectedAll(key) {
            let selectAll = true;
            this.expenseProducts.data.results[key].products.forEach((product, index) => {
                if (!this.form.products.length || !this.form.products.includes(product.id)) {
                    selectAll = false;

                }
            });

            if (selectAll)
                this.expenseProducts.data.results[key].selected = true;
            else
                this.expenseProducts.data.results[key].selected = false;
        },
        amountOnChange: function (newVal) {
            if (!String(this.form.amount).match(/\.$/)) {
                let newVal = Math.round(this.form.amount * 100) / 100;
                this.form.amount = isNaN(newVal) ? '' : Math.round(this.form.amount * 100) / 100;
            }

        },
        toParentElement(id) {
            this.expenses.forEach((expense, index) => {
                if (expense.transaction_definition_id === id) {
                    this.expenseEdit(expense);
                }
            });
        },
        init() {
            let self = this;
            $('#expensesCreate').on('hidden.bs.modal', function (e) {
                self.form.errors = {};
            })
        },
        submit() {
            let self = this;

            if (self.isSubmitting) {
                return false;
            }

            self.isSubmitting = true;

            this.form.productsFullGrouped = [];
            this.form.productsFull = [];
            let url = (!this.form.id) ? this.route('expenses.add') : this.route('expenses.edit')
            let data = this.form.data();
            data.amount = data.amount.replace(',', '.');
            data.dateFrom = this.formatDateSubmit(data.dateFrom);
            data.is_grouped = Number(data.is_grouped);
            //this.form.dateFrom = this.formatDateSubmit(this.form.dateFrom);
            this.$inertia.post(url, data, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    self.form.reset();
                    $('#expensesCreate').modal('hide');
                    let url = self.route('expenses') + '?dateFrom=' + self.filter.dateFrom + '&dateTo=' + self.filter.dateTo;
                    if (self.sort.orderBy)
                        url += '&orderBy=' + self.sort.orderBy;
                    if (self.sort.desc)
                        url += '&desc=' + self.sort.desc;
                    if (self.filter.query)
                        url += '&query=' + self.filter.query;

                    self.isSubmitting = false;
                    $('#expensesCreate').modal('hide');

                    setTimeout(this.filterSubmit(this.filter, true),300)
                },
                onError: (error) => {
                    self.isSubmitting = false;
                    $('#expense-amount').addClass('error');
                    Object.keys(error).forEach(function (key) {
                        self.showNotification(error[key], 'error');
                    });
                    setTimeout(() => {
                        $('#expense-amount').removeClass('error');
                    }, 5000)
                }
            });
        },
        sortByColumn: function (column) {
            this.sort = {
                orderBy: column,
                desc: (this.sort.desc === 1) ? -1 : 1
            };
        },
        showNotification: function (message, type = 'info') {
            let self = this;
            this.notification.show = true;
            this.notification.message = message;
            this.notification.type = type;
            setTimeout(function () {
                self.notification.show = false;
            }, 3000);
        },
        filterReset() {
            this.filter.query = '';
            this.filterSubmit(null, true);
        },
        filterSubmit(filter = null, forceSubmit = false) {
            if (filter)
                this.filter = filter;
            let self = this;
            if (!this.canFilter && !forceSubmit)
                return false;

            this.$inertia.get(this.route('expenses'), pickBy({
                orderBy: self.sort.orderBy,
                desc: self.sort.desc,
                query: self.filter.query,
                dateFrom: self.filter.dateFrom,
                dateTo: self.filter.dateTo,
                category: self.filter.category,
            }), {
                replace: true,
                preserveState: true,
                onSuccess: () => {
                    self.expenses = this.data;
                    self.canFilter = false;
                    this.category_list = [{id: '', text: '--'}, ...this.category];
                    this.category_list_filter = [{id: '0', text: 'Все'}, ...this.category];
                }
            })
        },
        expensesCreate: function () {
            this.form.reset();
            this.form.dateFrom = moment().format(this.dateFormat);
            this.form.productId = null;
            this.form.products = [];
            this.form.randIndex = 0;
            $('#expensesCreate').modal('show');
            this.select2Options = null;

            this.initDatePicker(0);
            this.expenseIsCreating = true;
            this.editingExpense = null;
        },
        expenseEdit: function (model) {
            let self = this;
            this.populateEditForm(model);
            this.editingExpense = model;
            this.expenseIsCreating = false;
            let products = [];
            let productsIds = [];
            if (model.products) {
                model.products.forEach((product, index) => {
                    products.push({
                        id: product.id,
                        text: product.title,
                        image: product.image,
                        barcode: product.barcode,
                        nm_id: product.nm_id,
                        selected: true
                    });
                    productsIds.push(product.id);
                });
                this.select2Options = products;
            }
                // if (model.product){
                //     let product = model.product;
                //     this.select2Options = [{
                //         id: product.id,
                //         text: product.title,
                //         image: product.image,
                //         barcode: product.barcode,
                //         nm_id: product.nm_id,
                //         selected: true
                //     }];
            // }
            else
                this.select2Options = null;

            setTimeout(function () {
                $('#expensesCreate').modal('show');
                //self.initDatePicker();
                self.tippyTooltips();
                self.tippyTooltipsForType();
            }, 200)
        },

        clearDelete: function () {
            this.deleteEvent = null;
        },

        openDeleteModal: function (event = null) {
            this.deleteEvent = event;
            $('#modalDeleteItem').modal('show');
        },

        confirmDelete: function () {
            if(this.deleteEvent === 'multi') {
                this.deleteEvent = null;
                this.multiDelete();
                $('#modalDeleteItem').modal('hide');
                return false;
            }

            if (!this.delete_id)
                return false;
            let self = this;
            axios.post(this.route('expenses.delete'), {transactionId: this.delete_id, allChain: true})
                .then(function (response) {
                    self.delete_id = null;
                    $('#modalDeleteItem').modal('hide');
                    self.filterSubmit(self.filter, true);
                })
                .catch(function (error) {
                    self.showNotification(error);
                });
        },
        expenseDelete: function (id) {
            this.delete_id = id;
            $('#modalDeleteItem').modal('show');
        },
        populateEditForm: function (model) {
            this.form.id = model.transaction_definition_id;
            this.form.transaction_id = model.id;
            this.form.title = model.title;
            this.form.amount = model.amount.toString().replace('.', ',');
            this.form.category = model.category;
            this.form.dateFrom = this.formatDate(model.date);
            this.form.productId = (model.product) ? String(model.product.id) : null;
            this.form.products = (model.products) ? model.products.map(function (product, index) {
                return product.id;
            }) : null;
            this.productsGroupedModal = model.productsGrouped;
            this.form.type = model.type;
            this.form.parent_type = model.parent_type;
            this.form.parent_id = model.parent_id;
            this.form.parent_title = model.parent_title;
            this.form.parent_date = model.parent_date;
            this.form.is_grouped = !!(model.is_grouped);
            this.form.editable = model.editable;
            this.form.amortizationPerDay = model.amortizationPerDay;
            console.log(model);
            this.form.randIndex = Math.floor(Math.random() * 100) + 1;
            this.initDatePicker(this.form.randIndex);
        },
        getTypeLabel(type) {
            if (typeof this.$props.expenseTypes === 'undefined')
                return '';
            let label = '';
            this.$props.expenseTypes.forEach((expenseType, index) => {
                if (expenseType.id == type) {
                    label = expenseType.text
                }
            });

            return label;
        },
        getProductById: function (productId) {
            let product = null;
            this.expenses.forEach((expense, index) => {
                if (expense.product && expense.product.id == productId) {
                    product = expense.product;
                }
            });
            return product;
        },
        initDatePicker: function (index) {
            setTimeout(() => {
                $('.daterangepicker').remove();
                let self = this;
                let pickerEl = $('#dateFrom_' + index);
                pickerEl.val(this.form.dateFrom);
                let picker = pickerEl.daterangepicker({
                    singleDatePicker: true,
                    showDropdowns: false,
                    autoApply: true,
                    minYear: 1901,
                    maxDate: new Date(),
                    autoUpdateInput: false,
                    maxYear: parseInt(moment().format("YYYY"), 10),
                    parentEl: pickerEl.parent(),
                    locale: {
                        direction: 'ltr',
                        format: self.dateFormat,
                        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                        firstDay: 1
                    },
                });

                // pickerEl.on('show.daterangepicker', function (ev, picker) {
                //     pickerEl.blur();
                // });

                pickerEl.on('apply.daterangepicker', function (ev, picker) {
                    $(this).val(picker.startDate.format(self.dateFormat));
                    this.dispatchEvent(new Event('input'))
                });
            }, 200);
        },

        formatDate(dateString) {
            return moment(dateString).format(this.dateFormat);
        },
        formatDateSubmit(dateString) {
            let splitted = dateString.split('.');
            return splitted[2] + '-' + splitted[1] + '-' + splitted[0];
        },

        tippyTooltips() {
            let product_summary = $('[data-product_summary]');
            product_summary.each(function () {
                if (typeof $(this).get(0)._tippy != 'undefined')
                    $(this).get(0)._tippy.destroy();
                let content = $(this).next('[data-product_summary_content]').html();
                tippy(this, {
                    content: content,
                    allowHTML: true,
                    arrow: true,
                    // hideOnClick: 'toggle',
                    // trigger: 'click'
                });
            })
        },
        tippyTooltipsForType() {
            let product_summary = $('[data-type_summary]');
            product_summary.each(function () {
                if (typeof $(this).get(0)._tippy != 'undefined')
                    $(this).get(0)._tippy.destroy();
                let content = $(this).next('[data-type_summary_content]').html();
                tippy(this, {
                    content: content,
                    allowHTML: true,
                    arrow: true,
                });
            })
        },
        onClickOutside() {
            this.multiselectOpen = false;
        },

        openMultiselect() {
            if (this.multiselectOpen) {
                this.multiselectOpen = false;
                return true;
            }


            this.multiselectOpen = true;
            this.expenseProducts.page = 1;
            this.expenseProductsShowMore();

        },
        expenseProductsShowMore() {
            if (this.expenseProducts.request) {
                this.cancelTokenSource.cancel();
            }
            this.expenseProducts.isLoading = true;
            this.cancelTokenSource = axios.CancelToken.source();
            this.expenseProducts.request = true;
            axios.get(this.route('expenses.productsCustom'), {
                cancelToken: this.cancelTokenSource.token,
                params: pickBy({
                    search: this.expenseProducts.search,
                    page: this.expenseProducts.page,
                    is_grouped: this.expenseProducts.is_grouped,
                    productIds: this.form.products
                })
            }).then((response) => {
                if (this.expenseProducts.page === 1)
                    this.expenseProducts.data = response.data;
                else {
                    this.expenseProducts.data.pagination = response.data.pagination;
                    this.expenseProducts.data.results = this.expenseProducts.data.results.concat(response.data.results);
                }

                this.expenseProducts.page += 1;
                this.expenseProducts.isLoading = false;
            });
        },
        expenseProductsSearch() {
            let self = this;
            if (this.expenseProducts.search.length === 0 || this.expenseProducts.search.length > 3) {
                this.expenseProducts.page = 1;
                if (this.expenseProductsSearchTimeoutId)
                    clearTimeout(this.expenseProductsSearchTimeoutId);
                this.expenseProductsSearchTimeoutId = setTimeout(function () {
                    self.expenseProductsShowMore();
                }, 500);
            }
        },

        changeFilterSection(filterSection = '') {
            this.filterSection = filterSection;
            if (filterSection === 'products') {
                this.$refs.ProductsSelector.openMultiselect(true);
            }
        },

        checkIndeterminate(model) {
            if(this.multiSelectedItems.includes(model.id)){
                this.multiSelectedItems.splice(this.multiSelectedItems.indexOf(model.id), 1);
            }
            else {
                this.multiSelectedItems.push(model.id);
            }
        },

        checkAll() {
          if(this.isAllChecked){
              this.multiSelectedItems = [];
          }
          else {
              this.multiSelectedItems = this.expenses.map((expense) => expense.id);
          }
        },

        multiDelete() {
            axios.post(this.route('expenses.multi.delete'), {expensesIds: this.multiSelectedItems})
                .then((response) => {
                    this.multiSelectedItems = [];
                    this.filterSubmit(this.filter, true);
                })
                .catch((error) => {
                    this.showNotification(error);
                });
        },

        jsInit() {
            let self = this;

            $(document).off('hidden.bs.modal', '#expensesCreate');
            $(document).on('hidden.bs.modal', '#expensesCreate', function (e) {
                self.filterSection = '';
            })
        },


        getLabelForCategory(id) {
            for (var i = 1; i < this.category_list.length; i++) {
                if (this.category_list[i].id == id) {
                    return this.category_list[i].text;
                }
            }
            return '--';
        },

        fileUpload(files) {
            files.forEach((file, index) => {
                setTimeout(() => {
                    const filename = file.name;
                    if (filename !== '') {
                        this.filename = filename;
                        const formData = new FormData();
                        formData.append('file', file);
                        const headers = {'Content-Type': 'multipart/form-data'};
                        axios.post(this.route('expenses.import'), formData, {headers}).then((res) => {
                            this.filename = '';
                            if (res.data.status == true) {
                                // $('#expensesImportModal').modal('hide');
                                this.filterSubmit(this.filter, true);
                                this.showNotification(res.data.msg);
                                this.fileLoaded.push(filename)

                            } else {
                                this.fileErrors.push(filename)
                                this.showNotification(res.data.msg, 'error');
                            }
                            //this.reloadTable();
                        }).catch((error) => {
                            this.fileErrors.push(filename);
                            this.showNotification('Неправильный формат файла', 'error');

                        });
                    }
                }, index * 200)
            })
        },
        openModal(modalId) {
            $(`#${modalId}`).modal('show');
        },
        checkExpensesImportModal() {
            const searchParams = new URLSearchParams(window.location.search);

            if (searchParams.get('expensesImportModal') === '1') {
                this.openModal('expensesImportModal');
            }
        }
    },

}
</script>
