<template>

</template>

<script>
    export default {
        props: ['show'],
        data() {
            return {
                //
            }
        },
        methods: {
            //
        },
        mounted() {
            //
        }
    };
</script>
