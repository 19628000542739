<template>
    <Head title="Register"/>

    <div class="reg_auth_row">
        <div class="logo_block">
            <a :href="$page.props.app.landing_url">
                <img src="/img/logo-auth.svg" alt="logo">
            </a>
        </div>
        <div>
            <Link :href="route('home')" class="auth_link login__link">
                Войти
            </Link>
        </div>
    </div>
    <div class="auth__wrapper">
        <div class="form__outer">
            <div class="auth__block">
                <div class="auth-block-inner">
                    <div class="form-title text-center">
                        <h6 class="ls-0_03em">Регистрация</h6>
                        <div v-if="!hasErrors" class="sub_title">Зарегистрируйтесь бесплатно!</div>


<!--                        <div v-if="formFirstError" class="info_block warning_block">-->
<!--                            <div class="info_block_icon"><span class="fm-alert-circle"></span></div>-->
<!--                            <div class="info_block_content">-->
<!--                                <div class="block_content_text">-->
<!--                                    <div class="input-errors" >-->
<!--                                        <div class="error-msg">{{ formFirstError }}</div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->


                    </div>

                    <div class="info_block warning_block mb-4" v-if="hasErrors">
                        <div class="info_block_icon"><span class="fm-alert-circle"></span></div>
                        <div class="info_block_content">
                            <div class="block_content_text">
                                <div class="input-errors" >
                                    <div class="error-msg" v-for="(error, key) in errors" :key="key" v-html="error"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form id="registerForm" @submit.prevent="submit" class="ajax-auth">
                        <div class="form__group email__input input_block">
                            <label for="registerEmail" style="display:none;"></label>
                            <input id="registerEmail" type="text" class="form-control"
                                   :class="{ form_error: v$.form.email.$error }" v-model="form.email"
                                   placeholder="Электронная почта" autocomplete="new-email">
                            <p v-if="v$.form.email.$error" class="invalid-feedback">{{ v$.form.email.$errors[0].$message }}</p>
                        </div>
                        <div class="form__group password__input input_block">
                            <label for="registerPassword" style="display:none;"></label>
                            <BreezeInput id="registerPassword" type="password" class="form-control"
                                         :class="{ form_error: v$.form.password.$error && !hasErrors }" v-model="form.password"
                                         placeholder="Пароль" autocomplete="new-password"/>
                            <p v-if="v$.form.password.$error" class="invalid-feedback">{{ v$.form.password.$errors[0].$message }}</p>
                        </div>
                        <div class="check_parent">
                            <div class="input_block">
                                <BreezeCheckbox name="conf1" class="input__checkbox"
                                                :class="{ form_error: v$.form.terms.$error }" id="conf1"
                                                v-model:checked="form.terms"/>
                                <label class="input__checkbox-label" for="conf1">
                                    Согласен с <a href="https://sellerdata.ru/privacy-policy/" target="_blank">политикой конфиденциальности</a> и <a href="https://sellerdata.ru/terms-of-use/" target="_blank">пользовательским соглашением</a>
                                </label>
                            </div>
                            <div class="input_block">
                                <BreezeCheckbox name="updates_subscription" class="input__checkbox" id="conf2"
                                                v-model:checked="form.updates_subscription"/>
                                <label class="input__checkbox-label" for="conf2">Хочу подписаться на новостную рассылку</label>
                            </div>
                        </div>
                        <div class="login_button_outer">
                            <BreezeButton class="submit_button" :class="{ 'opacity-25': form.processing }"
                                          :disabled="form.processing">
                                Зарегистрироваться
                                <span class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </BreezeButton>
                        </div>
                        <div class="bottom_link">
                            <span>Уже зарегистрированы?</span>
                            <Link :href="route('home')">
                                Войти
                            </Link>
                        </div>

                    </form>
                </div>
            </div>
            <BreezeFeedback/>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email, minLength, sameAs, helpers } from '@vuelidate/validators'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeButton from '@/Components/Button.vue'
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';

export default {
    setup: () => ({v$: useVuelidate()}),
    layout: BreezeGuestLayout,

    components: {
        BreezeCheckbox,
        BreezeButton,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link
    },
    computed: {
        errors() {
            return this.$page.props.errors
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0
        },
        formFirstError() {
            let message = null;
            this.v$.form.$errors.filter((error, index) => {
                if (error.$message && !message)
                    message = error.$message

            });
            return message;
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                email: '',
                password: '',
                terms: false,
                recaptcha_token: null,
                updates_subscription: false
            })
        }
    },
    validations() {
        return {
            form: {
                email: {
                    required: helpers.withMessage('Поле обязательно для заполнения', required),
                    email: helpers.withMessage('Введите email верного формата', email)
                },
                password: {
                    $lazy: true,
                    $autoDirty: false,
                    required: helpers.withMessage('Поле обязательно для заполнения', required),
                    minLength: helpers.withMessage(
                        ({
                             $pending,
                             $invalid,
                             $params,
                             $model
                         }) => ($invalid) ? 'Пароль должен содержать не менее 6 символов' : '',
                        minLength(6)
                    )
                    //minLengthValue: minLength(6),
                },
                /*terms: {
                    sameAs: helpers.withMessage(
                        ({
                             $pending,
                             $invalid,
                             $params,
                             $model
                         }) => ($invalid) ? '' : '',
                        sameAs(true)
                    )
                    //sameAs: sameAs(true)
                },*/
                terms: {
                    sameAs: helpers.withMessage(
                        ({
                             $pending,
                             $invalid,
                             $params,
                             $model
                         }) => ($invalid) ? '' : '',
                        sameAs(true)
                    )
                    //sameAs: sameAs(true)
                }
            }
        }
    },
    mounted() {

    },
    methods: {
        async submit() {
            let self = this;
            const isFormCorrect = await this.v$.$validate();
            grecaptcha.ready(function () {
                grecaptcha.execute(self.$attrs.app.recaptcha_public_key, {action: 'submit'}).then(function (token) {
                    self.form.recaptcha_token = token;
                    if (isFormCorrect) {
                        self.form.post(self.route('register'), {
                            onFinish: () => {
                                ym(87932746,'reachGoal','successful_registration');
                                self.form.reset('password')
                            },
                        })
                    }
                    // Add your logic to submit to your backend server here.
                });
            });
        }
    }
}
</script>
