import { reactive } from 'vue' // <-- reactive

export const store = reactive({ // <-- reactive
    loaded: false,
    account: {
        status: true,
    },
    syncStatus: {
        transactions: false,
        products: false,
        orders: false,
    },
    notifications: [],
    subscription: {},
    currentPayment: null,
    userId: null,
    accountList: [],
    currentAccount: null,
    stableDataEndDate: null,
    beta: true,
    currency: null,
});
