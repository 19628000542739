<template>
    <div class="multi-select__item" :class="{active: isOpen, 'multi-select__item-single': isSingle, current: isCurrent}">
        <div class="multi-select__item__header">
            <slot name="header" />
            <button
                v-show="!isSingle"
                class="multi-select__item__trigger"
                type="button"
                aria-label="Раскрыть"
                @click="toggleAccordion()"
                :aria-expanded="isOpen"
            >
            </button>
        </div>
        <div class="multi-select__item__body" >
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isSingle: Boolean,
        isCurrent: Boolean,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
