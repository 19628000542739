<template>
    <div class="form-group">
        <label v-if="showLabel" :for="`client-id-${slug}`" style="display: none">Client ID {{ marketplace }}</label>
        <BreezeInput
            v-if="slug === 'ozon'"
            :id="`client-id-${slug}`"
            type="text"
            class="form-control"
            v-model="clientId"
            :placeholder="`Client ID ${marketplace}`"
            autocomplete="false"
            @input="$emit('field-changed')"
        />
        <p v-if="submitError" class="invalid-feedback">{{ submitError }}</p>
    </div>

    <div class="form-group">
        <label v-if="showLabel" :for="`api-key-${slug}`" style="display: none">API-ключ {{ marketplace }}</label>
        <BreezeInput
            :id="`api-key-${slug}`"
            type="text"
            :class="{form_error: submitError}"
            class="form-control"
            v-model="apiKey"
            :placeholder="`API-ключ ${marketplace}`"
            autocomplete="false"
            @input="$emit('field-changed')"
        />
        <p v-if="submitError" class="invalid-feedback">{{ submitError }}</p>
    </div>
</template>

<script>
import BreezeInput from '@/Components/Input.vue'

export default {
    name: 'ApiKeyField',
    components: { BreezeInput },
    emits: ['field-changed'],
    props: {
        marketplace: {
            type: String,
            required: true
        },
        slug: {
            type: String,
            required: true
        },
        submitError: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            clientId: '',
            apiKey: '',
        }
    },
    methods: {
        getValue() {
            if (this.slug === 'ozon' && (this.clientId.length > 0 || this.apiKey.length > 0)) {
                return this.clientId + ':' + this.apiKey;
            }
            return this.apiKey;
        }
    }
}
</script>
