<template>

    <div class="error-page">
        <img class="error-page__logo" src="/img/logo-basic.svg" width="153" height="24" alt="sellerdata">

        <div class="error-page__body">
            <div class="error-page__content">
                <h1 class="error-page__title">Страница не найдена</h1>
                <p>Страница по введённому вами адресу не существует. Измените ваш запрос и попробуйте ещё раз.</p>
                <div class="error-page__actions">
                    <button class="btn-default" type="button" @click="back">
                        <span class="fm-arrow-left"></span>
                        Назад
                    </button>
                    <a class="btn-default btn-regular" href="/dashboard">В Дэшборд</a>
                </div>
            </div>
            <div class="error-page__image">
                <img class="error-page__img" src="/img/404-image.svg" width="290" height="234" alt="Error 404">
            </div>
        </div>
    </div>


</template>

<script>


export default {
    components: {

    },

    methods: {
        back() {
            history.back();
        }
    }
}
</script>
