<template>
    <Head title="Dashboard"/>
    <section class="page_connect_head">
        <div class="container">
            <div class="logo_head">
                <img src="/img/logo_blue.png" alt="logo">
            </div>

            <ApiKeyForm
                :exit-button-title="additional ? 'Назад' : 'Выйти'"
                :exit-button-route="additional ? 'dashboard' : 'logout'"
            />
        </div>
    </section>
    <section class="feedback" v-if="!additional">
        <div class="feedback__wrapper">
            <div class="container">
                <div class="feedback__inner">
                    <div class="feedback__header">
                        <div class="feedback__title">Отзывы наших пользователей</div>
                    </div>


                    <div class="swiper-container feedback__slider" data-feedback_slider>
                        <div class="swiper-wrapper">
                            <div v-for="(model, key) in reviews" class="swiper-slide">
                                <div class="feedback__item">
                                    <img class="feedback__item__avatar" :src="model.image" width="80" height="80"
                                        alt="user">
                                    <div class="feedback__item__header">
                                        <h3 class="h4 feedback__item__title">{{ model.fullname }}</h3>
                                        <p class="feedback__item__subtitle">{{ model.subtitle }} </p>
                                    </div>

                                    <div class="feedback__item__body" data-feedback_container>
                                        <div class="feedback__item__body__inner">{{ model.short_content }}</div>
                                    </div>

                                    <div class="feedback__item__footer" data-feedback_trigger_container>
                                        <button class="btn-link btn-link_primary" type="button" @click="openReview(model)">Читать дальше
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="feedback__slider__nav">
                            <div class="swiper-button-prev"><span class="fm-chevron-left"></span></div>
                            <div class="swiper-pagination"></div>
                            <div class="swiper-button-next"><span class="fm-chevron-right"></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="modal" id="feedback_modal" tabindex="-1" aria-labelledby="modal_notificationDelete_label"
         aria-hidden="true">
        <div class="modal-dialog feedback_modal modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal_thumbnail">
                        <img :src="oneReview.image" height="60" width="60" alt="">
                    </div>
                    <div class="title-outer">
                        <h5 class="modal-title" id="notification_modal_label">{{ oneReview.fullname }}</h5>
                        <div class="size-15 pt-1">{{ oneReview.subtitle }}</div>
                    </div>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <span class="fm-close"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <article>
                        <p>{{ oneReview.full_content }}</p>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import {Inertia} from "@inertiajs/inertia";
import ApiKeyForm from '@/Components/Account/ApiKeyForm.vue'

export default {
    components: {
        ApiKeyForm,
        BreezeCheckbox,
        BreezeInput,
        BreezeValidationErrors,
        Head,
        Link
    },
    props: {
        additional: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                apiKey: ''
            }),
            isFormProcessing: false,
            reviews: {},
            oneReview: {
                fullname: null,
                subtitle: null,
                image: null,
                full_content: null
            }
        }
    },
    mounted() {
        this.loadReviews();
        //this.checkThisPageAvailable();
    },
    methods: {
        checkThisPageAvailable(){
            /**
             * Prevent browser back button after Connecting page
             */
            fetch(this.route('connecting'), {})
                .then((response) => {
                    if (response.redirected){
                        Inertia.visit(response.url);
                    }
                })
                .catch(function (error) {

                });
        },
        submit() {
            let self = this;
            this.isFormProcessing = true;
            this.form.errors = {};
            this.form.post(this.route('apiKey'), {
                preserveScroll: true,

                onSuccess: () => {
                    ym(87932746,'reachGoal','successful_key');
                },
                onError: function(errors){
                    self.isFormProcessing = false;
                    if (errors.modal)
                        $('#errorKey').modal();
                },
            })
        },
        loadReviews: function(){
            let self = this;
            axios.get('/data/reviews.json', {})
                .then(function(response) {
                    self.reviews = response.data;
                    setTimeout(function(){
                        self.initSwiperSlider();
                    }, 300);

                })
                .catch(function(error) {

                });
        },
        openReview: function(model){
            this.oneReview = model;
            $('#feedback_modal').modal();
        },
        initSwiperSlider: function () {
            let showText = $('[data-feedback_trigger]').first().attr('data-show_text'),
                hideText = $('[data-feedback_trigger]').first().attr('data-hide_text');

            function checkContainer() {
                setTimeout(() => {
                    $('[data-feedback_container]').removeClass('show');
                    $('[data-feedback_container]').each(function () {
                        var containerHeight = $(this).outerHeight(),
                            childHeight = $(this).children().first().outerHeight();

                        if (childHeight <= containerHeight) {
                            $(this).addClass('show');
                            //$(this).parent().find('[data-feedback_trigger_container]').addClass('hidden');
                        } else {
                            $(this).removeClass('show');
                            //$(this).parent().find('[data-feedback_trigger_container]').removeClass('hidden');
                        }
                    });

                }, 0);
            }

            const swiper = new Swiper('[data-feedback_slider]', {
                slidesPerView: 1,
                spaceBetween: 24,
                loop: true,

                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                on: {
                    afterInit: checkContainer(),

                    resize: checkContainer(),

                    slideChange: function () {
                        $('[data-feedback_trigger]').html(showText);
                        checkContainer();
                    },
                },

                breakpoints: {
                    768: {
                        slidesPerView: 2,
                    },

                    992: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                        centeredSlides: true,
                        initialSlide: 1,
                        allowTouchMove: false
                    }
                }
            });
        }
    }
}
</script>
