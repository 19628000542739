<template>
    <Head title="Email подтверждён | Seller board" />

    <div class="reg_auth_row">
        <div class="logo_block">
            <a :href="$page.props.app.landing_url">
                <img src="/img/logo-auth.svg" alt="logo">
            </a>
        </div>
        <div>
            <Link :href="route('home')" class="auth_link login__link">
                Войти
            </Link>
        </div>
    </div>

    <div class="auth__wrapper">
        <div class="form__outer">
            <div class="confirm__block">
                <div class="confirm_title">Ваш адрес электронной почты подтвержден.</div>
                <div class="confirm_description"> Перенаправляем в Вашу учетную запись...</div>
            </div>
            <div class="feedback_block long_padding">
                <p>Свяжитесь с нами:<br><a target="_blank" href="mailto:support@sellerdata.ru">support@sellerdata.ru</a> или <a href="https://t.me/sellerdata" target="_blank">Telegram</a></p>
            </div>
        </div>

    </div>

</template>

<script>
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import { Head, Link } from '@inertiajs/inertia-vue3';

export default {
    layout: BreezeGuestLayout,

    components: {
        Head,
        Link,
    },

    created(){
        setTimeout( () => {
            this.$inertia.visit('/');
        }, 3000);

    }
}
</script>
