<template>
    <div v-if="chartTrue">
        <div class="periodSelectBlocks periodSelectBlocks_list">
            <template v-for="period in periods">
                <div
                     class="periodSelectBlock"
                     :class="{active: currentRange.id === period.id}"
                     @click="select(period)">
                    <i class="periodSelectBlock__ico fm-check"></i>
                    <span>{{ period.title.split(':')[0] }} {{ period.title.split(':')[1] }}</span>
                </div>
            </template>
        </div>
    </div>
    <div v-else>
        <div v-if="styleType === 2" class="periodSelectBlocks">
            <template v-for="period in periods">
                <div v-if="period.id === 7"
                     :id="'data-custom_range_'+randomIndex"
                     :class="{active: currentRange.id === period.id}"
                     class="periodSelectBlock periodSelectBlock_custom" id="scrollButton">
                    <i class="periodSelectBlock__ico fm-check"></i>
                    <span>{{ period.title }}</span>
                </div>
                <div v-else
                     class="periodSelectBlock"
                     :class="{active: currentRange.id === period.id}"
                     @click="select(period)">
                    <i class="periodSelectBlock__ico fm-check"></i>
                    <span>{{ period.title }}</span>
                </div>
            </template>
        </div>
        <div v-else id="periodSelectDefault" :class="{ 'dropMenu-opened': opened }" class="dropDown-parent"
             v-click-outside="onClickOutside">
            <div v-on:click="open()" class="periodSelectInput input-dropDown">
                <label><span class="fm-calendar"></span></label>
                <div class="dropDownData">{{ currentRange.title }}</div>
            </div>
            <div class="dropdown--menu">
                <ul class="dropList">
                    <template v-for="period in periods">
                        <li v-if="period.id == 7" data-custom_range>
                            <span>{{ period.title }}</span>
                        </li>
                        <li v-else>
                            <span v-on:click="select(period)">{{ period.title }}</span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'periods',
        'current',
        'dateFrom',
        'dateTo',
        'dateFirstEvent',
        'styleType',
        'chartTrue'
    ],
    emits: [
        'changed',
        'changedDates'
    ],
    data() {
        return {
            randomIndex:Math.floor(Math.random() * 100) + 1,
            opened: false,
            currentRange: {},
            from: null,
            to: null,
            fromLimit:null,
        };
    },
    methods: {
        open() {
            this.opened = !this.opened;
        },

        onClickOutside() {
            this.opened = false;
        },

        select(range) {
            this.currentRange = range;
            this.open();
            this.$emit('changed', this.currentRange)
        },
        getPeriodById(id){
            let p = null;
            this.periods.forEach((period) => {
                if (period.id === id)
                    p = period;
            });
            return p;
        },
        initCustomRange() {
            let customRange = $('#data-custom_range_'+this.randomIndex);
            let self = this;
            let start = moment(self.from);
            let end = moment(self.to);

            function dashboardCb(start, end) {
                self.from = start.format('YYYY-MM-DD');
                self.to = end.format('YYYY-MM-DD');
                self.$emit('changedDates', {dateFrom: self.from, dateTo: self.to})
                self.currentRange = self.getPeriodById(7);
                //$('#reportRange span').html(start.format('D MMMM YYYY').toLowerCase() + ' - ' + end.format('D MMMM YYYY').toLowerCase());
            }
            customRange.daterangepicker({
                startDate: start,
                endDate: end,
                parentEl: customRange.parent(),
                opens: 'right',
                drops: 'down',
                autoApply: true,
                minDate: moment(self.fromLimit),
                maxDate: moment(),
                locale: {
                    direction: 'ltr',
                    format: 'DD.MM.YYYY',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    fromLabel: 'с',
                    toLabel: 'по',
                    customRangeLabel: 'Произвольный период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    firstDay: 1
                },
                timePicker: false,
            }, dashboardCb);

            customRange.on('show.daterangepicker',()=>{
                $(".modal-body").animate({ scrollTop: $(document).height() }, 500);});

            //dashboardCb(start, end);
        }
    },
    mounted() {
        this.currentRange = this.current;
        this.from = this.dateFrom;
        this.to = this.dateTo;
        this.fromLimit = this.dateFirstEvent;
        setTimeout(()=>{
            this.initCustomRange();
        }, 500)
    }
}
</script>
