<template>

    <div v-bind:class="active ? 'active' : ''" class="more_popover_outer">
        <a v-on:click="toggle" class="open_more_popover"
           style="cursor: pointer; color: #4154F1;">Больше</a>
        <div class="more_popover_item" style="z-index: 9999;">
            <div class="close_more_popover" v-on:click="toggle"><span class="fm-close"></span></div>
            <div class="more_popover_item_inner">
                <ul class="more_item_list">
                    <li class="has-children">
                        <div class="toggle_slide" :data-tippy-content="__('hints.dashboard.realSalesAmount')">Продажи <span class="more_data whitespace-nowrap"><Currency :value="data.realSalesAmount"/></span></div>
                        <ul class="children_ul">
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.quantity')">Количество товара <span>{{ data.quantity }}</span></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.salesAmount')">Продажи без скидки <Currency :value="data.salesAmount"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.supplierDiscount')">Скидка <Currency :value="data.supplierDiscount"/></div>
                            </li>
                        </ul>
                    </li>

                    <li class="has-children" v-if="section && section === 'orders'">
                        <div class="toggle_slide" :data-tippy-content="__('hints.dashboard.orders')">Заказы <span class="more_data">₽ {{ data.ordersTotal }}</span></div>
                        <ul class="children_ul">
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.ordersQuantity')">Единицы <span>{{ data.ordersQuantity }}</span></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.ordersTotalWithoutDiscount')">Сумма заказов <span>₽ {{ data.ordersTotalWithoutDiscount }}</span></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.ordersTotal')">Скидка <span>₽ {{ data.ordersTotalDiscount }}</span></div>
                            </li>
                        </ul>
                    </li>

                    <li v-on:click="toggleDetails('refundsOpened')" :class="{ active: refundsOpened }" class="has-children">
                        <div :data-tippy-content="__('hints.dashboard.refundsAmount')">Возвраты <span class="more_data"><Currency :value="data.refundsAmount"/></span>
                        </div>
                        <ul class="children_ul" :style="{ display: refundsOpened ? 'block' : 'none'}">
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.refunds')">Количество товаров <span>{{ data.refunds }}</span></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.clientReward')">Возмещение покупателям <Currency :value="data.clientReward"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.returnCost')">Себестоимость <Currency :value="data.returnCost"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.returnCommission')">Возмещение вознаграждения <Currency :value="data.returnCommission"/></div>
                            </li>
                        </ul>
                    </li>
                    <li v-on:click="toggleDetails('wbOpened')" :class="{ active: wbOpened }" class="has-children">
                        <div :data-tippy-content="__('hints.dashboard.deduction')">Удержания WB <span class="more_data"><Currency :value="data.deduction"/></span></div>
                        <ul class="children_ul" :style="{ display: wbOpened ? 'block' : 'none'}">
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.penalty')">Штрафы <Currency :value="data.penalty"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.penalty_return')">Аннулирование штрафов <Currency :value="data.penalty_return"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.additional_payment')">Доплаты <Currency :value="data.additional_payment"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.additional_payment_return')">Аннулирование доплат <Currency :value="data.additional_payment_return"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.logisticCost')">Доставка <Currency :value="data.logisticCost"/></div>
                            </li>
                            <li v-if="data.stornoDelivery">
                                <div :data-tippy-content="__('hints.dashboard.stornoDelivery')">Сторно доставки <Currency :value="data.stornoDelivery"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.commission')">Вознаграждение <Currency :value="data.commission"/></div>
                            </li>
                            <li>
                                <div :data-tippy-content="__('hints.dashboard.redeemsExpenses')">Расходы на самовыкупы <Currency :value="data.redeemsExpenses"/></div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div :data-tippy-content="__('hints.dashboard.goodsCost')">Себестоимость <span class="more_data"><Currency :value="data.goodsCost"/></span></div>
                    </li>
                    <li>
                        <div :data-tippy-content="__('hints.dashboard.grossProfit')">Валовая прибыль <span class="more_data"><Currency :value="data.grossProfit"/></span></div>
                    </li>
                    <li>
                        <div :data-tippy-content="__('hints.dashboard.redeems')">Услуги самовыкупа <span class="more_data"><Currency :value="data.redeems"/></span></div>
                    </li>
                    <li>
                        <div :data-tippy-content="__('hints.dashboard.expenses')">Расходы <span class="more_data"><Currency :value="data.expenses"/></span></div>
                    </li>
                    <li v-if="!!$page.props.auth.user.user_account.account.tax_enabled">
                        <div :data-tippy-content="__('hints.dashboard.tax')">Налог <span class="more_data"><Currency :value="data.tax"/></span></div>
                    </li>
                    <li>
                        <div :data-tippy-content="__('hints.dashboard.profit')">Прибыль <span class="more_data"><Currency :value="data.profit"/></span></div>
                    </li>
                    <li>
                        <div :data-tippy-content="__('hints.dashboard.wbPay')">Сумма выплат WB <span class="more_data"><Currency :value="data.wbPay"/></span></div>
                    </li>
                    <template v-if="data.quantity">
                        <li>
                            <div :data-tippy-content="__('hints.dashboard.margin')">Маржа <span class="more_data">{{ data.margin }} %</span></div>
                        </li>
                        <li>
                            <div :data-tippy-content="__('hints.dashboard.roi')">ROI <span class="more_data">{{ data.roi }} %</span></div>
                        </li>
                        <template v-if="data.stable">
                            <li :data-tippy-content="__('hints.dashboard.redemption')">
                                <div>Выкупаемость,% <span class="more_data">{{ data.redemption }}</span></div>
                            </li>
                        </template>
                        <template v-else>
                            <li :data-tippy-content="__('hints.dashboard.redemption')">
                                <div>Выкупаемость,% <span class="more_data">-</span></div>
                            </li>
                        </template>
                    </template>
                    <template v-else>
                        <li>
                            <div :data-tippy-content="__('hints.dashboard.margin')">Маржа,% <span class="more_data">-</span></div>
                        </li>
                        <li>
                            <div :data-tippy-content="__('hints.dashboard.roi')">ROI,% <span class="more_data">-</span></div>
                        </li>
                        <li>
                            <div :data-tippy-content="__('hints.dashboard.redemption')">Выкупаемость,% <span class="more_data">-</span></div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import Currency from '@/Components/Common/Currency.vue'

export default {
    components: { Currency },
    props: [
        'data',
        'section'
    ],
    data() {
        return {
            active: false,
            wbOpened: false,
            refundsOpened: false,
        };
    },
    methods: {
        toggle(event) {
            this.active = !this.active;
            event.stopPropagation();
        },
        toggleDetails(item) {
            this[item] = !this[item];
        }
    },
}
</script>
