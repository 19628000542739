<template>
    <form action="">
        <div class="files-input">
            <div
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop">
                <input class="files-input__input" id="reportsInput" type="file" multiple @change="onChange" ref="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.zip">
                <label class="files-input__label" for="reportsInput" :class="isDragging ? 'highlight': ''">
                    <span class="fm-file-text"></span>
                    {{ inputLabel }}
                </label>
            </div>

            <div class="files-input__list" v-if="files.length">
                <div class="files-input__file" v-for="file in files" :key="file.name">


                    <div class="files-input__file__done fm-check" v-if="fileLoaded.includes(file.name)"></div>

                    <div class="files-input__file__done fm-alert-circle" v-else-if="fileErrors.includes(file.name)"></div>

                    <div class="files-input__file__spinner" v-else>
                        <span class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                       </span>
                    </div>

                    <div v-if="fileErrors.includes(file.name)" class="files-input__file__body">
                        <div class="files-input__file__name">{{ file.name }}</div>
                        <div class="files-input__file__progress">
                            <div class="files-input__file__bar" style="width:1%"></div>
                        </div>
                    </div>
                    <div v-else class="files-input__file__body">
                        <div class="files-input__file__name">{{ file.name }}</div>
                        <div class="files-input__file__progress">
                            <div class="files-input__file__bar" :style="fileLoaded.includes(file.name) ? 'width:100%' : 'width: 50%;'"></div></div>
                    </div>

                    <button class="files-input__file__remove fm-x" type="button" aria-label="Удалить" @click="remove(files.indexOf(file))"></button>
                </div>
            </div>

<!--            <div class="files-input__footer">-->
<!--                <button class="btn-default btn-regular" type="submit" disabled>Сохранить</button>-->
<!--            </div>-->
        </div>
    </form>
</template>

<script>

export default {
    props: [
        'inputLabel',
        'fileErrors',
        'fileLoaded'
    ],
    emits:[
        'upload'
    ],
    data() {
        return {
            isDragging: false,
            files: [],
        };
    },
    methods: {
        onChange() {
            const self = this;
            let incomingFiles = Array.from(this.$refs.file.files);
            const fileExist = self.files.some((r) =>
                incomingFiles.some(
                (file) => file.name === r.name && file.size === r.size
                )
            );
            if (fileExist) {
                self.showMessage = true;
                alert('Эти файлы уже добавлены');
            } else {
                self.files.push(...incomingFiles);
                this.$emit('upload',incomingFiles);
            }

        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        uploadFiles() {
            const files = this.files;
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('selectedFiles', file);
            });

            // axios({
            //     method: "POST",
            //     url: "http://path/to/api/upload-files",
            //     data: formData,
            //     headers: {
            //         "Content-Type": "multipart/form-data",
            //     },
            // });
        },
    },
};
</script>
