<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="filterRangesModal" tabindex="-1" aria-labelledby="filterRangesModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content" :class="(filterSection === 'periods') ? 'modal-content_grey': ''">
                <div class="modal-header">
                    <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>

                    <h5 class="modal-title" id="filterRangesModalTitle">Период</h5>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div class="filter-periods" v-show="filterSection === ''">
                        <DashboardDateRanges v-bind:dateFrom="dateFrom"
                                             v-bind:dateTo="dateTo"
                                             v-bind:periods="dateRangeSets"
                                             v-bind:current="currentRangeSet"
                                             v-bind:dateFirstEvent="dateFirstEvent"
                                             v-bind:styleType="2"
                                             v-bind:chartTrue="chartTrue ?? false"
                                             @changedDates="datesChanged"
                                             @changed="rangeSetChanged"></DashboardDateRanges>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="apply" data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Фильтровать</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductsSelector from "@/Components/Products/ProductsSelector";
import DashboardDateRanges from "@/Components/Dashboard/DashboardDateRanges";
export default {
    components: {
        ProductsSelector,
        DashboardDateRanges
    },
    props: [
        'query',
        'productsSelected',
        'dateFrom',
        'dateTo',
        'dateRangeSets',
        'currentRangeSet',
        'dateFirstEvent',
        'chartTrue',
    ],
    emits: [
        'apply',
        'selectedProductsChanged',
        'datesChanged',
        'rangeSetChanged'
    ],
    data() {
        return {
            filterSection: '',
            productsSelectedLocal: []
        };
    },
    methods: {
        apply(){
            this.$emit('apply');
        },
        selectedProductsChanged(data)
        {
            this.productsSelectedLocal = data.productIds;
            this.$emit('selectedProductsChanged', data);
        },
        datesChanged(dates){
            this.$emit('datesChanged', dates);
        },
        rangeSetChanged(rangeSet){
            this.$emit('rangeSetChanged', rangeSet);
        },
        changeFilterSection(filterSection = ''){
            this.filterSection = filterSection;
            if (filterSection === 'products'){
                this.$refs.ProductsSelector.openMultiselect(true);
            }

        },
        toggleModals(parent_modal, target_modal) {
            $(parent_modal).modal('hide');
            setTimeout(() => {
                $(target_modal).modal('show');
            }, 0);
        },
        jsInit(){
            let self = this;

            if (typeof window.isDashboardFilterModalJsMounted == 'undefined'){

                let clickHandler = 'click';

                if('ontouchstart' in document.documentElement) {
                    clickHandler = 'touchend';
                }

                console.log('Mounted: isDashboardFilterModalJsMounted');

                window.isDashboardFilterModalJsMounted = true;
            }

            $(document).off('hidden.bs.modal', '#filterModal');
            $(document).on('hidden.bs.modal', '#filterModal', function (e) {
                self.filterSection = '';
            })



        },
    },
    mounted() {
        this.jsInit();
        this.productsSelectedLocal = this.productsSelected;
    }
}
</script>
