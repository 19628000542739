<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="expensesFilterModalMob" tabindex="-1" aria-labelledby="expensesFilterModalMobTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button v-if="(filterSection === 'periods' || filterSection === 'categories')" class="modal-back modal-back_show" type="button" aria-label="Back" @click="changeFilterSection('')">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <button v-else class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>


                    <h5 v-if="filterSection === 'periods'" class="modal-title" id="expensesFilterModalMobTitle" @click="changeFilterSection('')">Период</h5>
                    <h5 v-else-if="filterSection === 'categories'" class="modal-title" id="expensesFilterModalMobTitle" @click="changeFilterSection('')">Категории</h5>
                    <h5 v-else class="modal-title" id="expensesFilterModalMobTitle">Фильтр</h5>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div v-show="filterSection === ''" class="filter-list">
                        <button class="filter-list__item" type="button" @click="changeFilterSection('periods')">
                            <i class="filter-list__ico fm-calendar"></i>
                            <span class="filter-list__title">{{ activeRange.title }}</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>
                        <button class="filter-list__item" type="button" @click="changeFilterSection('categories')">
                            <i class="filter-list__ico fm-list"></i>
                            <span v-if="category.length" class="filter-list__title">Категории</span>
                            <span v-else class="filter-list__title">Все категории</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>
                    </div>

                    <div class="datePicker_outer datePicker_outer_ml-auto" v-show="filterSection === 'periods'">
<!--                        <div id="reportRange" class="datePicker_inner">
                            <span></span>
                        </div>-->


                        <FilterDateRanges v-bind:dateFrom="queryFilter.dateFrom"
                                             v-bind:dateTo="queryFilter.dateTo"
                                             v-bind:periods="dateRangeSets"
                                             v-bind:datesAreDefault="true"
                                             v-bind:current="activeRange"
                                             v-bind:styleType="2"
                                             @changedDates="datesChanged"></FilterDateRanges>

                    </div>

                    <div class="filter-categories" v-show="filterSection === 'categories'">
                        <div class="multi-select">
                            <div class="multi-select__dropdown">
                                <div class="multi-select__dropdown__body">
                                    <div v-for="(item, index) in category" class="input">
                                        <div class="input__inner">
                                            <input class="input__checkbox" :checked="this.filter.category === item.id" :id="'category-'+item.id" :value="item.id" @change="categoryChanged(item.id)" name="category" type="radio">
                                            <label class="input__checkbox-label" :for="'category-'+item.id">{{ item.text }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" aria-label="Close" class="btn-default" v-show="filterSection === ''">Закрыть</button>
                    <button v-if="(filterSection === 'periods' || filterSection === 'categories')" class="btn-default btn-regular" @click="changeFilterSection('')">Сохранить</button>
                    <button v-else @click="apply" data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Фильтровать</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilterDateRanges from '@/Components/Common/FilterDateRanges.vue'
export default {
    components: {
        FilterDateRanges
    },
    props: [
        'filter',
        'category'
    ],
    emits: [
        'apply'
    ],
    data() {
        let dateRangeSets = this.__getFilterDateRangeSets();
        return {
            filterSection: '',
            queryFilter: {},
            dateRangeSets: dateRangeSets,
            currentRangeSet:this.filter.activeRange ? dateRangeSets[this.filter.activeRange] : dateRangeSets[0],
        };
    },
    computed:{
        filterClientDataNS() {
            return `account-${this.$page.props.auth.user.user_account.account_id}/expenses/filter`.toUpperCase();
        },
        activeRange(){
            return this.currentRangeSet;
        }
    },
    methods: {
        datesChanged(data){
            this.queryFilter.dateFrom = data.dateFrom;
            this.queryFilter.dateTo = data.dateTo;
            this.queryFilter.activeRange = data.activeRange;
            if(data.activeRange === 'custom'){
                this.currentRangeSet = this.dateRangeSets[4];
            }
            else {
                this.currentRangeSet = this.dateRangeSets[data.activeRange - 1];
            }
        },
        categoryChanged(id){
            this.queryFilter.category = id;
        },
        apply(){
            this.$emit('apply', this.queryFilter,true);
        },
        initDatePickerGlobal: function () {
            let self = this;
            let start = moment(self.queryFilter.dateFrom);
            let end = moment(self.queryFilter.dateTo);

            function cb(start, end) {
                self.queryFilter.dateFrom = start.format('YYYY-MM-DD');
                self.queryFilter.dateTo = end.format('YYYY-MM-DD');
                $('#reportRange span').html(start.format('D MMMM YYYY').toLowerCase() + ' - ' + end.format('D MMMM YYYY').toLowerCase());

                // self.filterSubmit();
            }

            let reportRange = $('#reportRange');

            reportRange.daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Один месяц': [moment().startOf('month'), moment().endOf('month')],
                    'Три месяца': [moment().subtract(2, 'month').startOf('month'), moment().endOf('month')],
                    'Шесть месяцев': [moment().subtract(5, 'month').startOf('month'), moment().endOf('month')],
                    'Текущий год': [moment().startOf('year'), moment().endOf('year')],
                },
                locale: {
                    direction: 'ltr',
                    format: 'YYYY-MM-DD',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    fromLabel: 'с',
                    toLabel: 'по',
                    customRangeLabel: 'Произвольный период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    firstDay: 1
                },
                timePicker: false,
                //autoApply : true
            }, cb);

            reportRange.on('apply.daterangepicker', function(ev, picker) {
                self.queryFilter.dateFrom = picker.startDate.format('YYYY-MM-DD');
                self.queryFilter.dateTo = picker.endDate.format('YYYY-MM-DD');
                $('#reportRange span').html(picker.startDate.format('D MMMM YYYY').toLowerCase() + ' - ' + picker.endDate.format('D MMMM YYYY').toLowerCase());

                //self.apply();
            });

            cb(start, end);

            updateConfig();

            function updateConfig() {
                let options = {};
            }
        },

        changeFilterSection(filterSection = ''){
            this.filterSection = filterSection;
        },

        jsInit(){
            let self = this;

            $(document).off('hidden.bs.modal', '#filterModal');
            $(document).on('hidden.bs.modal', '#filterModal', function (e) {
                self.filterSection = '';
            })
        },
    },
    created() {
        this.jsInit();
        //this.initDatePickerGlobal();
        const clientFilterData = localStorage.getItem(this.filterClientDataNS);
        this.queryFilter = this.filter;

        if (clientFilterData) {
            const filterData = JSON.parse(clientFilterData);
            if(filterData.activeRange === 'custom'){
                let range = {
                    id: 'custom',
                    title: 'Произвольный период',
                    dateFrom: filterData.dateFrom,
                    dateTo: filterData.dateTo
                }
                this.currentRangeSet = range;
            }
            else if(filterData.activeRange) {
                this.currentRangeSet = this.dateRangeSets[filterData.activeRange - 1];
            }
            else {
                this.currentRangeSet = this.dateRangeSets[0];
            }
        }

    }
}
</script>
