export const filterDateRangeSets = {
    methods: {
        __getFilterDateRangeSets() {
            let dateFormat = 'YYYY-MM-DD';
            return [
                {
                    id: 1,
                    title: 'Один месяц',
                    dateFrom: moment().startOf('month').format(dateFormat),
                    dateTo: moment().endOf('month').format(dateFormat)
                },
                {
                    id: 2,
                    title: 'Три месяца',
                    dateFrom: moment().subtract(2, 'month').startOf('month').format(dateFormat),
                    dateTo: moment().endOf('month').format(dateFormat)
                },
                {
                    id: 3,
                    title: 'Шесть месяцев',
                    dateFrom: moment().subtract(5, 'month').startOf('month').format(dateFormat),
                    dateTo: moment().endOf('month').format(dateFormat)
                },
                {
                    id: 4,
                    title: 'Текущий год',
                    dateFrom: moment().startOf('year').format(dateFormat),
                    dateTo: moment().endOf('year').format(dateFormat)
                },
                {
                    id: 'custom',
                    title: 'Произвольный период',
                    dateFrom: null,
                    dateTo: null
                }
            ];
        },
    }
}
