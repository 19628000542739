<template>
    <div class="text-page">
        <div class="container">
            <div class="text-page__inner">
                <div class="text-page__logo-header">
                    <a class="page_connect_back" href="/connecting" aria-label="Вернуться назад">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.884 6.70597L16 14.59L8.11602 6.70597C7.9264 6.53245 7.67714 6.43878 7.42017 6.44448C7.1632 6.45017 6.91833 6.55479 6.73658 6.73654C6.55484 6.91829 6.45022 7.16315 6.44452 7.42012C6.43883 7.67709 6.5325 7.92635 6.70602 8.11597L14.586 16L6.70402 23.882C6.60773 23.9737 6.53075 24.0838 6.47761 24.2058C6.42448 24.3277 6.39626 24.4591 6.39461 24.5921C6.39296 24.7251 6.41792 24.8571 6.46802 24.9803C6.51812 25.1035 6.59235 25.2155 6.68634 25.3096C6.78033 25.4038 6.89219 25.4781 7.01535 25.5284C7.1385 25.5787 7.27046 25.6038 7.40348 25.6024C7.53649 25.6009 7.66787 25.5729 7.78989 25.5199C7.91191 25.467 8.02211 25.3901 8.11402 25.294L16 17.412L23.884 25.296C24.0736 25.4695 24.3229 25.5632 24.5799 25.5575C24.8368 25.5518 25.0817 25.4472 25.2635 25.2654C25.4452 25.0837 25.5498 24.8388 25.5555 24.5818C25.5612 24.3249 25.4675 24.0756 25.294 23.886L17.41 16.002L25.294 8.11597C25.3903 8.0242 25.4673 7.91411 25.5204 7.79216C25.5736 7.67022 25.6018 7.53888 25.6034 7.40587C25.6051 7.27286 25.5801 7.14086 25.53 7.01763C25.4799 6.89441 25.4057 6.78244 25.3117 6.68832C25.2177 6.59419 25.1058 6.5198 24.9827 6.46953C24.8595 6.41926 24.7276 6.39411 24.5946 6.39557C24.4616 6.39703 24.3302 6.42506 24.2082 6.47802C24.0861 6.53099 23.9759 6.60781 23.884 6.70397V6.70597Z" fill="#999999"/>
                        </svg>
                    </a>
                    <img class="text-page__logo" src="/img/logo_blue.png" alt="logo">
                </div>

                <h1>Насколько безопасно прописывать свой ключ API на нашем сервисе? </h1>
                <p>Равным образом, постоянное информационно-пропагандистское обеспечение нашей деятельности прекрасно подходит для реализации приоретизации разума над эмоциями. Таким образом, дальнейшее развитие различных форм деятельности говорит о возможностях вывода текущих активов. Идейные соображения высшего порядка, а также высокое качество позиционных исследований говорит о возможностях дальнейших направлений развития. И нет сомнений, что предприниматели в сети интернет в равной степени предоставлены сами себе.</p>
                <h2>Title h2</h2>
                <p>Не следует, однако, забывать, что сложившаяся структура организации требует от нас анализа распределения внутренних резервов и ресурсов. Равным образом, консультация с широким активом, а также свежий взгляд на привычные вещи — безусловно открывает новые горизонты для стандартных подходов. <strong>Повседневная практика</strong> показывает, что экономическая повестка сегодняшнего дня предопределяет высокую востребованность экспериментов, поражающих по своей масштабности и грандиозности. Безусловно, граница обучения кадров однозначно определяет каждого участника как способного принимать собственные решения касаемо прогресса профессионального сообщества. <i>Равным образом, дальнейшее развитие различных форм деятельности позволяет выполнить важные задания по разработке поэтапного и последовательного развития общества</i>.</p>
                <h3>Title h3</h3>
                <ol>
                    <li>Мы вынуждены отталкиваться от того, что экономическая повестка сегодняшнего дня влечет за собой процесс внедрения и модернизации укрепления моральных ценностей.</li>
                    <li>Современные технологии достигли такого уровня, что синтетическое тестирование является качественно новой ступенью позиций, занимаемых участниками в отношении поставленных задач.</li>
                    <li>С другой стороны, перспективное планирование влечет за собой процесс внедрения и модернизации распределения внутренних резервов и ресурсов. </li>
                    <li>Есть над чем задуматься: некоторые особенности внутренней политики объявлены нарушающими общечеловеческие нормы этики и морали.</li>
                </ol>
                <h3>Title h3</h3>
                <ul>
                    <li>Мы вынуждены отталкиваться от того, что экономическая повестка сегодняшнего дня влечет за собой процесс внедрения и модернизации укрепления моральных ценностей.</li>
                    <li>Современные технологии достигли такого уровня, что синтетическое тестирование является качественно новой ступенью позиций, занимаемых участниками в отношении поставленных задач.</li>
                    <li>С другой стороны, перспективное планирование влечет за собой процесс внедрения и модернизации распределения внутренних резервов и ресурсов. </li>
                    <li>Есть над чем задуматься: некоторые особенности внутренней политики объявлены нарушающими общечеловеческие нормы этики и морали.</li>
                </ul>

                <div class="text-page__footer">
                    <div class="final-form final-form__footer">
                        <form id="keyForm" action="#" @submit.prevent="submit">
                            <div class="form-group">
                                <label for="keyInput" style="display: none"></label>
                                <BreezeInput id="keyInput" type="text" :class="{form_error: form.errors.apiKey}" class="form-control" v-model="form.apiKey"
                                                placeholder="API-ключ WB" autocomplete="new-key"/>
                            </div>
                            <button class="key-form-button" type="submit">
                                Подключить
                                <span class="btn-loader" v-show="isFormProcessing && !form.errors.apiKey">
                                    <span class="btn-loader__inner">
                                        <span class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import {pickBy} from "lodash-es";
import {Inertia} from "@inertiajs/inertia";

export default {
    components: {
        BreezeCheckbox,
        BreezeInput,
        BreezeValidationErrors,
        Head,
        Link
    },

    data() {
        return {
            form: this.$inertia.form({
                apiKey: ''
            }),
            isFormProcessing: false,
        }
    },
    mounted() {

    },
    methods: {

        submit() {
            let self = this;
            this.isFormProcessing = true;
            this.form.errors = {};
            this.form.post(this.route('apiKey'), {
                preserveScroll: true,

                onFinish: () => {
                    ym(87932746,'reachGoal','successful_key');
                },
                onError: function(errors){
                    self.isFormProcessing = false;
                    if (errors.modal)
                        $('#errorKey').modal();
                },
            })
        },

    }
}
</script>
