<template>
    <div v-for="value in notifications">
        <div class="data_loader_block" data-data_loader_block>
            <div class="load-ring"></div>
            <p><strong>{{ value.title }}</strong></p>
            <p>{{ value.message }}</p>
            <hr style="margin: 8px 0">
            <p>Обновление данных:</p>
            <ul>
                <li class="data_loader_block__loading-item">
                    <div :class="{'load-ring': true, 'finished': !accountInfo['in_updating']}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    </div>
                    Транзакции
                </li>
                <li class="data_loader_block__loading-item">
                    <div :class="{'load-ring': true, 'finished': !accountInfo['products_updating']}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    </div>
                    Товары
                </li>
                <li class="data_loader_block__loading-item">
                    <div :class="{'load-ring': true, 'finished': !accountInfo['orders_updating_first_month'] && !accountInfo['orders_updating'], 'finished-half': !accountInfo['orders_updating_first_month'] && accountInfo['orders_updating']}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    </div>
                    Заказы
                    <i class="fm-info" :class="{'finished-half': !accountInfo['orders_updating_first_month'] && accountInfo['orders_updating']}" :data-toast-tippy-content="__('hints.dashboard.orders_half_loaded')"></i>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    props: ['notifications', 'accountInfo'],

    data() {
        return {
            show: true
        }
    },

    methods: {
    },
    mounted() {
        const toastTippy = document.querySelector('[data-toast-tippy-content]');
        if (toastTippy) {
            const tippyContent = toastTippy.getAttribute('data-toast-tippy-content');
            tippy(toastTippy, {
                content: tippyContent,
                trigger: 'mouseenter focus',
                placement: 'bottom',
                arrow: false,
                zIndex: 600
            });
        }
    },
    watch: {
        'accountInfo.orders_updating_first_month': function(newValue, oldValue){
            const toastTippy = document.querySelector('[data-toast-tippy-content]');
            if (toastTippy) {
                const tippyContent = toastTippy.getAttribute('data-toast-tippy-content');
                tippy(toastTippy, {
                    content: tippyContent,
                    trigger: 'mouseenter focus',
                    placement: 'bottom',
                    arrow: false,
                    zIndex: 600
                });
            }
        },
    },
}
</script>
