<template>
    <div v-show="show" class="empty_banner">
        <div class="empty_banner_search">
            <span class="fm-carbon_search"></span>
        </div>
        <h4 class="empty_banner_title">Ничего не найдено</h4>
        <p class="empty_banner_description">Попробуйте поменять параметры фильтра и попробовать снова</p>
    </div>
</template>

<script>
export default {
    props: [
        'show',
    ],
}
</script>
