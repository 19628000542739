<template>
    <Head title="Reset Password" />

    <div class="reg_auth_row">
      <div class="logo_block">
        <a :href="$page.props.app.landing_url">
          <img src="/img/logo-auth.svg" alt="logo">
        </a>
      </div>
      <div>
        <Link :href="route('home')" class="auth_link login__link">
          Войти
        </Link>
      </div>
    </div>

    <div class="auth__wrapper">
      <div class="form__outer no-flex">
        <div class="auth__block">
          <div class="auth-block-inner">
            <div class="form-title text-center">
              <h6 class="size-16 ls-0_03em">Изменить пароль</h6>
            </div>
            <div class="info_block warning_block">
              <div class="info_block_icon"><span class="fm-alert-circle"></span></div>
              <div class="info_block_content">
                <div class="block_content_text">
                  Проверьте Ваш почтовый ящик и следуйте инструкции для изменения пароля.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="feedback_block long_padding">
          <p>Свяжитесь с нами:<br><a target="_blank" href="mailto:support@sellerdata.ru">support@sellerdata.ru</a> или <a href="https://t.me/sellerdata" target="_blank">Telegram</a></p>
        </div>
      </div>
    </div>
</template>

<script>
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import { Head, Link } from '@inertiajs/inertia-vue3';

export default {
    layout: BreezeGuestLayout,

    components: {
        Head,
        Link
    },
}
</script>
