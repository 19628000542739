<template>
    <a
        href="#"
        @click.prevent="showModal = true"
    >
        <span class="fm-trash"></span> Удалить
    </a>
    <Modal
        v-if="showModal"
        @close="showModal = false"
    >
        <template #title>
            <h5 class="modal-title">Вы уверены что хотите удалить магазин?</h5>
        </template>
        <template #body>
            <p>Все данные будут потеряны!!!</p>
        </template>
        <template #buttons>
            <button class="btn-default btn-cancel" type="button" @click.prevent="showModal = false">Отменить</button>
            <button
                class="btn-default btn-regular"
                @click.prevent="deleteAccount()"
            >Удалить</button>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/Components/Common/Modal.vue'

export default {
    name: 'DeleteAccountLink',
    components: { Modal },
    props: {
        accountId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            showModal: false,
        }
    },
    emits: ['notification', 'reload'],
    methods: {
        deleteAccount () {
            axios.post(
                this.route('deleteAccount'),
                { id: this.accountId }
            ).then(() => {
                this.$emit('notification', 'Магазин удален');
                this.$emit('reload');
                this.showModal = false;
            }).catch((error) => {
                this.$emit('notification', error);
                this.showModal = false;
            });
        }
    }
}
</script>
