<template>
    <div id="app_child">
<!--        <div>-->
<!--            <Link href="/">-->
<!--                <BreezeApplicationLogo class="w-20 h-20 fill-current text-gray-500" />-->
<!--            </Link>-->
<!--        </div>-->

    <slot/>
    </div>
</template>

<script>
import BreezeApplicationLogo from '@/Components/ApplicationLogo.vue'
import { Link } from '@inertiajs/inertia-vue3';

export default {
    components: {
        BreezeApplicationLogo,
        Link,
    }
}
</script>
