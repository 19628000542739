<template>
    <Head title="Dashboard"/>

    <BreezeAuthenticatedLayout>
        <template #header-title>
            <h1 class="page_title">{{ __("global.hint.products.title") }}</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">
                <BreezeDashboardInfo></BreezeDashboardInfo>

                <div class="table_outer table_outer_products">
                    <div class="dashboard-nav__btn-container">
                        <div class="dashboard-nav__search">
                            <div class="search_outer desktop_select">
                                <ProductsSelector
                                    ref="ProductsSelector2"
                                    style="margin-bottom:0;"
                                    v-bind:hidden="this.query.hidden"
                                    v-bind:products="productsSelected"
                                    @changed="selectedProductsChanged"
                                    @submit="filterClicked"></ProductsSelector>
                            </div>

                            <div class="filter_button">
                                <button v-on:click="filterClicked" :disabled="!queryModified" type="button"
                                        :class="queryModified ? 'active' : ''" class="index_filter_button"
                                        data-element=".filter_button" data-toggle="false"><span
                                    class="fm-filter"></span> Фильтр
                                </button>
                            </div>
                            <div class="filter_button ml-auto filter_button_align-center">
                                <!--
                                <button @click="showHidden()" class="index_filter_button ml-auto">
                                    <span v-show="query.hidden == 0">Скрытые товары</span>
                                    <span v-show="query.hidden == 1">Товары</span>
                                </button>
                                -->
                                <div class="input mr-2">
                                    <div class="input__inner">
                                        <input class="input__checkbox" type="checkbox" :checked="query.costempty == 1 ? true : false" @change="showEmptyCost" id="show-empty">
                                        <label class="input__checkbox-label" for="show-empty">Без себестоимости</label>
                                    </div>
                                </div>
                                <div class="input">
                                    <div class="input__inner">
                                        <input class="input__checkbox" type="checkbox" :checked="query.hidden == 1 ? true : false" @change="showHidden" id="show-hidden">
                                        <label class="input__checkbox-label" for="show-hidden">Показать скрытые</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="dashboard-nav__btn dashboard-nav__btn_products dashboard-nav__btn_mob"
                                :class="{active: query.products.length}" type="button" data-toggle="modal"
                                data-target="#productsFilterModal">
                            <i class="fm-filter"></i>
                        </button>
                    </div>

                    <div class="expenses-wrapper flex-column" :class="{'dashboard-wrapper no_items': !products.length}">
                        <div v-if="!Object.keys(products).length" class="empty_banner">
                            <div class="empty_banner_search">
                                <span class="fm-carbon_search"></span>
                            </div>
                            <h4 class="empty_banner_title">Ничего не найдено</h4>
                            <p class="empty_banner_description">Попробуйте поменять параметры фильтра и попробовать
                                снова</p>
                        </div>
                        <table v-else id="productTable"
                               class="table table-fw-widget products-table">
                            <thead>
                            <tr>
                                <th @click="sortByColumn('title')" class="sortable-header"
                                    :class="{'reorder_outer': sort.orderBy === 'title'}"
                                    width="30%">
                                    Товары
                                    <button v-show="sort.orderBy === 'title'" :class="{active: sort.desc === 0}"
                                            type="button" class="reorder_button"></button>
                                </th>
                                <th @click="sortByColumn('costPrice')" class="sortable-header"
                                    :class="{'reorder_outer': sort.orderBy === 'costPrice'}"
                                    width="10%">
                                    Себест.
                                    <button v-show="sort.orderBy === 'costPrice'" type="button"
                                            :class="{active: sort.desc === 0}" class="reorder_button"></button>
                                </th>
                                <th width="10%">Тип</th>
                                <th width="40%">Комментарий</th>
                                <th width="10%">
                                    <span v-show="query.hidden == 0">Скрыть</span>
                                    <span v-show="query.hidden == 1">Раскрыть</span>
                                </th>
                                <th width="1%"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, key) in products">
                                <tr class="products-ungrouped" v-if="item.products.length === 1">
                                    <td class="product_td" data-title="Товар">
                                        <div class="item">
                                            <img class="item__img"
                                                 :src="item.products[0].image ? item.products[0].image : '/img/product1.jpg'"
                                                 :alt="item.products[0].title" width="40" height="40"
                                                 onerror="this.src='/img/product1.jpg'"
                                            >
                                            <div class="item__body">
                                                <div class="item__data item__data_mob">
                                                    <ProductTitle :title="item.products[0].title" :warning="true"/>
                                                    <div class="item__product-id">
                                                        <button class="copy-btn" type="button"
                                                                data-bm-tooltip="Копировать"
                                                                @click="__copy(item.products[0].nm_id)"><span
                                                            class="fm-copy"></span></button>
                                                        <a class="item__link" :href="item.products[0].link"
                                                           target="_blank">{{ item.products[0].nm_id }}</a>
                                                    </div>
                                                    <div v-if="item.products[0].supplier_article">{{item.products[0].supplier_article}}</div>
                                                    <div>{{ item.products[0].barcode }}</div>
                                                </div>

                                                <div class="item__data item__data_desktop">
                                                    <ProductTitle :title="item.products[0].title" :warning="true"/>
                                                    <span class="item__product-id">
                                                            <button class="copy-btn" type="button"
                                                                    data-bm-tooltip="Копировать"
                                                                    @click="__copy(item.products[0].nm_id)"><span
                                                                class="fm-copy"></span></button>
                                                            <a class="item__link" :href="item.products[0].link"
                                                               target="_blank">{{ item.products[0].nm_id }}</a>
                                                        </span>&nbsp;
                                                    <span>
                                                        {{ item.products[0].supplier_article ? ' / ' + item.products[0].supplier_article : '' }} / {{ item.products[0].barcode }}
                                                        {{ item.products[0].discounted_price > 0 ? '&nbsp;/ Цена: ' : '' }}
                                                        <Currency v-if="item.products[0].discounted_price > 0" :value="item.products[0].discounted_price"/>
                                                        {{ item.products[0].tech_size ? ' / Размер: ' + item.products[0].tech_size : '' }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Себест. (нетто)" class="inner-text-block">
                                        <div class="item__data item__data_mob">
                                            {{ item.products[0].cost }}&nbsp;₽
                                        </div>
                                        <div class="item__data item__data_desktop">
                                            <button v-if="item.products[0].cost_type === 1"
                                                    class="inner-text-block__btn" type="button"
                                                    @click="costEdit(item.products[0].id)">
                                                <span>&#8381;</span>
                                                <span :id="'product-cost-'+item.products[0].id">{{
                                                        item.products[0].cost
                                                    }}</span>
                                            </button>
                                            <div v-else class="text_block price-block">
                                                <label>
                                                    <span>&#8381;</span>
                                                    <input :id="'product-cost-' + item.products[0].id"
                                                           v-bind:value="item.products[0].cost"
                                                           v-on:input="item.products[0].cost = $event.target.value"
                                                           placeholder="0"
                                                           data-price-parent
                                                           @focusin="hideZeroCost($event)"
                                                           @focusout="updateCostInline(item.products[0], true, $event)"
                                                           @keyup.enter="updateCostInline(item.products[0], true, $event)"
                                                           class="product-cost-input"
                                                           type="text">
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Тип">
                                        <Select2 v-model="item.products[0].cost_type_select2"
                                                 @select="costTypeChange(item.products[0], true, $event)"
                                                 :options="[{id: 0, text: 'За всё время'}, {id: 1, text: 'По периодам'}]"
                                                 :settings="{
                                                    theme: 'basic',
                                                    minimumResultsForSearch: -1,
                                                    width: '100%',
                                                }"/>
                                    </td>
                                    <td data-title="Комментарий" class="inner-text-block inner-text-block_top">
                                        <div class="text_block comment-block"
                                             :class="{'have_comment': item.products[0].comment !== null && item.products[0].comment !== ''}">
                                            <div class="comment-text">
                                                <label class="comment-text__label" :for="'desc'+item.products[0].id">Комментарий</label>
                                                <textarea
                                                    class="comment-text-inner"
                                                    @focusin="hideUpdBtn()"
                                                    @focusout="updateComment(item.products[0]); showUpdBtn()"
                                                    v-model="item.products[0].comment"
                                                    :id="'desc'+item.products[0].id"
                                                >{{ item.products[0].comment }}</textarea>
                                            </div>
                                            <span class="delete-comment" @click="removeComment(item.products[0])"><span
                                                class="fm-close"></span></span>
                                        </div>
                                    </td>
                                    <td class="checkbox-td">
                                        <div class="input">
                                            <div class="input__inner">
                                                <input class="input__checkbox"  @change="addToHide(item.products[0].id)"  :id="'prdct-'+item.products[0].id" type="checkbox">
                                                <label class="input__checkbox-label" :for="'prdct-'+item.products[0].id"></label>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <button class="product-more-btn fm-chevron-right" type="button"
                                                aria-label="Больше" @click="costEdit(item.products[0].id)"></button>
                                    </td>
                                </tr>
                                <template v-else>
                                    <tr class="products-grouped" data-toggle-products>
                                        <td class="product_td" data-title="Товар">
                                            <div class="item">
                                                <button class="table-accordion__trigger" type="button"
                                                        aria-label="Раскрыть/скрыть" data-toggle-products-row
                                                        :data-product="item.products[0].nmId"
                                                        aria-selected="false"></button>
                                                <img class="item__img" :src="item.products[0].image" width="40"
                                                     height="40" :alt="item.products[0].title"
                                                     onerror="this.src='/img/product1.jpg'">
                                                <div class="item__body">
                                                    <ProductTitle :title="item.products[0].title" :warning="true"/>
                                                    <div class="item__product-id">
                                                        <button class="copy-btn" type="button"
                                                                data-bm-tooltip="Копировать"
                                                                @click="__copy(item.products[0].nmId)"><span
                                                            class="fm-copy"></span></button>
                                                        <a class="item__link" :href="item.products[0].link"
                                                           target="_blank">{{ item.products[0].nmId }}</a>
                                                        <span v-if="item.products[0].supplier_article"> / {{item.products[0].supplier_article}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Себест. (нетто)" class="inner-text-block">
                                            <div class="item__data item__data_mob">
                                                <span v-if="item.products[0].cost_apply_all">{{
                                                        getValidProductById(item.products[0].id).cost
                                                    }}&nbsp;₽</span>
                                                <span v-else>—</span>
                                            </div>
                                            <div class="item__data item__data_desktop">
                                                <button v-if="item.products[0].cost_apply_all"
                                                        class="inner-text-block__btn" type="button"
                                                        @click="costEdit(item.products[0].id, null, true)">
                                                    <span>&#8381;</span>
                                                    <span :id="'product-cost-'+item.products[0].id">{{
                                                            getValidProductById(item.products[0].id).cost
                                                        }}</span>
                                                </button>
                                                <div v-else class="inner-text-block__btn disabled">
                                                    <span>₽</span>
                                                    <input type="text" value="—">
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Тип">
                                            <span class="product_period_status" v-if="item.products[0].cost_apply_all">{{
                                                    costTypes[item.products[0].cost_type]
                                                }}</span>
                                            <span class="product_period_status_none" v-else>—</span>
                                        </td>
                                        <td data-title="Комментарий" class="inner-text-block inner-text-block_top">
                                            <div class="text_block comment-block"
                                                 :class="{'have_comment': item.products[0].comment !== null && item.products[0].comment !== ''}">
                                                <div class="comment-text">
                                                    <label class="comment-text__label"
                                                           :for="'desc'+item.products[0].id">Комментарий</label>
                                                    <textarea
                                                        class="comment-text-inner"
                                                        @focusin="hideUpdBtn()"
                                                        @focusout="updateComment(item.products[0], true); showUpdBtn()"
                                                        v-model="item.products[0].nm_comment"
                                                        :id="'desc'+item.products[0].id"
                                                    >{{ item.products[0].nm_comment }}</textarea>
                                                </div>
                                                <span class="delete-comment"
                                                      @click="removeComment(item.products[0], true)"><span
                                                    class="fm-close"></span></span>
                                            </div>
                                        </td>
                                        <td class="checkbox-td">
                                            <div class="input">
                                                <div class="input__inner">
                                                        <input class="input__checkbox" @click="checkAllChildren(item.products,'prod-parent-'+item.products[0].id)" :id="'prod-parent-'+item.products[0].id" type="checkbox">
                                                    <label class="input__checkbox-label" :for="'prod-parent-'+item.products[0].id">
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button class="product-more-btn fm-chevron-right" type="button"
                                                    aria-label="Больше"
                                                    @click="costEdit(item.products[0].id, null, true)"></button>
                                        </td>
                                    </tr>
                                    <tr v-for="(product) in item.products"
                                        class="table-accordion__content hidden"
                                        :data-product_id="item.products[0].nmId">
                                        <td class="product_td" data-title="Товар">
                                            <div class="item">
                                                <div class="item__body">
                                                    <div class="item__data">
                                                        <div class="item__barcode">{{ product.barcode }}</div>
                                                        <span v-if="product.discounted_price > 0" class="whitespace-nowrap">Цена: <Currency
                                                            :value="product.discounted_price"/></span>
                                                        <span v-if="product.tech_size !== '' && product.tech_size !== 0 && product.tech_size !== 'null'">
                                                          {{ product.discounted_price > 0 ? ', Размер: ' + product.tech_size : 'размер: ' + product.tech_size }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Себест. (нетто)" class="inner-text-block">
                                            <div class="item__data item__data_mob">
                                                {{ product.cost }}&nbsp;₽
                                            </div>
                                            <div class="item__data item__data_desktop">
                                                <button v-if="product.cost_type === 1" class="inner-text-block__btn"
                                                        type="button" @click="costEdit(product.id)">
                                                    <span>&#8381;</span>
                                                    <span :id="'product-cost-'+product.id">{{ product.cost }}</span>
                                                </button>
                                                <div v-else class="text_block price-block">
                                                    <label>
                                                        <span>&#8381;</span>
                                                        <input :id="'product-cost-' + product.id"
                                                               v-bind:value="product.cost"
                                                               v-on:input="product.cost = $event.target.value"
                                                               placeholder="0"
                                                               @focusin="hideZeroCost($event)"
                                                               @focusout="updateCostInline(product, false, $event)"
                                                               @keyup.enter="updateCostInline(product, false, $event)"
                                                               class="product-cost-input"
                                                               type="text"
                                                               data-price-parent
                                                        >
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Select2 v-model="product.cost_type_select2"
                                                     @select="costTypeChange(product, false, $event)"
                                                     :options="[{id: 0, text: 'За всё время'}, {id: 1, text: 'По периодам'}]"
                                                     :settings="{
                                                    theme: 'basic',
                                                    minimumResultsForSearch: -1,
                                                    width: '100%',
                                                }"/>
                                        </td>
                                        <td data-title="Комментарий" class="inner-text-block inner-text-block_top">
                                            <div class="text_block comment-block"
                                                 :class="{'have_comment': product.comment !== null && product.comment !== ''}">
                                                <div class="comment-text">
                                                    <label class="comment-text__label" :for="'desc'+product.id">Комментарий</label>
                                                    <textarea
                                                        class="comment-text-inner"
                                                        @focusin="hideUpdBtn()"
                                                        @focusout="updateComment(product); showUpdBtn()"
                                                        v-model="product.comment"
                                                        :id="'desc'+product.id"
                                                    >{{ product.comment }}</textarea>
                                                </div>
                                                <span class="delete-comment" @click="removeComment(product)"><span
                                                    class="fm-close"></span></span>
                                            </div>
                                        </td>
                                        <td class="checkbox-td">
                                            <div class="input">
                                                <div class="input__inner">
                                                    <input class="input__checkbox" :class="'check-children-'+item.products[0].id" @change="addToHideChildren(product.id,item.products[0].id)" :id="'prdct-'+product.id" type="checkbox">
                                                    <label class="input__checkbox-label" :for="'prdct-'+product.id"></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button class="product-more-btn fm-chevron-right" type="button"
                                                    aria-label="Больше" @click="costEdit(product.id)"></button>
                                        </td>
                                    </tr>
                                </template>
                            </template>


                            </tbody>
                        </table>

                        <div class="table-last-section" v-if="data.meta.links.length > 3">
                            <Pagination
                                :current-page="data.meta.current_page"
                                :total-pages="data.meta.last_page"
                                :next="data.links.next"
                                :previous="data.links.prev"
                                :links="data.meta.links"
                                @page-selected="pageSelected"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!--        <div-->
        <!--            class="buttons-row fixed_bottom_row right_position"-->
        <!--            v-show="$page.props.auth.user.user_account.account.products_updating"-->
        <!--            :class="{processing: $page.props.auth.user.user_account.account.products_updating}"-->
        <!--            data-btn_row-->
        <!--        >-->
        <!--            <div class="badge badge-info whitespace-nowrap p-2">Обновляется <span class="ring_loader"></span></div>-->
        <!--&lt;!&ndash;            <button class="btn-default btn-regular loading_button" @click="sync">Обновить <span&ndash;&gt;-->
        <!--&lt;!&ndash;                class="ring_loader"></span></button>&ndash;&gt;-->
        <!--        </div>-->


        <div class="modal modal-fullscreen modal-import-errors" id="importErrors" tabindex="-1" aria-labelledby="importErrorsTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title" id="importErrorsTitle">Ошибки загрузки</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="info-list">
                            <div class="modal-import-errors__header">
                                <div class="modal-import-errors__column">
                                    <div class="modal-import-errors__label">Всего<br>строк</div>
                                    <div class="modal-import-errors__value">{{countRows}}</div>
                                </div>
                                <div class="modal-import-errors__column">
                                    <div class="modal-import-errors__label">Успешно загружено</div>
                                    <div class="modal-import-errors__value">{{ countSuccess }}</div>
                                </div>
                                <div class="modal-import-errors__column">
                                    <div class="modal-import-errors__label">Строки<br>с ошибками</div>
                                    <div class="modal-import-errors__value">{{countErrors}}</div>
                                </div>
                            </div>
                            <div class="modal-import-errors__title" v-if="errorsList.length > 0">
                                В файле были выявлены<br>следующие типы ошибок:
                            </div>

                            <ul class="info-list__accordion">
                                <li class="info-list__accordion__group" v-for="(errors, type) in errorsList" :class="'error_type_'+type">
                                    <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                                        <button class="info-list__accordion__toggle-btn" type="button" aria-label="toggle" @click="toggleDetails('error_type_'+type)"></button>
                                        <div class="info-list__accordion__inner">
                                            <span class="info-list__accordion__title" :id="type">{{ errorsNames[type] }}
                                                <div class="block_item_head__tooltip" v-if="type == 4" v-tippy="__(`hints.importErrors.invalidDateError`)">
                                                    <span class="fm-alert-triangle"></span>
                                                </div>
                                            </span>
                                            <span class="info-list__accordion__value">{{errors.length}}</span>
                                        </div>
                                    </div>
                                    <div class="info-list__accordion__item">
                                        <div class="info-list__accordion__inner">
                                            <div class="modal-import-errors__list">
                                                <div class="modal-import-errors__inner">
                                                    <div class="modal-import-errors__item" v-for="one_error in errors">
                                                        <div class="modal-import-errors__item__title">{{one_error.title}}</div>
                                                        <div class="modal-import-errors__item__value">
                                                            <span>{{ one_error.articul }}</span>
                                                            <span v-if="one_error.barcode"> / {{one_error.barcode}}</span>
                                                            <span> / Цена: {{one_error.price ?? 0}}</span>
                                                            <span v-if="one_error.periodFrom && (one_error.periodFrom !== '31.12.3000' && one_error.periodFrom !== '01.01.1970')"> / {{ one_error.periodFrom }}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

<!--                                <li class="info-list__accordion__group" :class="{ active: duplicateByPeriod }">
                                    <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                                        <button class="info-list__accordion__toggle-btn" type="button" aria-label="toggle" @click="toggleDetails('duplicateByPeriod')"></button>
                                        <div class="info-list__accordion__inner">
                                            <span class="info-list__accordion__title">Дубликат по периоду</span>
                                            <span class="info-list__accordion__value">2</span>
                                        </div>
                                    </div>
                                    <div class="info-list__accordion__item">
                                        <div class="info-list__accordion__inner">
                                            <div class="modal-import-errors__list">
                                                <div class="modal-import-errors__inner">
                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>

                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="info-list__accordion__group" :class="{ active: alreadyExist }">
                                    <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                                        <button class="info-list__accordion__toggle-btn" type="button" aria-label="toggle" @click="toggleDetails('alreadyExist')"></button>
                                        <div class="info-list__accordion__inner">
                                            <span class="info-list__accordion__title">Такой товар уже существует</span>
                                            <span class="info-list__accordion__value">14</span>
                                        </div>
                                    </div>
                                    <div class="info-list__accordion__item">
                                        <div class="info-list__accordion__inner">
                                            <div class="modal-import-errors__list">
                                                <div class="modal-import-errors__inner">
                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>

                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>

                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>
                                                </div>
                                                <div class="modal-import-errors__show-more">
                                                    <button class="modal-import-errors__show-more_btn" type="button">Показать ещё</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="info-list__accordion__group" :class="{ active: wrongDateFormat }">
                                    <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                                        <button class="info-list__accordion__toggle-btn" type="button" aria-label="toggle" @click="toggleDetails('wrongDateFormat')"></button>
                                        <div class="info-list__accordion__inner">
                                            <span class="info-list__accordion__title">Неправильный формат даты</span>
                                            <span class="info-list__accordion__value">12</span>
                                        </div>
                                    </div>
                                    <div class="info-list__accordion__item">
                                        <div class="info-list__accordion__inner">
                                            <div class="modal-import-errors__list">
                                                <div class="modal-import-errors__inner">
                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>

                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>

                                                    <div class="modal-import-errors__item">
                                                        <div class="modal-import-errors__item__title">Аллета&nbsp;/ Зарядка для iphone type-c 25W</div>
                                                        <div class="modal-import-errors__item__value">2,04E+12&nbsp;/ 159824065&nbsp;/ Цена:&nbsp;1111&nbsp;/ 12.03.2023</div>
                                                    </div>
                                                </div>
                                                <div class="modal-import-errors__show-more">
                                                    <button class="modal-import-errors__show-more_btn" type="button">Показать ещё</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>-->
                            </ul>

                            <div class="modal-import-errors__actions">
                                <button class="btn-default btn-regular" data-dismiss="modal">Понятно</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-fullscreen" id="importSuccess" tabindex="-1" aria-labelledby="importSuccessTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title" id="importSuccessTitle">Загрузка файла</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Файл успешно загружен
                    </div>
                    <div class="modal-footer modal-footer_center">
                        <button class="btn-default btn-regular" data-dismiss="modal">Понятно</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-fullscreen logout-confirm" id="modalDeleteItem" tabindex="-1"
             aria-labelledby="modal_notificationDelete_label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title">Подтверждение</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h6 class="logout-confirm__title text-center">Вы уверены?</h6>
                    </div>
                    <div class="modal-footer">
                        <button data-dismiss="modal" aria-label="Close" class="btn-default">Отменить</button>
                        <button aria-label="Close" @click="confirmDelete" class="btn-default btn-regular">Удалить
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="confirmCostProduct" class="modal modal-fullscreen modal-confirm-cost" id="confirmCostModal"
             aria-labelledby="costprice-modal-label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="modal-title" id="costprice-modal-label">Изменение себестоимости</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <input type="hidden" v-model="confirmCostForm.id">
                    <input type="hidden" v-model="confirmCostForm.cost_type">
                    <input type="hidden" v-model="confirmCostForm.cost">
                    <div class="modal-body scrollbar">
                        <div class="tree-barcodes">
                            <h6 class="tree-barcodes__title">Использовать для всех баркодов этого артикула?</h6>
                            <div class="product-summary__details">
                                <div class="product-summary__header">
                                    <img class="product-summary__img" :src="confirmCostProduct.image" width="40"
                                         height="40" :alt="confirmCostProduct.title">
                                    <div class="product-summary__header__inner">
                                        <div class="product-summary__product-title">{{ confirmCostProduct.title }}</div>
                                        <div>{{ confirmCostProduct.nm_id }}</div>
                                    </div>
                                </div>
                                <div class="tree-barcodes__container">
                                    <div v-for="(item) in confirmCostProducts" class="product-summary__variation"><span
                                        class="product-summary__variation__title">{{ item.barcode }}</span> {{
                                            (item.tech_size != '' && item.tech_size != 0 && item.tech_size != 'null') ? ', Размер: ' + item.tech_size : ''
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn-default" @click="confirmCostUpdate()">Только к текущему
                        </button>
                        <button type="button" class="btn-default btn-regular" @click="confirmCostUpdate(true)">Применить
                            ко всем
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-action-panel" v-show="productsToHide.length > 0">
            <button @click="hideProducts" class="index_filter_button active">Сохранить</button>
        </div>

        <transition name="fade">
            <div id="notificationMessage" v-if="notification.show" :class="notification.type" class="notification-message is-display-block">
                <span>{{ notification.message }}</span>
            </div>
        </transition>

        <ProductsCostModal ref="ProductsCostModal" v-bind:reloadOnHideModal="reloadOnHideModal"
                           @reload="reloadTable"></ProductsCostModal>
        <ProductsFilterModal ref="productsFilterModal"
                             v-bind:query="query"
                             v-bind:productsSelected="productsSelected"
                             @apply="filterClicked"
                             @showHidden="showHiddenMobile"
                             @showCostEmpty="showEmptyCostMobile"
                             @selectedProductsChanged="selectedProductsChanged"/>

    </BreezeAuthenticatedLayout>


    <Teleport to="#app_child">
        <div class="buttons-panel">
            <div class="buttons-panel__inner">
                <a :href="exportLink" class="btn-default btn-regular position-relative" :class="{disabled: export_inproccess }" @click.prevent="downloadStart(exportLink)">
                    Скачать таблицу (.xls)
                    <span class="btn-loader" v-show="export_inproccess">
                        <span class="btn-loader__inner">
                            <span class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </span>
                        </span>
                    </span>
                </a>

                <div class="upload-table-btn" v-if="isAvailable('standard') || isAvailable('business')">
                    <label class="btn-default btn-regular cursor-pointer" :class="{disabled: import_inproccess}" >
                        <input type="file" name="file" id="importfile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadFile">
                        Загрузить таблицу (.xls)
                        <span class="btn-loader" v-show="import_inproccess">
                            <span class="btn-loader__inner">
                                <span class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </Teleport>


</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import BreezeDashboardInfo from '@/Components/Dashboard/DashboardInfo.vue'
import ProductsCostModal from '@/Components/Products/ProductsCostModal.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import Pagination from '@/Components/Common/Pagination';
import {Inertia} from "@inertiajs/inertia";
import Inputmask from "inputmask";
import ProductsSelector from "@/Components/Products/ProductsSelector";
import Select2 from 'vue3-select2-component';
import ProductsFilterModal from '@/Components/Products/ProductsFilterModal.vue'
import Currency from '@/Components/Common/Currency.vue'
import ProductTitle from '@/Components/Products/Title.vue'
import { store } from '@/store'

export default {
    components: {
        ProductTitle,
        Currency,
        BreezeAuthenticatedLayout,
        BreezeDashboardInfo,
        ProductsCostModal,
        Head,
        Link,
        Pagination,
        Inputmask,
        ProductsSelector,
        Select2,
        ProductsFilterModal
    },
    props: [
        'data',
        'orderBy',
        'desc',
        'filterProducts',
        'costTypes'
    ],
    data() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        const query = {
            products: params.get('products') ?? [],
            orderBy: params.get('orderBy') ?? 'title',
            desc: params.get('desc') ?? 1,
            hidden: params.get('hidden') ?? 0,
            costempty: params.get('costempty') ?? 0,
        }

        const queryCache = Object.assign({}, query);

        return {
            confirmCostForm: this.$inertia.form({
                "id": null,
                "cost": null,
                "cost_type": 0,
                "apply_all": false
            }),
            isCostClose: true,
            confirmCostProduct: null,
            errorsList:{},
            errorsNames:{},
            countSuccess:0,
            countErrors:0,
            countRows:0,
            confirmCostProducts: {},
            products: {},
            productsValid: [],
            productsToHide: [],
            delete_id: null,
            delete_is_nm_id: false,
            isSyncing: false,
            export_inproccess: false,
            import_inproccess: false,
            query: query,
            queryCache: queryCache,
            queryModified: false,
            exportLink:'',
            sort: {
                orderBy: 'title',
                desc: 1
            },
            notification: {
                show: false,
                message: 'Успешно скопировано',
                type:'info',
            },
            costOld: null,
            productsSelected: [],
            pendingRequest: false,
            cancelTokenSource: null,
            reloadOnHideModal: true,
            store
        };
    },
    mounted() {
        this.isSyncing = this.$page.props.auth.user.user_account.account.products_updating;
        this.products = this.data.data;
        this.populateValidProducts();

        this.exportLink = this.route('product.export');

        this.productsSelected = this.filterProducts;

        if (this.orderBy)
            this.sort.orderBy = this.orderBy;
        if (typeof this.desc === 'number')
            this.sort.desc = this.desc;

        this.jsInit();
    },
    watch: {
        /*sort: {
            deep: true,
            handler: throttle(function () {
                let self = this;
                this.$inertia.get('/products', {
                    orderBy: self.sort.orderBy,
                    desc: self.sort.desc ? 1 : 0,
                    page: self.data.currentPage
                }, {
                    replace: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.products = this.data.data;
                    }
                })
            }, 150),
        },*/
    },
    methods: {
        isAvailable(packageType) {
            return this.store.subscription.trial || store.subscription.type === packageType;
        },
        applyPricePlugin(){
            setTimeout(()=>{
            let amountElements = document.querySelectorAll('[data-price-parent]');
            amountElements.forEach((el) => {
                Inputmask({
                    regex: "^[0-9.,]+$",
                    placeholder:'',
                    showMaskOnHover:false,
                }).mask(el);
            })
            }, 200);
        },
        hideZeroCost: function(event){
            if(event.target.value == 0) {
                event.target.value = '';
            }
        },
        updateCostInline(product, isSingle, event) {
            let cost = Number($(event.target).val().replace(',', '.'));
            if (isNaN(cost)) {
                $(event.target).val(product.cost)
                this.showNotification('Неправильный формат данных. Информация не сохранена.','error');
                return false;
            }

            let productValid = this.getValidProductById(product.id)

            if (Number(productValid.cost) === Number(product.cost)){
                return false;
            }

            this.confirmCostForm.id = product.id;
            this.confirmCostForm.title = product.title;
            this.confirmCostForm.cost = cost;
            this.confirmCostForm.apply_all = product.cost_apply_all;
            this.confirmCostForm.cost_type = 0;
            if (isSingle)
                this.confirmCostUpdate(true);
            else
                this.confirmCostUpdateModal();
        },
        costTypeChange(product, isSingle, event) {
            let costTypeOld = Number(product.cost_type)
            let costTypeNew = Number(event.id);
            if (costTypeOld === 0 && costTypeNew === 1) {
                this.confirmCostProduct = {id: product.id};
                this.costEdit(product.id, 1)
                // setTimeout(function(){
                //     product.cost_type_select2 = String(costTypeOld);
                // }, 700)


            } else if (costTypeOld === 1 && costTypeNew === 0) {
                this.confirmCostForm.id = product.id;
                this.confirmCostForm.title = product.title;
                this.confirmCostForm.cost = product.cost.replace(',','.');
                this.confirmCostForm.apply_all = product.cost_apply_all;
                this.confirmCostForm.cost_type = 0;

                // setTimeout(function(){
                //     product.cost_type_select2 = String(costTypeOld);
                // }, 200)
                if (isSingle)
                    this.confirmCostUpdate(true);
                else
                    this.confirmCostUpdateModal();

            }

        },
        confirmCostUpdateModal() {
            let self = this;
            axios.get(this.route('product.info'), {
                params: {
                    id: this.confirmCostForm.id
                }
            })
                .then((response) => {
                    this.confirmCostProducts = response.data.products;
                    this.confirmCostProduct = response.data.product;

                    setTimeout(function () {
                        $('#confirmCostModal').modal();
                        self.tippyTooltips();
                    }, 200)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        self.showNotification('Обновите страницу и авторизуйтесь');
                        return false;
                    }

                    self.showNotification('Произошла ошибка, попробуйте снова.','error');

                });
        },
        confirmCostUpdate(applyAll = false) {
            let self = this;

            let data = this.confirmCostForm.data();

            if (applyAll)
                data['apply_all'] = true;
            else
                data['apply_all'] = false;

            axios.post(this.route('product.setCost'), data)
                .then((response) => {
                    self.reloadOnHideModal = false;
                    self.isCostClose = false;
                    self.validProductUpdate(this.confirmCostForm.id, 'cost', this.confirmCostForm.cost);
                    $('#confirmCostModal').modal('hide');
                    self.confirmCostForm.reset();
                    self.reloadTable();
                    self.showNotification('Данные сохранены');

                    //self.reloadOnHideModal = true;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        self.showNotification('Обновите страницу и авторизуйтесь','error');
                        return false;
                    }
                    self.showNotification('Неправильный формат данных. Информация не сохранена.','error');
                });
        },
        reloadTable() {
            this.$inertia.get('/products', {
                orderBy: this.sort.orderBy,
                desc: this.sort.desc ? 1 : 0,
                page: this.data.currentPage,
                hidden: this.query.hidden,
                costempty: this.query.costempty,
                products: this.query.products
            }, {
                replace: true,
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.products = this.data.data;
                    this.populateValidProducts();
                }
            })
        },
        populateValidProducts() {
            this.products.forEach((productList) => {
                productList.products.forEach((product) => {
                    this.productsValid.push(JSON.parse(JSON.stringify(product)));
                })
            });
        },
        selectedProductsChanged(data) {
            this.queryCache.products = data.productIds;
            if (typeof data.checkQuery !== 'undefined' && data.checkQuery === false)
                return false;
            this.checkQueryStatus()
        },
        checkQueryStatus() {
            if (Array.isArray(this.queryCache.products) && this.queryCache.products.join() === this.query.products)
                this.queryModified = false;
            else
                this.queryModified = !this.arrayEquals(this.query.products, this.queryCache.products);
        },
        filterClicked() {
            let productsQuery = '';
            let productsImploded = '';
            if (this.queryCache.products) {
                productsImploded = ((Array.isArray(this.queryCache.products)) ? this.queryCache.products.join(',') : this.queryCache.products);
            }
            let query = Object.assign({}, this.query);
            if (this.queryCache.products) {
                query.products = ((Array.isArray(this.queryCache.products)) ? this.queryCache.products.join(',') : this.queryCache.products);
            }

            Inertia.visit('/products?hidden='+query.hidden +'&costempty='+query.costempty + ((productsImploded !== '') ? '&products=' + productsImploded : ''))
        },
        arrayEquals(a, b) {
            if (Array.isArray(b) && !b.length && !a)
                return true;

            return a === b || (Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val == b[index]));
        },
        getValidProductById: function (id) {
            let foundProduct = null
            this.productsValid.forEach((product, index) => {
                if (product.id == id) {
                    foundProduct = product;
                }
            });
            return foundProduct;
        },
        validProductUpdate: function (id, attribute, value) {
            this.productsValid.forEach((product, index) => {
                if (product.id == id) {
                    product[attribute] = value;
                }
            });
        },
        productUpdate: function (id, attribute, value) {
            this.products.forEach((productList, index) => {
                productList.products.forEach((product, idx) => {
                    if (product.id === id) {
                        this.products[index].products[idx][attribute] = value;
                        product[attribute] = value;
                    }
                })

            });
        },
        confirmDelete: function () {
            if (!this.delete_id)
                return false;
            let self = this;
            axios.post(this.route('product.setComment'), {
                productId: this.delete_id,
                comment: null,
                isNmId: this.delete_is_nm_id
            })
                .then(function (response) {
                    $('#modalDeleteItem').modal('hide');
                    self.productUpdate(self.delete_id, (self.delete_is_nm_id) ? 'nm_comment' : 'comment', null);

                })
                .catch(function (error) {
                    self.showNotification(error.response.data.message,'error');
                });
        },
        removeComment(model, isNmId = false) {
            this.delete_id = model.id;
            this.delete_is_nm_id = isNmId;
            $('#modalDeleteItem').modal('show');
        },
        sync: function () {
            return false;

            if (!this.$page.props.auth.data.status) {
                this.showNotification(this.__('user.api_key_invalid_short'),'error');
                return false;
            }

            if (this.$page.props.auth.user.user_account.account.products_updating) {
                this.showNotification('Обновляется, пожалуйста подождите','error');
                return false;
            }

            let self = this;
            this.isSyncing = true;
            this.$page.props.auth.user.user_account.account.products_updating = 1;

            axios.get('/products/sync', {})
                .then(() => {
                    self.$page.props.auth.user.user_account.account.products_updating = 1;
                    self.isSyncing = false;
                    self.$inertia.get('/products', {}, {
                        replace: false,
                        preserveState: true,
                        onSuccess: (response) => {
                            this.products = response.props.data.data;
                            this.isSyncing = false;
                        }
                    })
                })
                .catch((error) => {
                    self.showNotification(error.response.data.message);
                    self.$page.props.auth.user.user_account.account.products_updating = 0;
                });
        },
        showNotification: function (message,type = 'info') {
            let self = this;
            this.notification.show = true;
            this.notification.message = message;
            this.notification.type = type;
            setTimeout(function () {
                self.notification.show = false;
            }, 3000);
        },
        copy: function (text) {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            this.showNotification('Успешно скопировано');

        },
        sortByColumn: function (column) {
            let self = this;
            this.sort = {
                orderBy: column,
                desc: (this.sort.desc === 1) ? 0 : 1
            };

            Inertia.visit('/products?orderBy=' + this.sort.orderBy + '&desc=' + (this.sort.desc ? 1 : 0) + '&page=' + this.data.currentPage + '&products=' + this.query.products);

            // this.$inertia.get('/products', {
            //     orderBy: this.sort.orderBy,
            //     desc: this.sort.desc ? 1 : 0,
            //     page: this.data.currentPage
            // }, {
            //     replace: true,
            //     preserveState: true,
            //     onSuccess: () => {
            //         this.products = this.data.data;
            //     }
            // })

        },
        hasErrors: function (product, column) {
            if (typeof product.errors !== 'undefined') {
                if (typeof product.errors[column] !== 'undefined')
                    return true;
            }
            return false;
        },
        saveCostOld: function (product) {

        },
        updateComment: function (product, isNmId = false) {
            let self = this;
            let comment = (isNmId) ? product.nm_comment : product.comment;

            product.errors = {};
            axios.post(this.route('product.setComment'), {productId: product.id, comment: comment, isNmId: isNmId})
                .then(function (response) {
                    //product.comment = comment;
                })
                .catch(function (error) {
                    //console.log(error.response.data)
                    product.errors.comment = error.response.data.message;
                    self.showNotification(error.response.data.message,'error');
                });
        },
        hideUpdBtn: function () {
            $('[data-btn_row]').addClass('buttons-row_hidden');
        },
        showUpdBtn: function () {
            $('[data-btn_row]').removeClass('buttons-row_hidden');
        },
        costEdit: function (id, forceCostType = null, forceApplyAll = false) {
            this.$refs.ProductsCostModal.costEdit(id, forceCostType, forceApplyAll);
        },

        tippyTooltips() {
            if (!$('[data-products-info]').length)
                return false;
            let tippy_content = $('[data-products-info_content]').html();
            if (typeof $('[data-products-info]').get(0)._tippy !== 'undefined')
                $('[data-products-info]').get(0)._tippy.setContent(tippy_content);
            else
                tippy('[data-products-info]', {
                    content: tippy_content,
                    allowHTML: true,
                    arrow: true,
                    zIndex: 9999
                });
        },
        exportStart() {
            axios.post(this.route('product.export'), {})
                .then(function (response) {

                    console.log(response)

                })
                .catch(function (error) {

                });
        },
        downloadStart(url){
            if (this.export_inproccess)
                return false;
            let self = this;
            this.export_inproccess = true;
            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'products-export.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    self.export_inproccess = false;
                }).catch(console.error)
        },
        uploadFile(e) {
            this.import_inproccess = true;
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            const headers = {'Content-Type': 'multipart/form-data'};
            axios.post(this.route('product.import'), formData, {headers}).then((res) => {
                //this.showNotification(res.data.msg);
                this.reloadTable();
                this.import_inproccess = false;
                document.getElementById('importfile').value = "";
                if(res.data.status == true){
                    $('#importSuccess').modal();
                } else {
                    if (res.data.msg) {
                        this.showNotification(res.data.msg,'error');
                    } else {
                        this.errorsList = res.data.errors;
                        this.errorsNames = res.data.errors_names;
                        this.countSuccess = res.data.countSuccess;
                        this.countRows = res.data.countRows;
                        this.countErrors = res.data.countErrors;
                        $('#importErrors').modal();
                    }
                }


                  // const link = document.createElement("a");
                  // link.href ='/storage/'+res.data.error;
                  // link.download = 'error.xlsx';
                  // link.click();

            }).catch((error) =>{
                this.showNotification(error,'error');
                this.import_inproccess = false;
            });
        },
        addToHide($id){
            let index = this.productsToHide.indexOf($id);
            if (index !== -1){
                this.productsToHide.splice(index, 1);
            }
            else {
                this.productsToHide.push($id);
            }
        },
        checkAllChildren(products,el){
           if($('#'+el).is(':checked')){
               products.forEach((e)=>{
                   $('#prdct-'+e.id).prop('checked',true);
                   this.productsToHide.push(e.id);
               })
           }
           else {
               products.forEach((e)=>{
                   $('#prdct-'+e.id).prop('checked',false);
                   let index = this.productsToHide.indexOf(e.id);
                   if (index !== -1) {
                       this.productsToHide.splice(index, 1);
                   }
               })
           }
        },
        addToHideChildren($id,$parentId){
            let index = this.productsToHide.indexOf($id);
            if (index !== -1) {
                this.productsToHide.splice(index, 1);
                $('#prod-parent-'+$parentId).prop('checked',false);
            }
            else {
                if($('.check-children-'+$parentId+':checked').length == $('.check-children-'+$parentId).length){
                    $('#prod-parent-'+$parentId).prop('checked',true);
                }
                this.productsToHide.push($id);
            }
        },
        hideProducts(){
            let link = '/products/hide';
            if(this.query.hidden == 1){
                link = '/products/unhide'
            }
            axios.post(link, {'products':this.productsToHide})
                .then((res) => {
                    this.reloadTable();
                    this.showNotification(res.data.msg);
                    $('#productTable .input__checkbox').prop('checked',false);
                    this.productsToHide = [];
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        pageSelected(page) {
            const currentUrl = (new URL(location.href));
            currentUrl.searchParams.set('page', page);

            Inertia.visit(currentUrl.toString());
        },
        showHidden() {
            this.productsToHide = [];
            $('.products-table input:checked').prop('checked',false);
            if(this.query.hidden == 0){
                this.query.costempty = 0;
                this.query.hidden = 1;
            }
            else {
                this.query.hidden = 0;
            }

            this.reloadTable();
        },
        showEmptyCost() {
            this.productsToHide = [];
            $('.products-table input:checked').prop('checked',false);
            if(this.query.costempty == 0){
                this.query.costempty = 1;
                this.query.hidden = 0;
            }
            else {
                this.query.costempty = 0;
            }

            this.reloadTable();
        },
        showEmptyCostMobile() {
            if(this.query.costempty == 0){
                this.query.costempty = 1;
                this.query.hidden = 0;
            }
            else {
                this.query.costempty = 0;
            }
        },
        showHiddenMobile() {
            if(this.query.hidden == 0){
                this.query.costempty = 0;
                this.query.hidden = 1;
            }
            else {
                this.query.hidden = 0;
            }
        },

        toggleDetails(item) {
            document.querySelector('.'+item).classList.toggle('active');
        },

        jsInit() {
            let self = this;

            if (typeof window.isProductsJsMounted == 'undefined') {

                let clickHandler = 'click';

                if ('ontouchstart' in document.documentElement) {
                    clickHandler = 'touchend';
                }

                $(document).on(clickHandler, '[data-toggle-products-row]', function (e) {
                    e.preventDefault();
                    let $element = $(this);
                    let id = $(this).data('product');
                    if ($element.attr('aria-selected') == 'false') {
                        $('[data-product_id="' + id + '"]').removeClass('hidden');
                        $element.attr('aria-selected', 'true');
                        $element.closest('[data-toggle-products]').addClass('active');
                    } else {
                        $('[data-product_id="' + id + '"]').addClass('hidden');
                        $element.attr('aria-selected', 'false');
                        $element.closest('[data-toggle-products]').removeClass('active');
                    }
                })

                console.log('ProductsJsMounted');

                window.isProductsJsMounted = true;
            }
            $(document).off('hidden.bs.modal', '#costPrice');
            $(document).on('hidden.bs.modal', '#costPrice', function (e) {
                if (self.isCostClose && self.confirmCostProduct) {
                    let product = self.getValidProductById(self.confirmCostProduct.id);
                    self.productUpdate(product.id, 'cost_type_select2', product.cost_type_select2);
                }
                self.isCostClose = true;
            })
            $(document).off('hidden.bs.modal', '#confirmCostModal');
            $(document).on('hidden.bs.modal', '#confirmCostModal', function (e) {
                if (self.isCostClose && self.confirmCostProduct) {
                    let product = self.getValidProductById(self.confirmCostProduct.id);
                    self.productUpdate(product.id, 'cost', product.cost);
                }
                self.isCostClose = true;
            })

            this.applyPricePlugin();
        },
    }
}
</script>
<style>
input:focus::placeholder {
    color: transparent;
}
</style>
