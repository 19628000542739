<template>
    <th
        :style="style"
        :class="{'sortable-header': true, 'reorder_outer': filter.orderBy === sortKey}"
        @click="$emit('sort-data', {'column': sortKey})"
        v-tippy="tooltip ? {content: tooltip} : null"
    >
        {{ name }}
        <button
            v-show="filter.orderBy === sortKey"
            :class="{'reorder_button': true, 'active': filter.desc === 0}"
        />
    </th>
</template>

<script>
export default {
    name: 'SortableTh',
    props: {
        'style': Object,
        'sortKey': String,
        'name': String,
        'filter': Object,
        'tooltip': String,
    },
    emits: ['sort-data']
}
</script>
