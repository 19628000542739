<template>
    <h2 class="sep-title">Прочие настройки</h2>

    <div class="settings__block">
        <h3 class="settings__block__title">
            Налог на доход
            <i class="settings__block__title__info fm-icon-info"
               data-tippy-content="Сумма налога расчитывается согласно выбранных вами процента и принципа начисления и будет учтена при расчете чистой прибыли в каждом установленном периоде."></i>
        </h3>
        <div class="input">
            <div class="input__inner">
                <input id="s-1" v-model="taxEnabledForm.tax_enabled" class="input__checkbox"
                       type="checkbox"
                       @change="saveTaxEnabled">
                <label class="input__checkbox-label" for="s-1">Активировать</label>
            </div>
        </div>

        <div v-show="taxEnabledForm.tax_enabled" :class="{active: taxEnabledForm.tax_enabled}"
             class="settings__tax">
            <div class="settings__taxGrid">

                <div v-for="(tax,index) in taxes" class="settings__taxBlock">
                    <button v-if="taxes.length > 1" aria-label="Удалить"
                            class="settings__taxBlock__removeBtn"
                            type="button"
                            @click="deleteTax(tax)">
                        <span class="fm-x"></span>
                    </button>
                    <div class="input input_price">
                        <label class="input__label" for="s-2">Процент налога</label>
                        <div class="input__inner">
                            <span class="input__currency">%</span>
                            <input id="s-2" :class="{invalid: $page.props.errors.percent}"
                                   :disabled="!taxEnabledForm.tax_enabled"
                                   class="input__text-input tax-mask"
                                   max="100"
                                   min="0"
                                   placeholder="0"
                                   type="text"
                                   v-bind:value="tax.percent"
                                   @focusin="hideZeroCost(tax.percent,$event)"
                                   v-on:input="tax.percent = $event.target.value"
                            >
                        </div>
                    </div>

                    <div class="input input_date">
                        <label :for="'taxDateFrom_'+index" class="input__label">Применить с</label>
                        <div class="input__inner">
                            <input :id="'taxDateFrom_'+index" v-model="tax.start_date"
                                   :class="{error: $page.props.errors.start_date}"
                                   class="input__text-input" data-taxDateFrom placeholder="Дата"
                                   readonly>
                            <label :for="'taxDateFrom_'+index" class="input__ico"><span
                                class="fm-bx-calendar"></span></label>
                        </div>
                    </div>

                    <div class="input input_select">
                        <label class="input__label" for="principle">Принцип начисления</label>
                        <Select2
                            :id="'principle'+index"
                            v-model="tax.type"
                            :options="taxOptions"
                            :settings="{theme: 'sd_select', minimumResultsForSearch: -1, prompt: 'Реализация товара', placeholder: 'Выберите принцип начисления', allowClear: false, width: '100%',}"
                        />
                    </div>
                </div>

            </div>

            <div class="settings__tax__actions">
                <div class="settings__tax__actions__inner">
                    <button class="btn-default" type="button" @click="addNewTax">
                        Добавить
                    </button>

                    <button :class="{'disabled': taxFormLoading}" :disabled="taxFormLoading"
                            class="btn-default btn-regular"
                            style="position:relative;" type="button"
                            @click="saveTax">
                        Сохранить
                        <span v-show="taxFormLoading" class="btn-loader">
                                                <span class="btn-loader__inner">
                                                    <span class="spinner-border" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </span>
                                                </span>
                                            </span>
                    </button>
                </div>
            </div>
        </div>
    </div>


    <Modal
        ref="settingsDeleteTaxModal"
        v-if="showModal"
        @close="showModal = false"
        modalClasses="modal-fullscreen"
    >
        <template #title>
            <h5 class="modal-title">Подтверждение</h5>
        </template>
        <template #body>
            <h6 class="logout-confirm__title text-center">Вы уверены?</h6>
        </template>
        <template #buttons>
            <button class="btn-default btn-cancel" type="button" @click="closeModal">Отменить</button>
            <button
                class="btn-default btn-regular"
                @click.prevent="confirmDelete(taxToDelete)">
                Удалить
            </button>
        </template>
    </Modal>
</template>

<script>
import Select2 from 'vue3-select2-component'
import Inputmask from 'inputmask'
import Modal from '@/Components/Common/Modal.vue'

export default {
    components: {
        Select2,
        Modal
    },
    data() {
        return {
            taxOptions: [
                {text: 'Сумма продаж', allowed: ['wildberries', 'wildberries_demo', 'ozon', 'ozon_demo'], id: 'sell_amount'},
                {text: 'WB Реализовал', allowed: ['wildberries', 'wildberries_demo'], id: 'realized'},
                {text: 'Сумма выплат', allowed: ['wildberries', 'wildberries_demo', 'ozon', 'ozon_demo'], id: 'payment_amounts'},
                {text: 'Прибыль', allowed: ['wildberries', 'wildberries_demo', 'ozon', 'ozon_demo'], id: 'profit'},
            ],
            taxForm: this.$inertia.form({
                tax_percent: 6,
                tax_type: 'sell_amount',
                dateFrom: moment().startOf('year').format('DD.MM.YYYY')
            }),
            taxEnabledForm: this.$inertia.form({
                tax_enabled: false,
            }),
            taxFormLoading: false,
            taxes: [
                {
                    percent: 6,
                    type: 'sell_amount',
                    start_date: moment().startOf('year').format('DD.MM.YYYY')
                }
            ],
            taxToDelete: null,
            showModal: false
        };
    },
    mounted() {
        this.taxEnabledForm.tax_enabled = !!this.$page.props.auth.user.user_account.account.tax_enabled;
        this.taxForm.tax_percent = this.$page.props.auth.user.user_account.account.tax_percent;
        this.taxForm.tax_type = this.$page.props.auth.user.user_account.account.tax_type;
        this.taxes = this.$page.props.auth.user.user_account.account.owner.taxes;

        this.taxOptions = this.taxOptions.filter((option) => {
            return option.allowed.includes(this.$page.props.auth.user.user_account.account.data_source)}
        );

        this.taxes.forEach((tax) => {
            tax.start_date = moment(tax.start_date).format('DD.MM.YYYY');
        });

        if (this.$page.props.auth.user.user_account.account.tax_date_from)
            this.taxForm.dateFrom = moment(this.$page.props.auth.user.user_account.account.tax_date_from).format('DD.MM.YYYY');

        this.initDatePicker();
        this.maskInputValues();
    },
    methods: {
        hideZeroCost(cost, event) {
            if (cost == 0) {
                event.target.value = '';
            }
        },
        maskInputValues() {
            setTimeout(() => {
                let list = $('.tax-mask');
                Inputmask({
                    regex: "^[0-9][0-9]?$|^100$",
                    placeholder: " "
                }).mask($('.tax-mask'));
            }, 300);
        },
        initDatePicker() {
            setTimeout(() => {
                let self = this;
                let pickerEl = $('[data-taxDateFrom]');
                pickerEl.each((key, el) => {
                    el = $(el);
                    el.val(this.taxes[key].start_date);
                    let picker = el.daterangepicker({
                        singleDatePicker: true,
                        showDropdowns: false,
                        startDate: this.taxes[key].start_date,
                        maxDate: moment().toDate(),
                        autoApply: true,
                        minYear: 1901,
                        autoUpdateInput: false,
                        maxYear: parseInt(moment().format("YYYY"), 10),
                        parentEl: el.parent(),
                        locale: {
                            direction: 'ltr',
                            format: 'DD.MM.YYYY',
                            daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                            firstDay: 1
                        },
                    });

                    el.on('apply.daterangepicker', function (ev, picker) {
                        el.val(picker.startDate.format('DD.MM.YYYY'));
                        this.dispatchEvent(new Event('input'))
                    });
                })
            }, 300);
        },
        saveTaxEnabled() {
            let self = this;
            this.taxes = [
                {
                    percent: 6,
                    type: 'realized',
                    start_date: moment().startOf('year').format('DD.MM.YYYY')
                }
            ],

                this.taxEnabledForm.post(this.route('settings.changeTaxEnabled'), {
                    preserveScroll: true,
                    onSuccess: function (response) {
                        self.showNotification('Данные сохранены');
                    },
                    onError: function (errors) {
                        // Handle errors
                    },
                });
        },
        saveTax() {
            let self = this;
            this.taxFormLoading = true;

            axios.post(this.route('settings.changeTax'), this.taxes)
                .then(() => {
                    self.showNotification('Данные сохранены');
                    self.taxFormLoading = false;
                })
                .catch((errors) => {
                    let message = '';
                    Object.keys(errors).forEach((key) => {
                        message += errors[key];
                    })
                    if (message)
                        self.showNotification(message, 'error');

                    self.taxFormLoading = false;
                });
        },
        showNotification(message, type = 'info') {
            this.$emit('showNotification', message, type);
        },
        addNewTax() {
            this.taxes.push({
                percent: 6,
                type: 'realized',
                start_date: moment().format('DD.MM.YYYY')
            });
            this.initDatePicker();
            this.maskInputValues();
        },
        deleteTax(tax) {
            // $('#modalDeleteItem').modal('show');
            this.showModal = true;
            this.taxToDelete = tax;
        },
        confirmDelete(tax) {
            this.taxes = this.taxes.filter(t => t !== tax);
            // $('#modalDeleteItem').modal('hide');
            this.closeModal();
            this.initDatePicker();
        },

        closeModal () {
            this.$refs.settingsDeleteTaxModal.close();
        },
    },
};
</script>
