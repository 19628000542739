<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="redeemsFilterModal" tabindex="-1" aria-labelledby="redeemsFilterModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>

                    <h5 class="modal-title" id="redeemsFilterModalTitle">Период</h5>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div class="datePicker_outer datePicker_outer_ml-auto">
<!--                        <div id="reportRange" class="datePicker_inner">
                            <span></span>
                        </div>-->
                        <FilterDateRanges v-bind:dateFrom="queryFilter.dateFrom"
                                          v-bind:dateTo="queryFilter.dateTo"
                                          v-bind:datesAreDefault="datesAreDefault"
                                          v-bind:periods="dateRangeSets"
                                          v-bind:current="activeRange"
                                          v-bind:styleType="2"
                                          @changedDates="datesChanged"></FilterDateRanges>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="apply" data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Фильтровать</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilterDateRanges from '@/Components/Common/FilterDateRanges.vue'
export default {
    components: {
        FilterDateRanges
    },
    props: [
        'filter',
        'datesAreDefault'
    ],
    emits: [
        'apply'
    ],
    data() {
        let dateRangeSets = this.__getFilterDateRangeSets();
        return {
            queryFilter: {},
            dateRangeSets: dateRangeSets,
            currentRangeSet: this.filter.activeRange ? dateRangeSets[this.filter.activeRange] : dateRangeSets[0],
        };
    },
    computed:{
        filterClientDataNS() {
            let accountId = (this.$page.props.auth) ? this.$page.props.auth.user.user_account.account_id : 0;
            return `account-${accountId}/redeems/filter`.toUpperCase();
        },
        activeRange(){
            return this.currentRangeSet;
        }
    },
    methods: {
        datesChanged(data){
            this.queryFilter.dateFrom = data.dateFrom;
            this.queryFilter.dateTo = data.dateTo;
            this.queryFilter.activeRange = data.activeRange;
        },
        apply(){
            this.$emit('apply', false,this.queryFilter);
        },
        initDatePickerGlobal: function () {
            let self = this;
            let start = moment(self.queryFilter.dateFrom);
            let end = moment(self.queryFilter.dateTo);

            function cb(start, end) {
                self.queryFilter.dateFrom = start.format('YYYY-MM-DD');
                self.queryFilter.dateTo = end.format('YYYY-MM-DD');
                $('#reportRange span').html(start.format('D MMMM YYYY').toLowerCase() + ' - ' + end.format('D MMMM YYYY').toLowerCase());

                // self.filterSubmit();
            }

            let reportRange = $('#reportRange');

            reportRange.daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Один месяц': [moment().startOf('month'), moment().endOf('month')],
                    'Три месяца': [moment().subtract(2, 'month').startOf('month'), moment().endOf('month')],
                    'Шесть месяцев': [moment().subtract(5, 'month').startOf('month'), moment().endOf('month')],
                    'Текущий год': [moment().startOf('year'), moment().endOf('year')],
                },
                locale: {
                    direction: 'ltr',
                    format: 'YYYY-MM-DD',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    fromLabel: 'с',
                    toLabel: 'по',
                    customRangeLabel: 'Произвольный период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    firstDay: 1
                },
                timePicker: false,
                //autoApply : true
            }, cb);

            reportRange.on('apply.daterangepicker', function(ev, picker) {
                self.queryFilter.dateFrom = picker.startDate.format('YYYY-MM-DD');
                self.queryFilter.dateTo = picker.endDate.format('YYYY-MM-DD');
                $('#reportRange span').html(picker.startDate.format('D MMMM YYYY').toLowerCase() + ' - ' + picker.endDate.format('D MMMM YYYY').toLowerCase());

                //self.apply();
            });

            cb(start, end);

            updateConfig();

            function updateConfig() {
                let options = {};
            }
        },
    },
    created() {
        //this.initDatePickerGlobal();
        const clientFilterData = localStorage.getItem(this.filterClientDataNS);
        this.queryFilter = this.filter;

        if (clientFilterData) {
            const filterData = JSON.parse(clientFilterData);
            if(filterData.activeRange === 'custom'){
                let range = {
                    id: 'custom',
                    title: 'Произвольный период',
                    dateFrom: filterData.dateFrom,
                    dateTo: filterData.dateTo
                }
                this.currentRangeSet = range;
            }
            else if(filterData.activeRange) {
                this.currentRangeSet = this.dateRangeSets[filterData.activeRange - 1];
            }
            else {
                this.currentRangeSet = this.dateRangeSets[0];
            }
        }
    },
}
</script>
