<template>
    <div id="search_outer" class="search_outer desktop_select">
        <label for="selectSearchProduct"><span class="fm-search"></span></label>
        <select name="searchProduct" id="selectSearchProduct" class="searchSelect"
                data-placeholder="Наименование, артикул, баркод" multiple="multiple">
        </select>
    </div>
</template>

<script>

export default {
    data() {
        return {};
    },
    props: [
        'products'
    ],
    mounted() {
        this.initSelect2Dashboard();
    },
    methods: {
        initSelect2Dashboard: function () {
            let self = this;
            let searchSelect = $('#selectSearchProduct');

            if (searchSelect.length) {
                let clickHandler = 'click';
                if ('ontouchstart' in document.documentElement) {
                    clickHandler = 'touchend';
                }

                $.fn.select2.amd.define('select2/selectAllAdapter', [
                    'select2/utils',
                    'select2/dropdown',
                    'select2/dropdown/attachBody'
                ], function (Utils, Dropdown, AttachBody) {

                    function SelectAll() {
                    }

                    SelectAll.prototype.render = function (decorated) {
                        let self = this,
                            $rendered = decorated.call(this),

                            $unselectAll = $(
                                '<button id="unselectAllButton" type="button" style="display:none;">Отменить выбор</button>'
                            ),
                            $btnContainer = $('<div class="choose_buttons">').append($unselectAll);
                        if (!this.$element.prop("multiple")) {
                            // this isn't a multi-select -> don't add the buttons!
                            return $rendered;
                        }
                        $rendered.find('.select2-dropdown').prepend($btnContainer);

                        $unselectAll.on('click', function (e) {
                            let $results = $rendered.find('.select2-results__option[aria-selected=true]');
                            $results.each(function () {
                                self.trigger('unselect', {
                                    data: $(this).data('data')
                                });
                            });
                            $(this).hide();
                            $('.product_counter').remove();
                            self.trigger('close');
                        });
                        return $rendered;
                    };

                    return Utils.Decorate(
                        Utils.Decorate(
                            Dropdown,
                            AttachBody
                        ),
                        SelectAll
                    );

                });

                function formatStateDashboard(opt) {
                    if (!opt.id) {
                        return opt.text.toUpperCase();
                    }

                    let optimage = opt.image;

                    if (!optimage) {
                        return opt.text.toUpperCase();
                    } else {
                        return $(
                            '<span class="opt_image"><img src="' + optimage + '" width="40px" alt="' + opt.text.toUpperCase() + '" /></span><span class="opt_text"> ' + opt.text + '</span>'
                        );
                    }
                }

                let $select2 = searchSelect.select2({
                    ajax: {
                        url: self.route('select2List'),
                        data: function (params) {
                            return {
                                search: params.term,
                                page: params.page || 1
                            };
                        },
                        results: function (data) {
                            return {results: data};
                        }
                    },
                    theme: 'default counter_select',
                    minimumResultsForSearch: -1,
                    dropdownCssClass: 'check-select',
                    closeOnSelect: false,
                    placeholder: "Наименование, артикул, баркод",
                    allowClear: false,
                    templateResult: formatStateDashboard,
                    templateSelection: formatStateDashboard,
                    dropdownAdapter: $.fn.select2.amd.require('select2/selectAllAdapter'),
                    width: 'resolve',
                    "language": {
                        "noResults": function () {
                            return "Совпадений не найдено";
                        }
                    },
                })
                    .on('select2:select', (e) => {
                        $('.select2-results__options').scrollTop($(e.currentTarget).data('scrolltop'));

                        if ($(e.currentTarget).val().length === self.products.total) {
                            this.$emit('changed', []);
                        } else {
                            this.$emit('changed', $(e.currentTarget).val());
                        }
                    });
                $select2.on('select2:unselect', (e) => {
                    let dataSelected = $(e.currentTarget).val();
                    dataSelected = dataSelected.filter(item => item != e.params.data.id)
                    this.$emit('changed', dataSelected);

                    $select2.val(dataSelected).trigger("change");
                    $(e.params.originalEvent.currentTarget).attr('aria-selected', false);
                    $('.select2-results__options').scrollTop($(e.currentTarget).data('scrolltop'));
                });
                $select2.on("change", (e) => {
                    let counter = $(".select2-selection__choice").length;
                    let $results = $('.select2-results__option').length || this.products.total;

                    let selectedInput = $('.select2-selection__rendered li.select2-search input.select2-search__field')[0];

                    if (1==2 && counter <= 1) {
                        $('.product_counter').remove();
                        selectedInput.placeholder = "Наименование, артикул, баркод";
                    } else if (counter >= 1 && counter < self.products.total) {
                        $('.select2-selection__rendered li.select2-selection__choice').hide();
                        selectedInput.placeholder = "Наименование, артикул, баркод";
                        $('.product_counter').remove();
                        $('.counter_select .select2-selection__rendered').after(`<div class="product_counter">Выбрано ${counter} товар(ов)<span class="clear_all"></span></div>`);
                        $('#selectAllButton').show();
                        $('#unselectAllButton').hide();
                    }
                    else if (counter === self.products.total && self.products.total > 0) {
                        $('.counter_select .select2-selection__rendered li:not(.select2-search)').hide();
                        $('.product_counter').remove();
                        selectedInput.placeholder = "Наименование, артикул, баркод";
                        $('.counter_select .select2-selection__rendered').after('<div class="product_counter">Все результаты поиска<span class="clear_all"></span></div>');
                        $('#selectAllButton').hide();
                        $('#unselectAllButton').show();
                    }
                    else if (counter < 1) {
                        $('.product_counter').remove();
                    }
                });

                $select2.data('select2').$dropdown.addClass("check-select");

                $(document).on(clickHandler, '.clear_all', null, function () {
                    $select2.val(null).trigger("change");
                    self.$emit('changed', null);
                });

                $select2.html('');

                this.products.selected.forEach((product) => {
                    $select2.append(new Option(product.title , product.id, true, true));
                    $select2.trigger('change');
                });

                $(document).on(clickHandler, '[data-select_filter_button]', function(){
                    if ($(this).hasClass('active')) {
                        $select2.select2('close');
                    }
                });
            }
        },
    }
}
</script>
