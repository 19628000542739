<template>
    <input
        type="text"
        step="1"
        :class="errorInput ? cssClass + ' error' : cssClass"
        v-model="value"
        @focusin="hideZeroCost(value,$event)"
        @input="onInput"
        @focusout="onFocusOut"
    />
</template>

<script>
import Inputmask from "inputmask";

export default {
    name: 'WarehouseParamInput',
    props: {
        cssClass: {
            type: String,
            default: 'table-input__text-input',
        },
        product: {
            type: Object,
            required: true,
        },
        optionKey: {
            type: String,
            required: true,
        },
        autoSave: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            value: 0,
            errorInput: false,
        }
    },
    emits: ['product-updated', 'notification', 'param-updated'],
    methods: {
        hideZeroCost: function(num,$event) {
            if(num === 0) {
                this.value = '';
            }
            Inputmask({
                regex: "^[0-9.,]+$",
                placeholder: '',
                showMaskOnHover: false,
            }).mask($event.target);
        },
        wasChanged() {
            return this.value !== this.product[this.optionKey] && !this.errorInput;
        },
        /**
         * @param {InputEvent} event
         */
        onInput(event) {
            this.errorInput = false;

            if (!event.target.validity.valid) {
                this.displayError();
            }

            this.$emit('param-updated');
        },
        onFocusOut() {
            if (!this.autoSave) {
                return;
            }

            if (this.errorInput) {
                return;
            }

            if (this.value === '' || this.value === null || this.value === undefined) {
                this.value = 0;
            }

            this.saveValue().then(() => {
                this.$emit('product-updated');
            }).catch(() => {
                //console.log('error');
            });
        },
        saveValue() {
            return new Promise((resolve, reject) => {
                if (!this.wasChanged()) {
                    reject('ok');
                }

                if (this.errorInput) {
                    reject('fail');
                }

                axios.post('/product/warehouse', {
                    id: this.product.id,
                    key: this.optionKey,
                    value: this.value,
                }).then((response) => {
                    if (response.status !== 200) {
                        this.displayError();

                        reject('fail');
                        return;
                    }

                    resolve('ok');
                }).catch(() => {
                    this.displayError();

                    reject('fail');
                });
            });
        },
        displayError() {
            this.$emit('notification', {
                'message': 'Неверный формат данных. Информация не может быть сохранена.',
                'type': 'error'
            });

            this.errorInput = true;
        }
    },
    mounted () {
        if (Array.isArray(this.product) && this.product.length === 1) {
            this.value = this.product[0][this.optionKey];
        }
        else {
            this.value = this.product[this.optionKey];
        }
    },
}
</script>
