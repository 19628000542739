<template>
    <div v-if="hasErrors" class="info_block warning_block">
        <div class="info_block_icon"><span class="fm-alert-circle"></span></div>
        <div class="info_block_content">
            <span class="close_info" @click="close()"><span class="fm-close"></span></span>
            <div class="block_content_text">
                <ul>
                    <li v-html="errors['email']"></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        errors() {
            return this.$page.props.errors
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0
        },
    },
    methods: {
        close: function(){
            this.$page.props.errors = {};
        }
    }
}
</script>
