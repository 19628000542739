<template>
    <div :class="{user_thumb: true, user_thumb_ozon: showDataSource && dataSource === 'ozon', user_thumb_wb: showDataSource && dataSource === 'wildberries'}">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             viewBox="0 0 24 24" fill="none" stroke="currentColor"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-user">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'DataSourceIcon',
    props: {
        dataSource: {
            type: String,
            required: true
        },
        showDataSource: {
            type: Boolean,
            default: true
        }
    },
}
</script>
