<template>
    <div v-if="isShow" class="info-banner-outer">
        <div class="info-banner">
            <div class="icon__block"><span class="fm-icon-info"></span></div>
            <div class="info-banner-text">
                <p>Ваш аккаунт был успешно подключен к sellerdata.<br>
                    Через 5-10 минут Вы увидите первые цифры. Начальное заполнение может занять несколько часов, это требуется только один раз.
                    Пока импорт идет, мы рекомендуем ознакомиться с нашим кратким руководством по использованию sellerdata. </p>
                <span v-on:click="close" class="hide_banner fm-x"></span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: function () {
        return {
            isShow: false,
        };
    },
    methods: {
        close() {
            axios.get(this.route('firstTimeHintShown'), {})
                .then(() => {
                    this.isShow = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        this.isShow = !this.$page.props.auth.data.firstTimeHintShown;
    }
}
</script>
