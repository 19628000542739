<template>
    <div>



        <div class="expenses-wrapper dashboard-wrapper">

            <table id="dashboardTable" class="table table-condensed table-hover table-fw-widget dashboard-table">
                <thead>
                <tr>
                    <th style="width: 15%;min-width: 280px;">Товары</th>
                    <th
                        style="width: 5%; min-width: 70px;"
                        class="sortable-header"
                        data-breakpoints="xs sm md lg">
                        Единицы
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm md lg">
                        Возвраты
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm">
                        Продажи
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm">
                        Доставка
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm md">
                        Комиссия
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm md">
                        Вал. прибыль
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm md lg">
                        Расходы
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs">
                        Прибыль
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm md">
                        Выплаты WB
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm">
                        Маржа
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm md">
                        ROI
                    </th>
                    <th style="width: 5%; min-width: 70px;" class="sortable-header" data-breakpoints="xs sm md">
                        Выкуп. %
                    </th>
                    <th style="width: 5%; max-width: 60px;">Инфо</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-title="Товар">
                            <div class="product_wrapper">
                                <button class="table-accordion__trigger" type="button" aria-label="Раскрыть/скрыть" @click="toggleTableRow(document, $event)" data-order="B0XLFX9JXJ" aria-selected="false"></button>

                                <div class="product_content product_content_grid">
                                    <div class="product_image">
                                        <img class="product-thumbnail" src="https://via.placeholder.com/80x80" width="40" height="40" alt="product">
                                    </div>

                                    <div class="product_content__inner">
                                        <div class="name" title="Гидролат лаванды 200 мл. гидролат спрей для лица тоник для роста волос косметическая вода для кожи">
                                            <span>Гидролат лаванды 200 мл. гидролат спрей для лица тоник для роста волос косметическая вода для кожи</span>
                                        </div>
                                        <div>
                                            <button class="copy_button" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                            <a href="#" target="_blank">B0XLFX9JXJ</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>100</td>
                        <td>100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>

                        <td>100%</td>
                        <td>100%</td>
                        <td>100%</td>

                        <td>
                            Больше
                        </td>
                    </tr>

                    <tr class="table-accordion__content hidden" data-order_id="B0XLFX9JXJ">
                        <td data-title="Товар">
                            <div class="product_wrapper product_wrapper_d-block">
                                <div class="product_content">
                                    <div>
                                        2349591345991234,
                                        <div class="popover_outer price_popover">
                                            <a class="open_price_popover">
                                                <span class="fm-edit"></span>
                                                <span>
                                                    Себест.: ₽ <span></span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div>
                                        Цена: ₽ 8.99, Цвет: черный, размер: M
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>100</td>
                        <td>100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>

                        <td>100%</td>
                        <td>100%</td>
                        <td>100%</td>

                        <td>
                            Больше
                        </td>
                    </tr>
                    <tr class="table-accordion__content hidden" data-order_id="B0XLFX9JXJ">
                        <td data-title="Товар">
                            <div class="product_wrapper product_wrapper_d-block">
                                <div class="product_content">
                                    <div>
                                        2349591345991234,
                                        <div class="popover_outer price_popover">
                                            <a class="open_price_popover">
                                                <span class="fm-edit"></span>
                                                <span>
                                                    Себест.: ₽ <span></span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div>
                                        Цена: ₽ 8.99, Цвет: черный, размер: M
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>100</td>
                        <td>100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>

                        <td>100%</td>
                        <td>100%</td>
                        <td>100%</td>

                        <td>
                            Больше
                        </td>
                    </tr>



                    <tr>
                        <td data-title="Товар">
                            <div class="product_wrapper">
                                <button class="table-accordion__trigger" type="button" aria-label="Раскрыть/скрыть" @click="toggleTableRow(document, $event)" data-order="B0XLFX9JXX" aria-selected="false"></button>
                                <div class="product_content product_content_grid">
                                    <div class="product_image">
                                        <img class="product-thumbnail" src="https://via.placeholder.com/80x80" width="40" height="40" alt="product">
                                    </div>

                                    <div class="product_content__inner">
                                        <div class="name" title="Гидролат лаванды 200 мл. гидролат спрей для лица тоник для роста волос косметическая вода для кожи">
                                            <span>Гидролат лаванды 200 мл. гидролат спрей для лица тоник для роста волос косметическая вода для кожи</span>
                                        </div>
                                        <div>
                                            <button class="copy_button" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                            <a href="#" target="_blank">B0XLFX9JXJ</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>100</td>
                        <td>100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>

                        <td>100%</td>
                        <td>100%</td>
                        <td>100%</td>

                        <td>
                            Больше
                        </td>
                    </tr>

                    <tr class="table-accordion__content hidden" data-order_id="B0XLFX9JXX">
                        <td data-title="Товар">
                            <div class="product_wrapper product_wrapper_d-block">
                                <div class="product_content">
                                    <div>
                                        2349591345991234,
                                        <div class="popover_outer price_popover">
                                            <a class="open_price_popover">
                                                <span class="fm-edit"></span>
                                                <span>
                                                    Себест.: ₽ <span></span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div>
                                        Цена: ₽ 8.99, Цвет: черный, размер: M
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>100</td>
                        <td>100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>
                        <td>₽ 100</td>

                        <td>100%</td>
                        <td>100%</td>
                        <td>100%</td>

                        <td>
                            Больше
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>



export default {
    props: [

    ],
    components: {

    },
    methods: {
        toggleTableRow(document, event) {
            var $element = $(event.target);
            var id = $element.data('order');

            if ($element.attr('aria-selected') == 'false') {
                $('[data-order_id="' + id + '"]').removeClass('hidden');
                $element.attr('aria-selected', 'true');
            } else {
                $('[data-order_id="' + id + '"]').addClass('hidden');
                $element.attr('aria-selected', 'false');
            }
        },


    },
    data() {

    },
    mounted() {

    },

}
</script>
