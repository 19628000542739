<template>
    <nav
        v-if="totalPages > 1"
        class="page_nav"
    >
        <ul class="navigation">
            <li v-for="(link, key) in links">
                <span
                    v-if="(key === 0 || key === links.length-1) && link.url !== null"
                    style="cursor: pointer;"
                    :class="{'prev': key === 0, 'next': key === links.length-1}"
                    @click="$emit('page-selected', getPageFromUrl(link.url))"
                >
                    <span
                        :class="{'fm-chevron-left': key === 0, 'fm-chevron-right': key === links.length-1}"
                    />
                </span>
                <span
                    v-else-if="!link.active && link.url !== null"
                    style="cursor: pointer;"
                    @click="$emit('page-selected', getPageFromUrl(link.url))"
                    v-html="link.label"
                />
                <a
                    v-else-if="(key === 0 || key === links.length-1) && link.url === null"
                    :class="{'prev': key === 0, 'next': key === links.length-1, 'disabled': true}"
                >
                    <span
                        :class="{'fm-chevron-left': key === 0, 'fm-chevron-right': key === links.length-1}"
                    />
                </a>
                <span
                    v-else-if="!link.active && link.url === null"
                >
                    {{ link.label }}
                </span>
                <span
                    v-else
                    class="current"
                >
                    {{ link.label }}
                </span>
            </li>
        </ul>

        <div class="select-pagination">
            <a
                :class="{'select-pagination__btn': true, 'disabled': previous === ''}"
                :href="previous === '' ? '#' : previous"
                aria-label="Предыдущая страница"
                @click.stop.prevent="$emit('page-selected', getPageFromUrl(previous))"
            >
                <span class="fm-chevron-left"></span>
            </a>

            <div class="select-pagination__block">
                <div class="input">
                    <div class="input__inner">
                        <label class="input__select" for="paginationSelect">Страница <span>{{currentPage}}</span> из {{totalPages}}</label>
                        <select class="input__select select-pagination__select" @change="$emit('page-selected', $event.target.value)" id="paginationSelect">
                            <option
                                v-for="(page) in [...Array(totalPages).keys()].map( i => i+1)"
                                :value="page"
                                :selected="page === currentPage"
                            >{{page}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <a
                :class="{'select-pagination__btn': true, 'disabled': next === ''}"
                :href="next === '' ? '#' : next"
                aria-label="Следующая страница"
                @click.stop.prevent="$emit('page-selected', getPageFromUrl(next))"
            >
                <span class="fm-chevron-right"></span>
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        currentPage: Number,
        totalPages: Number,
        previous: String,
        next: String,
        links: Array,
    },
    emits: ['page-selected'],
    methods: {
        getPageFromUrl(url) {
            this.$emit('page-selected',(new URL(url)).searchParams.get('page'));
            return (new URL(url)).searchParams.get('page');
        }
    }
}
</script>
