<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="filterModal" tabindex="-1" aria-labelledby="filterModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content" :class="(filterSection === 'periods') ? 'modal-content_grey': ''">
                <div class="modal-header">
                    <button v-if="(filterSection === 'products' || filterSection === 'periods' || filterSection === 'countries')" class="modal-back modal-back_show" type="button" aria-label="Back" @click="changeFilterSection('')">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <button v-else class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>

                    <h5 v-if="filterSection === 'products'" class="modal-title" id="filterModalTitle" @click="changeFilterSection('')">Товары</h5>
                    <h5 v-else-if="filterSection === 'periods'" class="modal-title" id="filterModalTitle" @click="changeFilterSection('')">Период</h5>
                    <h5 v-else-if="filterSection === 'countries'" class="modal-title" id="filterModalTitle" @click="changeFilterSection('')">Страны</h5>
                    <h5 v-else class="modal-title" id="filterModalTitle">Фильтр</h5>

                    <button v-if="filterSection === 'products'" type="button" class="modal-fullscreen__select-all-btn" @click="selectAll">Выбрать все</button>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div v-show="filterSection === ''" class="filter-list">
                        <button class="filter-list__item" type="button" @click="changeFilterSection('periods')">
                            <i class="filter-list__ico fm-calendar"></i>
                            <span class="filter-list__title">{{ currentRangeSet.title }}</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>
                        <button class="filter-list__item" type="button" @click="changeFilterSection('products')">
                            <i class="filter-list__ico fm-clipboard"></i>
                            <span v-if="productsSelectedLocal.length" class="filter-list__title">Выбрано: {{ productsSelectedLocal.length }} товара</span>
                            <span v-else class="filter-list__title">Товары</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>
                        <button class="filter-list__item" type="button" @click="changeFilterSection('countries')" v-if="isSupported">
                            <i class="filter-list__ico fm-globe"></i>
                            <span v-if="countriesSelectedLocal.length" class="filter-list__title">Страны: {{ countriesSelectedLocal.join(', ') }}</span>
                            <span v-else class="filter-list__title">Страны</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>
                    </div>
                    <div class="filter-periods" v-show="filterSection === 'periods'">
                        <DashboardDateRanges v-bind:dateFrom="dateFrom"
                                             v-bind:dateTo="dateTo"
                                             v-bind:periods="dateRangeSets"
                                             v-bind:current="currentRangeSet"
                                             v-bind:styleType="2"
                                             v-bind:dateFirstEvent="dateFirstEvent"
                                             v-bind:chartTrue="chartTrue ?? false"
                                             @changedDates="datesChanged"
                                             @changed="rangeSetChanged"></DashboardDateRanges>
                    </div>
                    <div class="filter-products" v-show="filterSection === 'products'">
                        <ProductsSelector
                            ref="ProductsSelector"
                            v-bind:products="productsSelected"
                            v-bind:isInline="true"
                            @changed="selectedProductsChanged"></ProductsSelector>
                    </div>
                    <div class="filter-countries" v-show="filterSection === 'countries'">
                        <div class="multi-select">
                            <div class="multi-select__dropdown">
                                <div class="multi-select__dropdown__header">
                                    <i class="multi-select__dropdown__header__ico fm-search"></i>
                                    <input class="multi-select__search" type="text" placeholder="Поиск">
                                </div>
                                <div class="multi-select__dropdown__body">
                                    <div v-for="(item, index) in filterCountries" class="input">
                                        <div class="input__inner">
                                            <input class="input__checkbox" @change="countriesChanged" v-model="countriesSelectedLocal" :id="'c-'+index" :value="item.country_name" type="checkbox">
                                            <label class="input__checkbox-label" :for="'c-'+index">{{ item.country_name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" aria-label="Clear" class="btn-default" @click="clearFilterEmit()" v-show="filterSection === ''">Сбросить</button>
                    <button v-if="(filterSection === 'products' || filterSection === 'periods' || filterSection === 'countries')" class="btn-default btn-regular" @click="changeFilterSection('')">Сохранить</button>
                    <button v-else @click="apply" data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Фильтровать</button>
                </div>
            </div>
        </div>
    </div>



    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="periodModal" tabindex="-1" aria-labelledby="periodModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-back" type="button" aria-label="Back" @click="toggleModals('#periodModal', '#filterModal')">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <h5 class="modal-title" id="periodModalTitle">Период</h5>
                    <button type="button" class="modal-close" aria-label="Close" @click="toggleModals('#periodModal', '#filterModal')">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    период
                </div>
                <div class="modal-footer">
                    <button class="btn-default btn-regular" @click="toggleModals('#periodModal', '#filterModal')">Сохранить</button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="productsModal" tabindex="-1" aria-labelledby="productsModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-back" type="button" aria-label="Back" @click="toggleModals('#productsModal', '#filterModal')">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <h5 class="modal-title" id="productsModalTitle">Товары</h5>
                    <button type="button" class="modal-close" aria-label="Close" @click="toggleModals('#productsModal', '#filterModal')">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    Товары
                </div>
                <div class="modal-footer">
                    <button class="btn-default btn-regular" @click="toggleModals('#productsModal', '#filterModal')">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductsSelector from '@/Components/Products/ProductsSelector'
import DashboardDateRanges from '@/Components/Dashboard/DashboardDateRanges'
import { store } from '@/store'

export default {
    components: {
        ProductsSelector,
        DashboardDateRanges
    },
    props: [
        'query',
        'productsSelected',
        'dateFrom',
        'dateTo',
        'dateRangeSets',
        'currentRangeSet',
        'dateFirstEvent',
        'filterCountries',
        'selectedCountries',
        'chartTrue'
    ],
    emits: [
        'apply',
        'selectedProductsChanged',
        'datesChanged',
        'rangeSetChanged',
        'countriesChanged',
        'filterClear'
    ],
    computed: {
        isSupported: function () {
            return this.store.account.data_source === 'wildberries';
        }
    },
    data() {
        return {
            filterSection: '',
            productsSelectedLocal: [],
            countriesSelectedLocal: [],
            store
        };
    },
    methods: {
        apply(){
            this.$emit('countriesChanged', this.countriesSelectedLocal);
            this.$emit('selectedProductsChanged', this.productsSelectedLocal);
            this.$emit('apply');
        },
        countriesChanged() {
            this.$emit('countriesChanged', this.countriesSelectedLocal);
        },
        selectAll(){
            this.$refs.ProductsSelector.selectAll();
        },
        clearFilterEmit(){
            this.$emit('filterClear');
        },
        countriesChangedEvent(id){
            if(this.countriesSelectedLocal.includes(id)){
                let index = this.countriesSelectedLocal.indexOf(id);
                if (index !== -1) {
                    this.countriesSelectedLocal.splice(index, 1);
                }
            }
            else {
                this.countriesSelectedLocal.push(id);
            }
        },
        selectedProductsChanged(data)
        {
            this.productsSelectedLocal = data;
        },
        datesChanged(dates){
            this.$emit('datesChanged', dates);
        },
        rangeSetChanged(rangeSet){
            this.$emit('rangeSetChanged', rangeSet);
        },
        changeFilterSection(filterSection = ''){
            this.filterSection = filterSection;
            if (filterSection === 'products'){
                this.$refs.ProductsSelector.openMultiselect(true);
                this.$refs.ProductsSelector.mobileEventAdd('filterModal');

            }else {
                this.$refs.ProductsSelector.destroyMobileEventAdd();
            }

        },
        toggleModals(parent_modal, target_modal) {
            $(parent_modal).modal('hide');
            setTimeout(() => {
                $(target_modal).modal('show');
            }, 0);
        },
        jsInit(){
            let self = this;

            if (typeof window.isDashboardFilterModalJsMounted == 'undefined'){

                let clickHandler = 'click';

                if('ontouchstart' in document.documentElement) {
                    clickHandler = 'touchend';
                }

                console.log('Mounted: isDashboardFilterModalJsMounted');

                window.isDashboardFilterModalJsMounted = true;
            }

            $(document).off('hidden.bs.modal', '#filterModal');
            $(document).on('hidden.bs.modal', '#filterModal', function (e) {
                self.filterSection = '';
            })



        },
    },
    mounted() {
        this.jsInit();
        this.productsSelectedLocal = this.productsSelected;
        this.countriesSelectedLocal = this.selectedCountries;
    }
}
</script>
