<template>
    <Head title="Dashboard"/>

    <BreezeAuthenticatedLayout>
        <template #header-title>
            <h1 class="page_title">{{ __("global.hint.redeems.title") }}</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">
                <BreezeDashboardInfo></BreezeDashboardInfo>

                <div class="filter-panel">
                    <div class="filter-panel__inner">
                        <div class="input input_combo input_combo_2">
                            <div class="input__inner">
                                <input class="input__text-input" id="selectSearchProductRedeems" placeholder="Поиск по номеру заказа" type="text" @keyup.enter="filterSubmit(false, null)"
                                   v-model="filter.query">
                                <div class="input__actions">
                                    <button class="input__btn fm-x" type="button" v-if="query" @click="filterReset()" aria-label="Сбросить"></button>
                                    <button class="input__btn fm-search" type="button" @click="filterSubmit(false, null)" aria-label="Искать"></button>
                                </div>
                            </div>
                        </div>

                        <button class="dashboard-nav__btn dashboard-nav__btn_products" :class="{active: !datesAreDefault && defaults && (query || dateFrom !== defaults.dateFrom || dateTo !== defaults.dateTo)}" type="button" data-toggle="modal" data-target="#redeemsFilterModal">
                            <i class="fm-calendar"></i>
                        </button>
                    </div>
                </div>
                <!--
                <div class="dashboard_panel">
                    <div class="toggle_filter">
                        <button type="button" class="select_filter_button js_trigger__click"
                                data-element=".top_filter_row" data-toggle="false"><span class="fm-filter"></span>Отбор
                        </button>
                    </div>
                    <div class="top_filter_row">
                        <div id="search_outer" class="search_outer desktop_select">
                            <label for="selectSearchProductRedeems"><span class="fm-search"></span></label>
                            <input id="selectSearchProductRedeems" class="form-control pl-5" @keyup.enter="filterSubmit"
                                   v-model="filter.query" type="text"
                                   placeholder="Наименование, артикул, баркод">
                        </div>
                        <div class="datePicker_outer">
                            <div id="reportRange" class="datePicker_inner">
                                <span></span>
                            </div>
                        </div>
                        <div class="filter_button ml-auto">
                            <button type="button" class="index_filter_button"
                                    :class="{active: canFilter}"
                                    @click="filterSubmit" data-toggle="false"><span class="fm-filter"></span>
                                Фильтр
                            </button>
                        </div>
                    </div>
                </div>
                -->

                <div class="table_outer">
                    <div class="expenses-wrapper">
                        <table v-if="redeems.length && !loading"
                            class="table table-fw-widget redeems-table">
                            <thead>
                                <tr>
                                    <th class="checkbox-td" style="width: 1%;">
                                        <div class="input">
                                            <div class="input__inner">
                                                <input class="input__checkbox" id="s-all" type="checkbox" :indeterminate="multiSelectedItems.length > 0 && multiSelectedItems.length != redeems.length" :checked="isAllChecked" @change="checkAll()">
                                                <label class="input__checkbox-label" for="s-all"></label>
                                            </div>
                                        </div>
                                    </th>
                                    <th style="text-align: left;">Заказы</th>
                                    <!--
                                    <th width="8%" @click="sortByColumn('date')" class="sortable-header"
                                        :class="{'reorder_outer': sort.orderBy === 'date'}"
                                        style="text-align: left;">Дата
                                        <button v-show="sort.orderBy === 'date'" :class="{active: !sort.desc || sort.desc === -1}"
                                                type="button" class="reorder_button"></button>
                                    </th>
                                    -->

                                    <th style="text-align: left;">Сумма заказа</th>
                                    <th style="text-align: left;max-width:120px;width:150px;">Дата выкупа</th>
                                    <th style="text-align: left;">Удержания</th>
                                    <th style="text-align: left;max-width: 150px;width:150px;">Стоимость <span class="d-none d-md-inline">услуги</span></th>
                                    <th style="text-align: left; width: 20%;">Выкупщик </th>
                                    <th style="text-align: center;">Товар не вернулся</th>
                                    <th style="text-align: center;">Отзыв получен</th>
                                    <th style="text-align: center; width: 1%;"><span class="d-none d-sm-inline">Действия</span></th>

                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(model, key) in redeems">
                                    <tr>
                                        <td class="checkbox-td">
                                            <div class="input">
                                                <div class="input__inner">
                                                    <input class="input__checkbox" :id="'s-'+model.id" type="checkbox" :checked="multiSelectedItems.includes(model.id)" @change="checkIndeterminate(model)">
                                                    <label class="input__checkbox-label" :for="'s-'+model.id"></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Заказы">
                                            <div class="item__order">
                                                <button class="copy-btn" type="button" data-bm-tooltip="Копировать" @click="copy(model.g_number)"><span class="fm-copy"></span></button>
                                                <span class="item__order__title" :title="model.g_number">{{ model.g_number }}</span>
                                            </div>
                                            <div>{{ model.date_formatted }}</div>
                                            <!--
                                            <div class="inner-relative">
                                                <span>
                                                    <span class="multi-select__item__copy-item">
                                                        <button type="button" data-bm-tooltip="Копировать" @click="copy(model.g_number)"><span class="fm-copy"></span></button>
                                                        {{ model.g_number }}
                                                    </span>
                                                </span>
                                            </div>
                                            -->
                                        </td>
                                        <!--
                                        <td data-title="Дата заказа" @click="redeemEdit(model)">
                                            <div class="inner-relative"><span>{{ model.date_formatted }}</span></div>
                                        </td>
                                        -->
                                        <td data-title="Сумма заказа" @click="redeemEdit(model)">
                                            <div class="inner-relative"><span><Currency :value="getOrderPrice(model)"/></span></div>
                                        </td>
                                        <td data-title="Дата выкупа" @click="redeemEdit(model)">
                                            <div v-if="model.redeem_date_empty" style="min-height:auto;" class="inner-text-block__btn disabled">
                                                <input type="text" value="—">
                                            </div>
                                            <div v-else class="inner-relative"><span>{{ model.redeem_date }}</span></div>
                                        </td>
                                        <td data-title="Удержания" @click="redeemEdit(model)">
                                            <div class="inner-relative"><span><Currency :value="Math.abs(model.redeems_expenses)"/></span></div>
                                        </td>
                                        <td data-title="Стоимость услуги" class="inner-text-block">
                                            <div class="item__data item__data_mob">
                                                {{ model.price }}&nbsp;₽
                                            </div>
                                            <div class="item__data item__data_desktop">
                                                <div class="text_block price-block">
                                                    <label>
                                                        <span>&#8381;</span>
                                                        <input :id="'redeem-price-' + model.id"
                                                               v-bind:value="model.price_comma"
                                                               v-on:input="model.price = model.price_comma = $event.target.value "
                                                               placeholder="0"
                                                               @focusin="removePlaceholder($event)"
                                                               @focusout="updatePriceInline(model, true, $event)"
                                                               @keyup.enter="updatePriceInline(model, true, $event)"
                                                               class="product-cost-input mask-input"
                                                               type="text">
                                                    </label>
                                                </div>
                                            </div>
<!--                                            <div class="inner-relative"><span><Currency :value="model.price"/></span></div>-->
                                        </td>
                                        <td data-title="Выкупщик" class="inner-text-block">
                                            <!--
                                            <div class="text_block comment-block"
                                                 :class="{'have_comment': model.redeemer !== null && model.redeemer !== ''}">
                                                <div class="comment-text">
                                                    <label class="comment-text__label" :for="'redeemer'+model.id">Выкупщик</label>
                                                    <textarea
                                                        class="comment-text-inner"
                                                        @focusin="hideUpdBtn()"
                                                        @focusout="updateRedeemer(model); showUpdBtn()"
                                                        v-model="model.redeemer"
                                                        :id="'redeemer'+model.id"
                                                    >{{ model.redeemer }}</textarea>
                                                </div>
                                                <span class="delete-comment" @click="removeRedeemer(model)"><span
                                                    class="fm-close"></span></span>
                                            </div>
                                            -->
                                            <div class="text_block text-block">
                                                <label>
                                                    <input
                                                        :id="'redeemer'+model.id"
                                                        v-model="model.redeemer"
                                                        @focusout="updateRedeemer(model)"
                                                        class="product-cost-input"
                                                        placeholder="ООО &laquo;Выкупщик WB&raquo;"
                                                        type="text">
                                                </label>
                                            </div>
                                        </td>

                                        <td class="checkbox-td" data-title="Товар не вернулся">
                                            <div class="input">
                                                <div class="input__inner">
                                                    <input class="input__checkbox" @change="updateWarehouseStatus(model, $event)" :id="'nr-'+model.id" type="checkbox" :checked="model.warehouse_return">
                                                    <label class="input__checkbox-label" :for="'nr-'+model.id"></label>
                                                </div>
                                            </div>
                                        </td>

                                        <td class="checkbox-td" data-title="Отзыв получен">
                                            <div class="input">
                                                <div class="input__inner">
                                                    <input class="input__checkbox" @change="updateFeedbackReceived(model, $event)" :id="'sp-'+model.id" type="checkbox" :checked="model.feedback_received">
                                                    <label class="input__checkbox-label" :for="'sp-'+model.id"></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="action-td">
                                            <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="redeemEdit(model)"></button>
                                            <!--
                                            <div class="inner-relative">
                                                <button type="button" class="dropdown_toggle" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    <span class="fm-more-horizontal"></span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-right">
                                                    <li>
                                                        <button class="dropdown-item" @click="redeemEdit(model)">Изменить</button>
                                                    </li>
                                                    <li>
                                                        <button class="dropdown-item" @click="redeemDelete(model.id)"
                                                                data-toggle="modal" data-target="#modal_notificationDelete">Удалить</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            -->
                                        </td>
                                    </tr>

                                    <tr v-if="model.summary" class="total_table_row total_table_row_xs">
                                        <td class="text-right" colspan="2">{{ model.summary.label }}:</td>
                                        <td><Currency :value="Math.abs(model.summary.total_redeems_expenses)"/></td>
                                        <td colspan="2"><Currency :value="model.summary.total"/></td>
                                    </tr>
                                    <tr v-if="model.summary" class="total_table_row total_table_row_sm">
                                        <td colspan="4" class="text-right">{{ model.summary.label }}:</td>
                                        <td colspan="1"><Currency :value="Math.abs(model.summary.total_redeems_expenses)"/></td>
                                        <td colspan="2"><Currency :value="model.summary.total"/></td>
                                    </tr>
                                    <tr v-if="model.summary" class="total_table_row total_table_row_md">
                                        <td colspan="4" class="text-right">{{ model.summary.label }}:</td>
                                        <td colspan="1"><Currency :value="Math.abs(model.summary.total_redeems_expenses)"/></td>
                                        <td colspan="5"><Currency :value="model.summary.total"/></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <TableLoader :show="loading"/>
                <div v-if="!redeems.length && !loading" class="dashboard_panel dashboard_panel_pt">
                    <div class="dashboard_panel_inner">

                        <div class="expenses-wrapper no_items">
                            <div class="empty_banner">
                                <div class="empty_banner_search">
                                    <span class="fm-rotate-ccw"></span>
                                </div>
                                <h4 class="empty_banner_title">Заказы отсутствуют</h4>
                                <p class="empty_banner_description">Добавьте заказы, которые были выкуплены</p>
<!--                                <button class="btn-default btn-regular" type="button" @click="redeemCreate">Добавить</button>-->
                                <button class="btn-default btn-regular" type="button" @click="redeemAdd">Добавить</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--
        <div v-if="redeems.length" class="buttons-row fixed_bottom_row right_position">
            <a href="javascript:;" @click="redeemAdd" class="btn-default btn-regular">Добавить</a>
        </div>
        -->

        <div class="modal modal-fullscreen redeem-create-modal" id="redeemCreate" aria-labelledby="selfPurchaseCreateTitle" aria-hidden="true" data-redeem_modal>
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <form class="modal-content" @submit.prevent="submit">
                    <div class="modal-header">
                        <button v-if="filterSection === 'orders'" class="modal-back modal-back_show" type="button" aria-label="Back" @click="changeFilterSection('')">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <button v-else class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 v-if="redeemIsCreating" class="modal-title">Добавить Самовыкуп</h5>
                        <h5 v-else-if="filterSection === 'orders'" class="modal-title">Заказ</h5>
                        <h5 v-else class="modal-title">Изменить Самовыкуп</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>

                        <input type="hidden" v-model="form.id">
                        <div class="modal-body scrollbar">
                            <div class="info-list" v-show="filterSection === ''">
                                <template v-if="redeemIsCreating">
                                    <div class="info-list__input info-list__input_combo">
                                        <div class="info-list__input__inner">
                                            <div class="info-list__input__basic-label" :class="{error: (form.errors.g_number || form.errors.g_numbers) && (!form.g_number || !form.g_numbers.length)}">Заказ</div>
                                            <div v-if="form.g_number">
                                                <div class="item__product-id">
                                                    <button class="copy-btn" @click="copy(form.g_number)" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                                    <span class="item__product-id__link">{{ form.g_number }}</span>
                                                    <div v-if="editingRedeem" class="multi-select__choosen__subtitle">{{ getDateFromOrder(form.g_number) }} / <span class="nowrap">Сумма заказа: <Currency :value="getOrderPrice(getModelByGNumber(form.g_number))"/></span></div>
                                                </div>
                                            </div>
                                            <p class="invalid-feedback">{{ form.errors.g_number }} {{ form.errors.g_numbers }}</p>
                                        </div>
                                        <button class="info-list__input__btn fm-chevron-right" type="button" @click="changeFilterSection('orders'), openMultiselect()"></button>
                                    </div>
                                </template>

                                <template v-if="editingRedeem && form.g_number === editingRedeem.g_number">
                                    <div class="multi-select_modal">
                                        <div class="multi-select__dropdown__body">
                                            <productMultiselectAccordion v-bind:isCurrent="key === 0" v-bind:isSingle="false">
                                                <template v-if="form.g_number" v-slot:header>
                                                    <div class="multi-select__item__header__content">
                                                        <div class="multi-select__item__header__content__body">
                                                            <div class="info-list__input__basic-label" :class="{error: (form.errors.g_number || form.errors.g_numbers) && (!form.g_number || !form.g_numbers.length)}">Заказ</div>
                                                            <div class="multi-select__item__row">
                                                                <span class="multi-select__item__copy-item">
                                                                    <button type="button" data-bm-tooltip="Копировать" @click="copy(form.g_number)"><span class="fm-copy"></span></button>
                                                                    {{ form.g_number }}
                                                                </span>
                                                            </div>
                                                        <div class="multi-select__item__subtitle whitespace-nowrap">{{ getDateFromOrder(form.g_number) }} / Сумма заказа: <Currency :value="getOrderPrice(getModelByGNumber(form.g_number))"/></div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-slot:content>
                                                    <div v-for="(product, k) in editingRedeem.products" class="multi-select__item__row">
                                                        <div class="multi-select__product">
                                                            <img class="multi-select__product__img" :src="product.image" width="40" height="40" alt="">
                                                            <div class="multi-select__product__content">
                                                                <div class="multi-select__product__title">{{ product.title }}</div>
                                                                <span class="multi-select__item__copy-item">
                                                                        <button type="button" @click="copy(product.nm_id)"><span class="fm-copy"></span></button>
                                                                        <a :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                                                    </span>
                                                                <span v-if="product.supplier_article"> / {{product.supplier_article}}</span>
                                                                <span> / {{ product.barcode }}</span>
                                                                <span v-if="product.tech_size"> / Размер: {{ product.tech_size }}</span>
                                                                <div>Количество: {{ product.quantity }} / <span class="nowrap">Сумма: <Currency :value="product.total_with_discount"/></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </productMultiselectAccordion>
                                        </div>
                                    </div>
                                </template>

                                <template v-if="editingRedeem && form.g_number === editingRedeem.g_number">
                                    <div class="info-list__input info-list__input_dasibled" v-if="!redeemIsCreating">
                                        <div class="info-list__input__inner">
                                            <div class="info-list__input__label">Дата заказа</div>
                                            <div class="info-list__input__text-input">{{ editingRedeem.date_formatted }}</div>
                                        </div>
                                    </div>

                                    <div class="info-list__input info-list__input_dasibled" v-if="!redeemIsCreating">
                                        <div class="info-list__input__inner">
                                            <div class="info-list__input__label">Дата выкупа</div>
                                            <div class="info-list__input__text-input">
                                                <span v-if="editingRedeem.redeem_date_empty">—</span>
                                                <span v-else>{{ editingRedeem.redeem_date }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info-list__input info-list__input_dasibled" v-if="!redeemIsCreating">
                                        <div class="info-list__input__inner">
                                            <div class="info-list__input__label">Сумма заказа</div>
                                            <div class="info-list__input__text-input">
                                                <span><Currency :value="getOrderPrice(editingRedeem)"/></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="info-list__input info-list__input_dasibled" v-if="!redeemIsCreating">
                                        <div class="info-list__input__inner">
                                            <div class="info-list__input__label">Удержания</div>
                                            <div class="info-list__input__text-input"><Currency :value="Math.abs(editingRedeem.redeems_expenses)"/></div>
                                        </div>
                                    </div>
                                </template>


                                <div class="info-list__input">
                                    <div class="info-list__input__inner" :class="{error: form.errors.price}">
                                        <div class="info-list__input__label">
                                            <label for="redeem-price">Стоимость услуги</label>
                                            <button class="input__label__ico fm-info" type="button" :data-tippy-content="__('hints.dashboard.redeems')"></button>
                                        </div>
                                        <input class="info-list__input__text-input"
                                               id="redeem-price"
                                               type="text"
                                               v-bind:value="form.price_comma"
                                               v-on:input="form.price = form.price_comma = $event.target.value"
                                               :class="{error: $page.props.errors.price}"
                                               autocomplete="off"
                                               @focusin="removePlaceholder($event)"
                                               placeholder="0">
                                    </div>
                                </div>

                                <div class="info-list__input">
                                    <div class="info-list__input__inner">
                                        <div class="info-list__input__label">
                                            <label for="redeem-redeemer">Выкупщик</label>
                                            <button class="input__label__ico fm-info" type="button" :data-tippy-content="__('hints.dashboard.redeemer')"></button>
                                        </div>
                                        <input class="info-list__input__text-input" id="redeem-redeemer" type="text" v-model="form.redeemer" :class="{error: $page.props.errors.redeemer}" placeholder="ООО &laquo;Выкупщик WB&raquo;">
                                    </div>
                                </div>

                                <div class="info-list__input">
                                    <div class="info-list__input__inner">
                                        <input class="input__checkbox" id="not-returned" v-model="form.warehouse_return" type="checkbox">
                                        <label class="input__checkbox-label" for="not-returned">Товар не вернулся</label>
                                    </div>
                                </div>

                                <div class="info-list__input info-list__input_last">
                                    <div class="info-list__input__inner">
                                        <input class="input__checkbox" id="feedback-received" v-model="form.feedback_received" type="checkbox">
                                        <label class="input__checkbox-label" for="feedback-received">Отзыв получен</label>
                                    </div>
                                </div>

                            </div>

                            <div class="info-list-orders" v-show="filterSection === 'orders'">
                                <div class="multi-select" :class="{'multi-select_single': !redeemIsCreating, 'multi-select_multiple': redeemIsCreating}" v-click-outside="onClickOutside">
                                    <div class="multi-select__dropdown" :class="{error: form.errors.g_number}">
                                        <div class="multi-select__dropdown__header">
                                            <i class="multi-select__dropdown__header__ico fm-search"></i>
                                            <input class="multi-select__search" ref="searchInput" @input="redeemOrdersSearch()" v-model="redeemOrders.search" type="text" placeholder="Номер заказа, артикул или баркод товара">
                                        </div>

                                        <div v-if="redeemIsCreating" class="multi-select__dropdown__body" v-show="!redeemOrders.isLoading">
                                            <template v-for="(model, key) in redeemOrders.data.results">
                                                <productMultiselectAccordion v-bind:isSingle="model.products.length === 0">
                                                    <template v-if="model.products.length > 0" v-slot:header>
                                                        <div class="input">
                                                            <div class="input__inner">
                                                                <input class="input__checkbox"
                                                                       v-model="form.g_numbers"
                                                                       :value="model.g_number"
                                                                       :id="'pm-'+model.id" type="checkbox">
                                                                <label class="input__checkbox-label" :for="'pm-'+model.id"></label>
                                                            </div>
                                                        </div>
                                                        <div class="multi-select__item__header__content">
                                                            <div class="multi-select__item__header__content__body">
                                                                <div class="multi-select__item__row">
                                                                    <span class="multi-select__item__copy-item">
                                                                        <button type="button" data-bm-tooltip="Копировать" @click="copy(model.g_number)"><span class="fm-copy"></span></button>
                                                                        {{ model.g_number }}
                                                                    </span>
                                                                </div>
                                                                <div class="multi-select__item__subtitle">{{ model.date }} / <span class="nowrap">Сумма заказа: 4 080&nbsp;₽</span></div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <template v-if="model.products.length > 0" v-slot:content>
                                                        <div v-for="(product, k) in model.products" class="multi-select__item__row">
                                                            <div class="multi-select__product">
                                                                <img class="multi-select__product__img" :src="product.image" width="40" height="40" alt="">
                                                                <div class="multi-select__product__content">
                                                                    <div class="multi-select__product__title">{{ product.title }}</div>
                                                                    <span class="multi-select__item__copy-item">
                                                                        <button type="button" @click="copy(product.nm_id)"><span class="fm-copy"></span></button>
                                                                        <a :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                                                    </span>
                                                                    <span v-if="product.supplier_article"> / {{product.supplier_article}}</span>
                                                                    <span> / {{ product.barcode }}</span>
                                                                    <span v-if="product.tech_size"> / Размер: {{ product.tech_size }}</span>
                                                                    <!-- <div>Цена: ₽ {{ product.price }}</div> -->
                                                                    <div>Количество: / <span class="nowrap">Сумма: 4 080&nbsp;₽</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>


                                                </productMultiselectAccordion>
                                            </template>

                                            <div v-show="redeemOrders.data.pagination.more || redeemOrders.isShowMore" class="show-more-container" style="text-align:center;">
                                                <button v-show="!redeemOrders.isShowMore" type="button" class="btn-default" @click="redeemOrdersShowMore()">Показать больше</button>
                                                <span v-show="redeemOrders.isShowMore" class="multi-select__loading">
                                                    <span class="spinner-border" role="status">
                                                        <span class="visually-hidden">Загрузка...</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-else class="multi-select__dropdown__body" v-show="!redeemOrders.isLoading">
                                            <template v-for="(model, key) in redeemOrders.data.results">
                                                <productMultiselectAccordion v-bind:isCurrent="key === 0" v-bind:isSingle="model.products.length === 0">
                                                    <template v-if="model.products.length > 0" v-slot:header>
                                                        <div class="input">
                                                            <input class="input__checkbox"
                                                                    @change="onClickOutside, changeFilterSection('')"
                                                                    data-multiselect-check-all
                                                                    v-model="form.g_number"
                                                                    :value="model.g_number"
                                                                    :checked="model.g_number == form.g_number"
                                                                    :id="'pp-'+model.id" type="radio">
                                                            <label :for="'pp-'+model.id"></label>
                                                        </div>
                                                        <div class="multi-select__item__header__content">
                                                            <div class="multi-select__item__header__content__body">
                                                                <div class="multi-select__item__row">
                                                                    <span class="multi-select__item__copy-item">
                                                                        <button type="button" data-bm-tooltip="Копировать" @click="copy(model.g_number)"><span class="fm-copy"></span></button>
                                                                        {{ model.g_number }}
                                                                    </span>
                                                                </div>
                                                            <div class="multi-select__item__subtitle whitespace-nowrap">{{ model.date }} / Сумма заказа: <Currency :value="getOrderPrice(model)"/></div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <template v-if="model.products.length > 0" v-slot:content>
                                                        <div v-for="(product, k) in getCountedProducts(model.products)" class="multi-select__item__row">
                                                            <div class="multi-select__product">
                                                                <img class="multi-select__product__img" :src="product.image" width="40" height="40" alt="">
                                                                <div class="multi-select__product__content">
                                                                    <div class="multi-select__product__title">{{ product.title }}</div>
                                                                    <span class="multi-select__item__copy-item">
                                                                        <button type="button" @click="copy(product.nm_id)"><span class="fm-copy"></span></button>
                                                                        <a :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                                                    </span>
                                                                    <span v-if="product.supplier_article"> / {{product.supplier_article}}</span>
                                                                    <span> / {{ product.barcode }}</span>
                                                                    <span v-if="product.tech_size"> / Размер: {{product.tech_size}}</span>
                                                                    <div class="whitespace-nowrap">Количество: {{ product.count }} / Цена: <Currency :value="product.total_with_discount"/></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>


                                                </productMultiselectAccordion>
                                            </template>

                                            <div v-show="redeemOrders.data.pagination.more || redeemOrders.isShowMore" class="show-more-container" style="text-align:center;">
                                                <button v-show="!redeemOrders.isShowMore" type="button" class="btn-default" @click="redeemOrdersShowMore()">Показать больше</button>
                                                <span v-show="redeemOrders.isShowMore" class="multi-select__loading">
                                                    <span class="spinner-border" role="status">
                                                        <span class="visually-hidden">Загрузка...</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>



                                        <div v-show="!redeemOrders.isLoading && redeemOrders.noData" class="empty_banner">
                                            <div class="empty_banner_search">
                                                <span class="fm-search"></span>
                                            </div>
                                            <h4 class="empty_banner_title">Ничего не найдено</h4>
                                        </div>

                                        <div class="multi-select__loading" v-show="redeemOrders.isLoading">
                                            <span class="spinner-border" role="status">
                                                <span class="visually-hidden">Загрузка...</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer" v-show="filterSection === ''">
                            <button v-if="!redeemIsCreating" class="btn-default" @click="redeemDelete(form.id)" type="button" data-dismiss="modal">Удалить</button>
                            <button type="submit" aria-label="Close" class="btn-default"
                                    :disabled="!redeemsFormPopulated"
                                    :class="{'btn-regular': redeemsFormPopulated, 'opacity-25': isSubmitting}">Сохранить
                            </button>
                        </div>
                        <div class="modal-footer" v-show="redeemIsCreating && filterSection === 'orders'">
                            <button type="button" aria-label="Close" class="btn-default"
                                    @click="changeFilterSection('')"
                                    :disabled="!form.g_numbers.length"
                                    :class="{'btn-regular': form.g_numbers.length, 'opacity-25': isSubmitting}">Сохранить
                            </button>
                        </div>

                </form>
            </div>
        </div>


        <div class="modal modal-fullscreen redeem-create-modal" id="redeemAdd" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <form class="modal-content" @submit.prevent="submit">
                    <div class="modal-header">
                        <button v-if="filterSection === 'periodFilter'" class="modal-back modal-back_show" type="button" aria-label="Back" @click="changeFilterSection('')">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <button v-else class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 v-if="filterSection === ''" class="modal-title">Добавить Самовыкуп</h5>
                        <h5 v-else-if="filterSection === 'periodFilter'" class="modal-title">Выберите период</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>

                        <input type="hidden" v-model="form.id">
                        <div class="modal-body scrollbar">
                            <div class="info-list-orders" v-show="filterSection === ''">
                                <div class="multi-select multi-select_multiple" v-click-outside="onClickOutside">
                                    <div class="multi-select__dropdown" :class="{error: form.errors.g_number}">
                                        <div class="multi-select__dropdown__header">
                                            <i class="multi-select__dropdown__header__ico fm-search"></i>
                                            <input class="multi-select__search" ref="searchInput" @input="redeemOrdersSearch()" v-model="redeemOrders.search" type="text" placeholder="Номер заказа, артикул или баркод товара">
<!--                                            <button
                                                class="multi-select__dropdown__header__filterBtn fm-filter active"
                                                type="button"
                                                aria-label="Фильтр"
                                                @click="changeFilterSection('periodFilter')">
                                            </button>-->
                                        </div>
                                        <div class="multi-select__dropdown__body" v-show="!redeemOrders.isLoading">
                                            <template v-for="(model, key) in redeemOrders.data.results">
                                                <productMultiselectAccordion v-bind:isCurrent="key === 0" v-bind:isSingle="model.products.length === 0">
                                                    <template v-if="model.products.length > 0" v-slot:header>
                                                        <div class="input">
                                                            <div class="input__inner">
                                                                <input class="input__checkbox"
                                                                       v-model="form.g_numbers"
                                                                       :value="model.g_number"
                                                                       :id="'pmm-'+model.id" type="checkbox">
                                                                <label class="input__checkbox-label" :for="'pmm-'+model.id"></label>
                                                            </div>
                                                        </div>
                                                        <div class="multi-select__item__header__content">
                                                            <div class="multi-select__item__header__content__body">
                                                                <div class="multi-select__item__row">
                                                                    <span class="multi-select__item__copy-item">
                                                                        <button type="button" data-bm-tooltip="Копировать" @click="copy(model.g_number)"><span class="fm-copy"></span></button>
                                                                        {{ model.g_number }}
                                                                    </span>
                                                                </div>
                                                            <div class="multi-select__item__subtitle">{{ model.date }} / <span class="nowrap">Сумма заказа: <Currency :value="model.total_with_discount"/></span></div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <template v-if="model.products.length > 0" v-slot:content>
                                                        <div v-for="(product, k) in model.products" class="multi-select__item__row">
                                                            <div class="multi-select__product">
                                                                <img class="multi-select__product__img" :src="product.image" width="40" height="40" alt="">
                                                                <div class="multi-select__product__content">
                                                                    <div class="multi-select__product__title">{{ product.title }}</div>
                                                                    <span class="multi-select__item__copy-item">
                                                                        <button type="button" @click="copy(product.nm_id)"><span class="fm-copy"></span></button>
                                                                        <a :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                                                    </span>
                                                                    <span v-if="product.supplier_article"> / {{product.supplier_article}}</span>
                                                                    <span> / {{ product.barcode }}</span>
                                                                    <span v-if="product.tech_size"> / Размер: {{ product.tech_size }}</span>
                                                                    <!-- <div>Цена: ₽ {{ product.price }}</div> -->
                                                                    <div>Количество: {{ product.quantity }} / <span class="nowrap">Сумма: <Currency :value="product.total_with_discount"/></span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>


                                                </productMultiselectAccordion>
                                            </template>
                                            <div v-show="redeemOrders.data.pagination.more || redeemOrders.isShowMore" class="show-more-container" style="text-align:center;">
                                                <button v-show="!redeemOrders.isShowMore" type="button" class="btn-default" @click="redeemOrdersShowMore()">Показать больше</button>
                                                <span v-show="redeemOrders.isShowMore" class="multi-select__loading">
                                                    <span class="spinner-border" role="status">
                                                        <span class="visually-hidden">Загрузка...</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div v-show="!redeemOrders.isLoading && redeemOrders.noData" class="empty_banner">
                                            <div class="empty_banner_search">
                                                <span class="fm-search"></span>
                                            </div>
                                            <h4 class="empty_banner_title">Ничего не найдено</h4>
                                        </div>

                                        <div class="multi-select__loading" v-show="redeemOrders.isLoading">
                                            <span class="spinner-border" role="status">
                                                <span class="visually-hidden">Загрузка...</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="filterSection === 'periodFilter'">
                                Календарь сюда

                                <div class="input">
                                    <div class="input__inner input__inner_info">
                                        <input class="input__checkbox" type="checkbox" id="withoutDate">
                                        <label class="input__checkbox-label" for="withoutDate">Заказы без даты выкупа</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button v-if="filterSection === ''" type="submit" aria-label="Close" class="btn-default"
                                    :disabled="!form.g_numbers.length"
                                    :class="{'btn-regular': form.g_numbers.length, 'opacity-25': isSubmitting}">Сохранить
                            </button>
                            <button
                                v-if="filterSection === 'periodFilter'"
                                type="submit"
                                aria-label="Фильтровать"
                                class="btn-default btn-regular"
                                @click.prevent="changeFilterSection('')">
                                Фильтровать
                            </button>
                        </div>

                </form>
            </div>
        </div>



        <div class="modal modal-fullscreen logout-confirm" id="modalDeleteItem" tabindex="-1" aria-labelledby="modal_notificationDelete_label"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" @click="clearDelete()" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title">Подтверждение</h5>
                        <button type="button" class="modal-close" @click="clearDelete()" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h6 class="logout-confirm__title text-center">Вы уверены?</h6>
                    </div>
                    <div class="modal-footer">
                        <button data-dismiss="modal" aria-label="Close" @click="clearDelete()" class="btn-default">Отменить</button>
                        <button aria-label="Close" @click="confirmDelete" class="btn-default btn-regular">Удалить</button>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div id="notificationMessage" v-if="notification.show" :class="notification.type" class="notification-message is-display-block notification-message_offset">
                <span>{{ notification.message }}</span>
            </div>
        </transition>
    </BreezeAuthenticatedLayout>

    <RedeemsFilterModal ref="redeemsFilterModal"
                        v-bind:filter="filter"
                        v-bind:datesAreDefault="datesAreDefault"
                        @apply="filterSubmit" />

    <Teleport to="#app_child" v-if="redeems.length">
        <div class="buttons-panel">
            <div class="buttons-panel__inner">
                <button v-if="isMultiDeleteOn" @click="openDeleteModal('multi')" class="btn-default btn-regular buttons-panel__redeem-add" type="button">Удалить</button>
                <button v-else class="btn-default btn-regular buttons-panel__redeem-add" type="button" @click="redeemAdd">Добавить</button>
            </div>
        </div>
    </Teleport>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import {Head} from '@inertiajs/inertia-vue3';
import BreezeDashboardInfo from '@/Components/Dashboard/DashboardInfo.vue'

import productMultiselectAccordion from "@/Components/Expenses/productMultiselectAccordion";
import Select2 from "vue3-select2-component";
import {pickBy, throttle} from "lodash-es";
import {Inertia} from "@inertiajs/inertia";
import Inputmask from "inputmask";
import RedeemsFilterModal from '@/Components/Redeems/RedeemsFilterModal.vue'
import Currency from '@/Components/Common/Currency.vue'
import TableLoader from "@/Components/Common/TableLoader.vue";

export default {
    components: {
        TableLoader,
        Currency,
        BreezeAuthenticatedLayout,
        Head,
        BreezeDashboardInfo,
        Inputmask,
        Select2,
        productMultiselectAccordion,
        RedeemsFilterModal,
    },
    props: [
        'data',
        'query',
        'desc',
        'dateFrom',
        'dateTo',
        'datesAreDefault',
        'defaults'
    ],
    data() {
        let data = {
            form: this.$inertia.form({
                "id": null,
                "redeemer": '',
                "price": '',
                "g_number": null,
                "g_numbers": [],
                "date": null,
                "feedback_received": false,
                "warehouse_return": false
            }),
            loading: true,
            isSubmitting: false,
            redeemsFormPopulated: false,
            delete_id: null,
            redeemer_delete_id: null,
            redeems: {},
            filter: {
                query: null,
                dateFrom: null,
                dateTo: null,
            },
            canFilter: false,
            sort: {
                orderBy: 'date',
                desc: this.$props.desc
            },
            notification: {
                show: false,
                message: 'Успешно скопировано',
                type:'info',
            },
            redeemIsCreating: true,
            editingRedeem: null,
            select2: null,
            select2Options: null,
            multiselectOpen: false,
            cancelTokenSource: null,
            redeemOrders: {
                request: false,
                noData: false,
                isLoading: false,
                isShowMore: false,
                page: 1,
                search: '',
                is_grouped: 1,
                data: {
                    results: null,
                    pagination: {
                        isMore: false
                    }
                }
            },
            redeemOrdersSearchTimeoutId: null,
            filterSection: '',
            multiSelectedItems: [],
            deleteEvent: null,
        };
        data.select2Settings = {
            multiple: true,
            ajax: {
                url: '/redeems/orders',
                dataType: 'json',
                data: function (params) {
                    let query = {
                        search: params.term,
                        page: params.page || 1,
                    }

                    // Query parameters will be ?search=[term]&page=[page]
                    return query;
                }
            },
            theme: 'default modal_select',
            dropdownParent: '#select2ExpensesContainer > div',
            dropdownCssClass: 'single-select',
            placeholder: 'Товар',
            closeOnSelect: false,
            templateResult: function(opt){
                $('input.select2-search__field').prop('placeholder', 'Имя, артикул или баркод');
                if (!opt.id) {
                    return opt.text.toUpperCase();
                }
                let optimage = opt.image;
                if (!optimage) {
                    return opt.text.toUpperCase();
                } else {

                    let $opt = $('<span class="opt_image"><img src="' + optimage + '" width="40px" alt="' + opt.text.toUpperCase() + '" /></span><span class="opt_text"><span class="opt_text_block">' + opt.text + '</span> <span class="opt_text_block">' + ((opt.group_nm_count > 1) ? '' : opt.barcode + ' / ') + opt.nm_id + '</span></span>');
                    return $opt;
                }
            },
            templateSelection: function(opt){
                let id = opt.id;
                if (!id) {
                    return opt.text.toUpperCase();
                }

                let image = opt.image;
                let text = opt.text
                let barcode = opt.barcode;
                let nm_id = opt.nm_id;
                if (!image) {
                    return opt.text.toUpperCase();
                } else {
                    let $opt = $(
                        '<span class="opt_image"><img src="' + image + '" width="40px" alt="' + text.toUpperCase() + '" /></span><span class="opt_text"><span class="opt_text_block">' + text + '</span> <span class="opt_text_block">'+ barcode +' / '+ nm_id +'</span></span>'
                    );
                    return $opt;
                }
            },
            allowClear: true,
            width: 'resolve',
            language: {
                noResults: function () {
                    return "Совпадений не найдено";
                },
                searching: function() {
                    return "Загрузка...";
                }
            }
        };


        return data;
    },
    computed:{
        filterClientDataNS() {
            return `account-${this.$page.props.auth.user.user_account.account_id}/redeems/filter`.toUpperCase();
        },
        isAllChecked: function () {
            return this.multiSelectedItems.length === this.redeems.length;
        },
        isMultiDeleteOn: function () {
            return this.multiSelectedItems.length > 0;
        },
    },
    watch: {
        form: {
            deep: true,
            handler(newValue, oldValue) {
                if (this.form.price === '' || (this.form.g_number === '' && !this.form.g_numbers.length))
                    this.redeemsFormPopulated = false;
                else
                    this.redeemsFormPopulated = true;
            }
        },
        filter: {
            deep: true,
            handler(newValue, oldValue) {
                localStorage.setItem(this.filterClientDataNS, JSON.stringify(newValue));
                if (newValue.query == '')
                    newValue.query = null;
                if (this.$props.query !== newValue.query || this.$props.dateFrom !== newValue.dateFrom || this.$props.dateTo !== newValue.dateTo){
                    this.canFilter = true;
                    // if (this.$props.dateFrom !== newValue.dateFrom || this.$props.dateTo !== newValue.dateTo)
                    //     this.filterSubmit();
                }

                else
                    this.canFilter = false;
            }
        },
    },

    mounted() {
        const clientFilterData = localStorage.getItem(this.filterClientDataNS);

        if (clientFilterData) {
            const filterData = JSON.parse(clientFilterData);
            let dateRangeSets = this.__getFilterDateRangeSets();
            if (filterData.activeRange !== 'custom') {
                const range  = dateRangeSets.find((r) => r.id === filterData.activeRange);
                if (range) {
                    filterData.dateFrom = range.dateFrom;
                    filterData.dateTo = range.dateTo;
                }
            }
            this.filterSubmit(true,filterData);
        }else{
            this.redeems = this.data;
            this.loading = false;
            this.filter.query = this.query;
            this.filter.dateFrom = this.$props.dateFrom;
            this.filter.dateTo = this.$props.dateTo;

        }

        this.init();

        let amountEl = document.getElementById('redeem-price');
        Inputmask({
            regex: "^[0-9.,]+$",
            placeholder:' ',
            showMaskOnHover:false,
        }).mask(amountEl);

        setTimeout(() => {

        let amountElInline = document.querySelectorAll('.mask-input');
        amountElInline.forEach((el) => {
            Inputmask({
                regex: "^[0-9.,]+$",
                placeholder: ' ',
                showMaskOnHover: false,
            }).mask(el);
        })

            this.tippyTooltips();
        }, 2000);

        this.jsInit();

    },
    methods: {
        removePlaceholder($event) {
            if ($event.target.value == 0) {
                $event.target.value = '';
            }
            $event.target.removeAttribute('placeholder');
        },
        updateRedeemer: function (model) {
            let self = this;
            let redeemer = model.redeemer;

            model.errors = {};
            axios.post(this.route('redeems.setRedeemer'), {id: model.id, redeemer: redeemer})
                .then(function (response) {
                    //product.comment = comment;
                })
                .catch(function (error) {
                    //console.log(error.response.data)
                    model.errors.redeemer = error.response.data.message;
                    self.showNotification(error.response.data.message);
                });
        },
        removeRedeemer(model) {
            this.redeemer_delete_id = model.id;
            $('#modalDeleteItem').modal('show');
        },
        hideUpdBtn: function() {
            $('[data-btn_row]').addClass('buttons-row_hidden');
        },
        showUpdBtn: function() {
            $('[data-btn_row]').removeClass('buttons-row_hidden');
        },
        updatePriceInline(model, isSingle, event){
            let self = this;
            let price = Number($(event.target).val().replace(',', '.'));
            if (isNaN(price)){
                $(event.target).val(model.price)
                this.showNotification('Неправильный формат данных. Информация не сохранена.','error');
                return false;
            }

            this.form.id = model.id;
            this.form.g_number = model.g_number;
            this.form.redeemer = model.redeemer;
            this.form.price = price;
            this.form.feedback_received = model.feedback_received;
            this.form.warehouse_return = model.warehouse_return;
            let data = this.form.data();
            axios.post(this.route('redeems.edit'), data)
                .then((response) => {
                    self.reloadTable();
                    self.showNotification('Данные сохранены');
                    $('.mask-input').removeClass('error');
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        self.showNotification('Обновите страницу и авторизуйтесь');
                        return false;
                    }

                    $('#redeem-price-'+model.id).addClass('error');
                    Object.keys(error.response.data.errors).forEach(function(key) {
                        self.showNotification(error.response.data.errors[key][0],'error');
                    });
                    setTimeout(()=>{
                        $('#redeem-price-'+model.id).removeClass('error');
                    },5000)

                });


        },
        reloadTable(){
            this.loading = true;
            this.$inertia.get('/redeems', pickBy({
                dateFrom: this.filter.dateFrom,
                dateTo: this.filter.dateTo,
                query: this.filter.query,
            }), {
                replace: true,
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.redeems = this.data;
                    this.loading = false;
                }
            })
        },
        getModelByGNumber(gNumber){
            let model = null;

            if (this.editingRedeem && this.editingRedeem.g_number === gNumber)
                return this.editingRedeem;

            if (this.redeemOrders.data.results) {
                this.redeemOrders.data.results.forEach((redeem) => {
                    if (redeem.g_number === gNumber) {
                        model = redeem;
                        return false;
                    }
                })
            }

            return model;
        },
        getDateFromOrder(gNumber){
            let date = '';
            if (this.editingRedeem && this.editingRedeem.g_number === gNumber){
                date = this.editingRedeem.date_formatted;
                if (this.editingRedeem.redeem_date !== '' && this.editingRedeem.redeem_date !== '-')
                    date += ' - '+this.editingRedeem.redeem_date;
            }


            if (this.redeemOrders.data.results){
                this.redeemOrders.data.results.forEach((order) => {
                    if (order.g_number === gNumber){
                        date = order.date;
                        return false;
                    }

                })
            }
            return date;
        },
        getOrderPrice(model) {
            if (!model)
                return 0;
            let price = 0;
            if (model.products.length > 0){
                model.products.forEach((product) => {
                    price += product.total_with_discount; //* product.count;
                })
            }

            return price;
        },
        getCountedProducts(products) {
            const countedProducts = [];

            products.forEach((product) => {
                const index = countedProducts.findIndex((countedProduct) => countedProduct.barcode === product.barcode);
                if (index === -1) {
                    countedProducts.push({
                        ...product,
                        count: 1,
                    });
                } else {
                    countedProducts[index].count += 1;
                }
            });

            return countedProducts;
        },
        updateFeedbackReceived(model, event){
            let self = this;
            model.feedback_received = $(event.target).prop('checked');
            axios.post(this.route('redeems.feedbackReceived'), {
                id: model.id,
                feedback_received: model.feedback_received
            })
                .then(function (response) {
                    self.showNotification('Сохранено');
                })
                .catch(function (error) {
                    self.showNotification(error);
                });
        },
        updateWarehouseStatus(model,event){
            let self = this;
            model.warehouse_return = $(event.target).prop('checked');
            axios.post(this.route('redeems.warehouseReturn'), {
                id: model.id,
                warehouse_return: model.warehouse_return
            })
                .then(function (response) {
                    self.showNotification('Сохранено');
                })
                .catch(function (error) {
                    self.showNotification(error);
                });
        },
        submit() {
            if (this.isSubmitting)
                return false;

            this.isSubmitting = true;
            let self = this;
            let url = (!this.form.id) ? this.route('redeems.add') : this.route('redeems.edit')
            let data = this.form.data();
            data.price = data.price.toString().replace(',','.');
            //this.form.dateFrom = this.formatDateSubmit(this.form.dateFrom);
            this.$inertia.post(url, data, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    self.form.reset();
                    $('#redeemCreate').modal('hide');
                    $('#redeemAdd').modal('hide');
                    setTimeout(() => self.isSubmitting = false, 200);
                    let url = self.route('redeems') + '?dateFrom=' + self.filter.dateFrom + '&dateTo=' + self.filter.dateTo;
                    if (self.filter.query)
                        url += '&query=' + self.filter.query;
                    Inertia.visit(url, {
                        preserveScroll: true,
                        onSuccess: () => {
                            self.loading = false;
                        }
                    });
                },
                onError: (error) => {
                    this.form.errors = error;
                    Object.keys(error).forEach(function(key) {
                        self.showNotification(error[key],'error');
                    });
                    setTimeout(() => self.isSubmitting = false, 200);
                }
            });
        },
        sortByColumn: function (column) {
            this.sort = {
                orderBy: column,
                desc: (this.sort.desc === 1) ? -1 : 1
            };
        },
        showNotification: function (message,type = 'info') {
            let self = this;
            this.notification.show = true;
            this.notification.message = message;
            this.notification.type = type;
            setTimeout(function () {
                self.notification.show = false;
            }, 3000);
        },
        filterReset(){
            this.filter.query = '';
            this.filterSubmit(true, null);
        },
        filterSubmit(ignoreCanFilter = false, filter = null) {
            if(filter) {
                this.filter.activeRange = filter.activeRange;
                this.filter.dateFrom = filter.dateFrom;
                this.filter.dateTo = filter.dateTo;
            }
            let self = this;
            if (!this.canFilter && !ignoreCanFilter)
                return false;
            this.loading = true;
            this.$inertia.get(this.route('redeems'), pickBy({
                query: self.filter.query,
                dateFrom: self.filter.dateFrom,
                dateTo: self.filter.dateTo,
            }), {
                replace: true,
                preserveState: true,
                onSuccess: () => {
                    self.redeems = this.data;
                    self.canFilter = false;
                    self.loading = false;
                }
            })
        },
        redeemCreate: function () {
            this.form.reset();
            $('.modal').modal('hide');
            setTimeout(() => {
                $('#redeemCreate').modal('show');
            }, 300);
            this.select2Options = null;

            this.redeemIsCreating = true;
            this.editingRedeem = null;
        },
        redeemAdd: function () {
            this.form.reset();
            $('.modal').modal('hide');
            setTimeout(() => {
                $('#redeemAdd').modal('show');
            }, 300);
            this.select2Options = null;

            this.redeemIsCreating = true;
            this.editingRedeem = null;
            this.openMultiselect();
        },
        redeemEdit: function (model) {
            let self = this;
            this.populateEditForm(model);
            this.editingRedeem = model;
            this.redeemIsCreating = false;
            this.select2Options = null;

            setTimeout(function(){
                $('#redeemCreate').modal('show');
            }, 200)


        },

        clearDelete: function () {
            this.deleteEvent = null;
        },

        openDeleteModal: function (event = null) {
            this.deleteEvent = event;
            $('#modalDeleteItem').modal('show');
        },

        confirmDelete: function () {
            if(this.deleteEvent === 'multi') {
                this.deleteEvent = null;
                this.multiDelete();
                $('#modalDeleteItem').modal('hide');
                return false;
            }

            if (!this.delete_id && !this.redeemer_delete_id)
                return false;
            let self = this;
            if (this.delete_id) {
                axios.post(this.route('redeems.delete'), {id: this.delete_id, allChain: true})
                    .then(function (response) {
                        self.delete_id = null;
                        $('#modalDeleteItem').modal('hide');
                        Inertia.visit(self.route('redeems') + '?dateFrom=' + self.filter.dateFrom + '&dateTo=' + self.filter.dateTo + '&orderBy=' + self.sort.orderBy + '&desc=' + self.sort.desc, {
                            data: pickBy({
                                query: self.filter.query,
                                dateFrom: self.filter.dateFrom,
                                dateTo: self.filter.dateTo,
                            }),
                            only: ['data'],
                            preserveScroll: true,
                            onSuccess: () => {
                                self.loading = false;
                            }
                        });
                    })
                    .catch(function (error) {
                        self.showNotification(error);
                    });
            }
            else if (this.redeemer_delete_id){
                axios.post(this.route('redeems.setRedeemer'), {id: this.redeemer_delete_id, redeemer: null})
                    .then(function (response) {
                        $('#modalDeleteItem').modal('hide');
                        self.modelUpdate(self.redeemer_delete_id, 'redeemer', null);

                    })
                    .catch(function (error) {
                        self.showNotification(error.response.data.message);
                    });
            }
        },
        redeemDelete: function (id) {
            this.delete_id = id;
            $('#modalDeleteItem').modal('show');
        },
        modelUpdate: function(id, attribute, value){
            this.redeems.forEach((model, index) => {
                if (model.id === id){
                    this.redeems[index][attribute] = value;
                }

            });
        },
        populateEditForm: function (model) {
            this.form.id = model.id;
            this.form.redeemer = model.redeemer;
            this.form.price = model.price;
            this.form.price_comma = model.price_comma;
            this.form.g_number = model.g_number;
            this.form.g_numbers = [];
            this.form.date = model.date_formatted;
            this.form.feedback_received = model.feedback_received;
            this.form.warehouse_return = model.warehouse_return;
        },

        init() {
            let self = this;
            $('#redeemCreate').on('hidden.bs.modal', function (e) {
                self.form.errors = {};
                self.redeemOrders.search = '';
                self.isSubmitting = false;
                //self.$page.props.errors = {};
            });
            $('#redeemAdd').on('hidden.bs.modal', function (e) {
                self.form.errors = {};
                self.redeemOrders.search = '';
                self.isSubmitting = false;
                //self.$page.props.errors = {};
            })

            let box = document.getElementById("redeemAdd").getElementsByClassName("modal-body")[0];

            box.addEventListener('scroll', (e) => {
                const scrollableHeight = box.scrollHeight - box.clientHeight

                if (box.scrollTop >= scrollableHeight) {
                    this.redeemOrdersShowMore()
                }
            })

        },

        tippyTooltips() {
            const typpyInstance = tippy('[data-tippy-content]', {
                touch: true,
                trigger: 'mouseenter focus',
                // trigger: 'click',
                // hideOnClick: false,
                maxWidth: 340,
                //placement: 'bottom',
                arrow: false,
                //zIndex: 7
            });
        },

        onClickOutside() {
            this.multiselectOpen = false;
        },

        openMultiselect() {
            let self = this;
            if (this.multiselectOpen){
                this.multiselectOpen = false;
                return true;
            }


            this.multiselectOpen = true;
            this.redeemOrders.page = 1;

            setTimeout(function(){
                self.$refs.searchInput.focus();
            }, 200)
            this.redeemOrdersShowMore(true);

        },
        redeemOrdersShowMore(isLoading){
            if (this.redeemOrders.request){
                this.cancelTokenSource.cancel();
            }
            if (isLoading)
                this.redeemOrders.isLoading = true;
            else
                this.redeemOrders.isShowMore = true;
            this.cancelTokenSource = axios.CancelToken.source();
            this.redeemOrders.request = true;
            axios.get(this.route('redeems.ordersCustom'), {
                cancelToken: this.cancelTokenSource.token,
                params: pickBy({
                    search: this.redeemOrders.search,
                    page: this.redeemOrders.page,
                    g_number: this.form.g_number ?? null
                })
            }).then((response) => {
                if (this.redeemOrders.page === 1)
                    this.redeemOrders.data = response.data;
                else{
                    this.redeemOrders.data.pagination = response.data.pagination;
                    this.redeemOrders.data.results = this.redeemOrders.data.results.concat(response.data.results);
                }
                if (!this.redeemOrders.data.results.length)
                    this.redeemOrders.noData = true;
                else
                    this.redeemOrders.noData = false;
                this.redeemOrders.page += 1;
                this.redeemOrders.isShowMore = false;
                this.redeemOrders.isLoading = false;
            });
        },
        redeemOrdersSearch(){
            let self = this;
            if (this.redeemOrders.search.length === 0 || this.redeemOrders.search.length > 3){
                this.redeemOrders.page = 1;
                if (this.redeemOrdersSearchTimeoutId)
                    clearTimeout(this.redeemOrdersSearchTimeoutId);
                this.redeemOrdersSearchTimeoutId = setTimeout(function(){
                    self.redeemOrders.page = 1;
                    self.redeemOrdersShowMore(true);
                }, 500);



            }

        },
        copy: function (text) {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            this.showNotification('Успешно скопировано');

        },

        changeFilterSection(filterSection = ''){
            let self = this;
            this.filterSection = filterSection;
            if (filterSection === 'orders'){
                setTimeout(function(){
                    self.openMultiselect();
                }, 200)
                self.form.errors = {};
                // this.$refs.ProductsSelector.openMultiselect(true);
            }
        },

        checkIndeterminate(model) {
            if(this.multiSelectedItems.includes(model.id)){
                this.multiSelectedItems.splice(this.multiSelectedItems.indexOf(model.id), 1);
            }
            else {
                this.multiSelectedItems.push(model.id);
            }
        },

        checkAll() {
            if(this.isAllChecked){
                this.multiSelectedItems = [];
            }
            else {
                this.multiSelectedItems = this.redeems.map((expense) => expense.id);
            }
        },

        multiDelete() {
            axios.post(this.route('redeems.multi.delete'), {redeemsIds: this.multiSelectedItems})
                .then((response) => {
                    this.multiSelectedItems = [];
                    this.filterSubmit(true);
                })
                .catch((error) => {
                    this.showNotification(error);
                });
        },

        jsInit(){
            let self = this;

            $(document).off('hidden.bs.modal', '#redeemCreate');
            $(document).on('hidden.bs.modal', '#redeemCreate', function (e) {
                self.filterSection = '';
            });

            $(document).off('hidden.bs.modal', '#redeemAdd');
            $(document).on('hidden.bs.modal', '#redeemAdd', function (e) {
                self.filterSection = '';
            });
        },
    }
}
</script>
<style>
.product-cost-input.error {
    border-color: #f47171 !important;
}
</style>
