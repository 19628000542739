<template>
    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>1</span>
                <p>Перейдите в личный кабинет Ozon Seller <a href="https://seller.ozon.ru" target="_blank">по этой ссылке</a> (если Вы не авторизованы, авторизуйтесь).</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/1.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>2</span>
                <p>Перейдите на страницу настроек</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/2.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>3</span>
                <p>Перейдите на вкладку Seller API “API ключи”</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/3.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>4</span>
                <p>Нажмите на кнопку “Сгенерировать ключ”. Удаляйте ранее созданный ключ, только если вы уверены, что он нигде больше не используется.</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/4_2.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>5</span>
                <p>Введите название ключа на ваше усмотрение. Выберите тип токена. Нажмите кнопку «Сгенерировать».</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/5.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>6</span>
                <p>Скопируйте созданный ключ и внесите его в поле ниже на этой странице, чтобы подключить API  Ozon Seller к Sellerdata.</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/6.png" width="567" height="300" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'WildberriesApiKeyHelp'
}
</script>
