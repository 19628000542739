<template>
    <div v-if="isShow" class="service-unavailable-widget-wrapper">
        <div class="service-unavailable-widget">
            <div class="service-unavailable-widget-block">
                <div class="service-unavailable-widget-block-inner">
                    <strong>Нет подключения.</strong>
                    <span>Повторное подключение через {{ timerLabel }}</span>
                </div>
            </div>
            <div class="service-unavailable-widget-block">
                <span v-show="retryButtonShown()" @click="resync" class="service-unavailable-widget-button">Повторить сейчас</span>
            </div>
        </div>
    </div>

</template>

<script>

import {pickBy, throttle} from "lodash-es";

export default {
    props: [
        'accountInfo'
    ],
    data: function () {
        return {
            isShow: true,
            isShowRetryButton: false,
            intervalId: null,
            timerLabel: '',
            timerSeconds: 180,
            root: null
        };
    },
    emits: ['loading'],
    methods: {
        resync(){
            let self = this;
            axios.post(this.route('account.resync'), {})
                .then(() => {
                    this.isShow = false;
                    document.body.classList.remove('service-unavailable-padding');
                })
                .catch((error) => {

                });
        },
        startTimer(){
            let self = this;
            let currentTime = Math.floor(Date.now() / 1000);
            let secondsLeft = this.accountInfo.api_unavailable_at + this.timerSeconds - currentTime;

            this.timerLabel = '00:00';
            let minutes = 0;
            let seconds = 0;
            setTimeout(function(){
                document.body.classList.add('service-unavailable-padding');
                self.root = document.documentElement;
                self.root.style.setProperty('--su-height', document.querySelector('.service-unavailable-widget-wrapper').offsetHeight+'px');
            }, 200)

            this.intervalId = setInterval(function(){
                secondsLeft--;
                if (secondsLeft >= 0){
                    minutes = Math.floor(secondsLeft / 60);
                    seconds = secondsLeft - minutes * 60;
                    self.timerLabel = ((minutes < 10) ? '0'+minutes : minutes)+':'+((seconds < 10) ? '0'+seconds : seconds);
                }

                if (secondsLeft <= 0)
                    clearInterval(self.intervalId);
            }, 1000);
        },
        stopTimer(){
            clearInterval(this.intervalId);
            document.body.classList.remove('service-unavailable-padding');
        },
        retryButtonShown(){
            let self = this;
            let isShow = false;
            let currentTime = Math.floor(Date.now() / 1000);
            isShow = this.accountInfo.api_unavailable && this.accountInfo.api_unavailable_retries === 3 && (currentTime - this.accountInfo.api_unavailable_at > this.timerSeconds);
            if (isShow){
                if (document.querySelector('.service-unavailable-widget-wrapper')){
                    document.body.classList.add('service-unavailable-padding');
                    self.root = document.documentElement;
                    self.root.style.setProperty('--su-height', document.querySelector('.service-unavailable-widget-wrapper').offsetHeight+'px');
                }
            }
            return isShow;
        }
    },
    watch: {
        'accountInfo.api_unavailable'(newValue) {
            this.isShow = newValue;
            if (this.isShow){
                this.startTimer();

            }
            else{
                this.stopTimer();

            }
        },
    },
    mounted() {
        this.isShow = this.accountInfo.api_unavailable;
        this.isShowRetryButton = this.retryButtonShown()
    }
}
</script>

<style lang="scss">

.service-unavailable-padding {
    padding-top: var(--su-height);

    .auth_row {
        top: var(--su-height);
    }

    aside.aside {
        top: var(--su-height);
    }

    .dash__board__container {
        padding-top: var(--su-height);
    }
}

.service-unavailable-widget-wrapper {
    text-align: center;
    z-index: 999;
    position: fixed;
    width: 100%;
    color: #222;
    top: 0;
    left: 0;
    box-sizing: border-box;
    pointer-events: none;

    * {
        box-sizing: border-box;
    }
}

.service-unavailable-widget {
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    -webkit-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    color: #222;
    padding: 6px 10px;
    pointer-events: auto;
}

.service-unavailable-widget .service-unavailable-widget-block {
    position:relative;
}

.service-unavailable-widget .service-unavailable-widget-block-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-unavailable-widget .service-unavailable-widget-button {
    text-decoration:underline;
    cursor:pointer;
}

@media (min-width: 768px) {
    .service-unavailable-widget {
        display: flex;
        justify-content: center;
    }

    .service-unavailable-widget .service-unavailable-widget-block-inner {
        display: block;

        span {
            margin-left: 5px;
        }
    }

    .service-unavailable-widget .service-unavailable-widget-button {
        margin-left: 5px;
    }
}

@media (min-width: 1200px) {
    .service-unavailable-widget-wrapper {
        display: flex;
        justify-content: center;
    }

    .service-unavailable-padding {
        padding-top: 0;

        .auth_row {
            top: 0;
        }

        .dash__board__container {
            padding-top: 50px;
        }

        aside.aside {
            top: 0;
        }
    }
}
</style>
