<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="FilterModalWarehouse" tabindex="-1" aria-labelledby="filterModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button v-if="(filterSection === 'products' || filterSection === 'warehouse')" class="modal-back modal-back_show" type="button" aria-label="Back" @click="changeFilterSection('')">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <button v-else class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>

                    <h5 v-if="filterSection === 'products'" class="modal-title" id="filterModalTitle" @click="changeFilterSection('')">Товары</h5>
                    <h5 v-else-if="filterSection === 'warehouse'" class="modal-title" id="filterModalTitle" @click="changeFilterSection('')">Страны</h5>
                    <h5 v-else class="modal-title" id="filterModalTitle">Фильтр</h5>

                    <button v-if="filterSection === 'products'" type="button" class="modal-fullscreen__select-all-btn" @click="selectAll">Выбрать все</button>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div v-show="filterSection === ''" class="filter-list">
                        <button class="filter-list__item" type="button" @click="changeFilterSection('products')">
                            <i class="filter-list__ico fm-calendar"></i>
                            <span class="filter-list__title">Товары</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>
                        <button v-if="warehouseList.length > 1" class="filter-list__item" type="button" @click="changeFilterSection('warehouse')">
                            <i class="filter-list__ico fm-globe"></i>
                            <span v-if="warehouseSelectedLocal.length" class="filter-list__title">Склады: {{ warehouseSelectedLocal.join(', ') }}</span>
                            <span v-else class="filter-list__title">Все склады</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>
                    </div>
                    <div class="filter-products" v-show="filterSection === 'products'">
                        <ProductsSelector
                            ref="ProductsSelector"
                            v-bind:products="productsSelected"
                            v-bind:isInline="true"
                            @changed="selectedProductsChanged"></ProductsSelector>
                    </div>
                    <div class="filter-countries" v-if="warehouseList.length > 1" v-show="filterSection === 'warehouse'">
                        <div class="multi-select">
                            <div class="multi-select__dropdown">
                                <div class="multi-select__dropdown__header">
                                    <i class="multi-select__dropdown__header__ico fm-search"></i>
                                    <input class="multi-select__search" type="text" placeholder="Поиск">
                                </div>
                                <div class="multi-select__dropdown__body">
                                    <div v-for="(item) in warehouseList" class="input">
                                        <div class="input__inner">
                                            <input class="input__checkbox" :checked="warehouseSelectedLocal.includes(item.key)" @change="warehouseChangedEvent(item.key)" :id="'c-mobile-'+item.key" :value="item.key" type="checkbox">
                                            <label class="input__checkbox-label" :for="'c-mobile-'+item.key">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" aria-label="Clear" class="btn-default" @click="clearFilterEmit()" v-show="filterSection === ''">Сбросить</button>
                    <button v-if="(filterSection === 'products' || filterSection === 'warehouse')" class="btn-default btn-regular" @click="changeFilterSection('')">Сохранить</button>
                    <button v-else @click="apply" data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Фильтровать</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ProductsSelector from '@/Components/Products/ProductsSelector'
import DashboardDateRanges from '@/Components/Dashboard/DashboardDateRanges'

export default {
    components: {
        ProductsSelector,
        DashboardDateRanges
    },
    props: [
        'query',
        'productsSelected',
        'warehouseSelectedElements',
        'warehouseList',
    ],
    emits: [
        'apply',
        'selectedProductsChanged',
        'selectedWarehouse',
        'filterClear'
    ],
    data() {
        return {
            filterSection: '',
            productsSelectedLocal: [],
            warehouseSelectedLocal: [],
        };
    },
    methods: {
        apply(){
            this.$emit('selectedWarehouse', this.warehouseSelectedLocal);
            this.$emit('selectedProductsChanged', this.productsSelectedLocal);
            this.$emit('apply');
        },
        selectAll(){
            this.$refs.ProductsSelector.selectAllAction();
        },
        clearFilterEmit(){
            this.$emit('filterClear');
        },
        // warehouseChangedEvent(item){
        //     if (this.warehouseSelectedLocal.includes(item)) {
        //         this.warehouseSelectedLocal = this.warehouseSelectedLocal.filter(i => i !== item);
        //     } else {
        //         this.warehouseSelectedLocal.push(item);
        //     }
        // },
        selectedProductsChanged(data)
        {
            this.productsSelectedLocal = data;
        },
        changeFilterSection(filterSection = ''){
            this.filterSection = filterSection;
            if (filterSection === 'products'){
                this.$refs.ProductsSelector.openMultiselect(true);
                this.$refs.ProductsSelector.mobileEventAdd('FilterModalWarehouse');

            }else {
                this.$refs.ProductsSelector.destroyMobileEventAdd();
            }
        },
        jsInit(){
            let self = this;

            if (typeof window.isDashboardFilterModalJsMounted == 'undefined'){

                let clickHandler = 'click';

                if('ontouchstart' in document.documentElement) {
                    clickHandler = 'touchend';
                }

                console.log('Mounted: isDashboardFilterModalJsMounted');

                window.isDashboardFilterModalJsMounted = true;
            }
        },
    },
    mounted() {
        this.jsInit();
        this.productsSelectedLocal = this.productsSelected;
        this.warehouseSelectedLocal = this.warehouseSelectedElements;
    }
}
</script>
