<template>
    <Head title="Dashboard"/>

    <BreezeAuthenticatedLayout :tab="tab" @loading="loadingData" @setAccountInfo="setAccountInfo"
                               @toggleHeaderTab="toggleHeaderTab">
        <template #header-title>
            <h1 class="page_title">{{ __("global.hint.dashboard.title") }}</h1>
        </template>

        <DashboardCharts
            :currentMonthRange="currentMonthRange"
            :currentRangeSet="currentRangeSet"
            :dateRangeSets="dateRangeSetsChart"
            :products="products"
            :dateFrom="dateFrom"
            :dateFirstEvent="dateFirstEvent"
            :dateTo="dateTo"
            :filterCountries="filterCountries"
            :externalDataLoading="externalDataLoading"
            :import-off="true"
            v-if="tab === 'chart'"
        />

        <DashboardPeriods
            :currentMonthRange="currentMonthRange"
            :currentRangeSet="currentRangeSet"
            :dateRangeSets="dateRangeSetsMain"
            :products="products"
            :dateFrom="dateFrom"
            :dateFirstEvent="dateFirstEvent"
            :dateTo="dateTo"
            :filterCountries="filterCountries"
            :externalDataLoading="externalDataLoading"
            v-if="tab === 'grid'"
            :import-off="true"
        />

    </BreezeAuthenticatedLayout>

</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import BreezeDashboardInfo from '@/Components/Dashboard/DashboardInfo.vue'
import DashboardCharts from '@/Components/Dashboard/DashboardCharts.vue'
import DashboardVideoModal from '@/Components/Dashboard/DashboardVideoModal.vue'
import DashboardFilterModal from '@/Components/Dashboard/DashboardFilterModal.vue'
import DashboardFilterRangesModal from '@/Components/Dashboard/DashboardFilterRangesModal.vue'
import DashboardDateRanges from '@/Components/Dashboard/DashboardDateRanges.vue'
import SummaryPeriodItem from '@/Components/Dashboard/SummaryPeriodItem.vue'
import ProductsCostModal from '@/Components/Products/ProductsCostModal.vue'
import {Head} from '@inertiajs/inertia-vue3';
import DashboardProductsTable from "@/Components/Dashboard/DashboardProductsTable";
import DashboardOrdersTable from "@/Components/Dashboard/DashboardOrdersTable";
import DashboardGroupedProductsTable from "@/Components/Dashboard/DashboardGroupedProductsTable";
import DashboardProductFilter from "@/Components/Dashboard/DashboardProductFilter";
import ProductsSelector from "@/Components/Products/ProductsSelector";
import DashboardSummaryModal from "@/Components/Dashboard/DashboardSummaryModal";
import Select2 from 'vue3-select2-component';
import WildBerriesTechPause from '@/Components/Common/WildBerriesTechPause.vue';
import WildBerriesDataNotReady from '@/Components/Common/WildBerriesDataNotReady';
import TransactionSummary from '@/Components/Dashboard/TransactionSummary.vue'
import DropFileInput from '@/Components/Common/DropFileInput.vue';
import {store} from '@/store'
import DashboardChart from "@/Components/Dashboard/DashboardChart.vue";
import DashboardPeriods from "@/Components/Dashboard/DashboardPeriods.vue";

export default {
    components: {
        DashboardCharts,
        DashboardPeriods,
        DashboardChart,
        WildBerriesTechPause,
        WildBerriesDataNotReady,
        DashboardProductFilter,
        DashboardProductsTable,
        DashboardOrdersTable,
        DashboardGroupedProductsTable,
        BreezeAuthenticatedLayout,
        BreezeDashboardInfo,
        DashboardVideoModal,
        DashboardFilterModal,
        DashboardFilterRangesModal,
        DashboardDateRanges,
        ProductsCostModal,
        ProductsSelector,
        DashboardSummaryModal,
        Head,
        Select2,
        SummaryPeriodItem,
        TransactionSummary,
        DropFileInput
    },
    props: [
        'currentMonthRange',
        'currentRangeSet',
        'dateRangeSets',
        'products',
        'dataByPeriods',
        'dateFrom',
        'dateFirstEvent',
        'dateTo',
        'filterCountries'
    ],
    data() {

        return {
            dateRangeSetsMain: [],
            dateRangeSetsChart: [],
            tab: '',
            externalDataLoading: true,
            store
        };
    },
    computed: {
        isKeyLegacy: function () {
            return this.$page.props.auth.data.status === 0;
        },
        isTechPause: function () {
            return !this.isKeyLegacy && this.techPauseTime;
        },
        isDataNotReady: function () {
            return !this.loadingPeriods && !this.isTechPause && !this.loading && !this.reportLoaded;
        },
        techPauseTime: function () {
            const now = moment().tz('Europe/Moscow');
        },
    },
    beforeMount() {
        this.dateRangeSetsMain = this.dateRangeSets.slice(0, 7);
        this.dateRangeSetsChart = this.dateRangeSets.slice(7);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if(urlParams.get('tab') && this.isAvailable('business')) {
            this.toggleHeaderTab(urlParams.get('tab'));
        } else {
            this.toggleHeaderTab('grid');
        }
    },

    methods: {
        setAccountInfo(accountInfo) {
            this.accountInfo = accountInfo;
        },
        loadingData(value) {
            this.externalDataLoading = value;
        },
        toggleHeaderTab(tab = '') {
            this.tab = tab;
        },
        isAvailable(packageType) {
            return this.store.subscription.trial || store.subscription.type === packageType;
        },
    },

    watch: {
        'accountInfo.report_loaded': function (value) {
            this.reportLoaded = !!value;
        },
        /*'accountInfo.orders_updating': function (value) {
            if (value === 0) {
                this.getDataByPeriods(false);
            }
        },
        'accountInfo.orders_updating_first_month': function (value) {
            if (value === 0) {
                this.getDataByPeriods(false);
            }
        },*/
    },
}
</script>
