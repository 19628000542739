<template>
    <Head title="Forgot Password"/>

    <div class="reg_auth_row">
        <div class="logo_block">
            <a :href="$page.props.app.landing_url">
                <img src="/img/logo-auth.svg" alt="logo">
            </a>
        </div>
        <div>
            <Link :href="route('home')" class="auth_link login__link">
                Войти
            </Link>
        </div>
    </div>

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
        {{ status }}
    </div>

    <div class="auth__wrapper">
        <div class="form__outer">
            <div class="auth__block">
                <div class="auth-block-inner">
                    <div class="form-title text-center">
                        <h6 class="size-16 ls-0_03em">Изменить пароль</h6>
                    </div>
                    <form id="changePassForm" @submit.prevent="submit">
                        <BreezeValidationErrors class="mb-4"/>
                        <div class="form__group email__input input_block">
                            <label for="loginEmail" style="display:none;"></label>
                            <BreezeInput id="email" type="text" class="form-control"
                                         :class="{ form_error: v$.form.email.$error || form.errors.email }" v-model="form.email"
                                         @keyup="clearError"
                                         placeholder="Электронная почта" autofocus autocomplete="off"/>
                            <p v-if="v$.form.email.$error" class="invalid-feedback">{{ v$.form.email.$errors[0].$message }}</p>
                            <p v-if="form.errors.email && !v$.form.email.$error" class="invalid-feedback">Введите зарегистрированный email</p>
                        </div>
                        <div class="login_button_outer">
                            <BreezeButton class="submit_button" :class="{ 'opacity-25': form.processing }"
                                          :disabled="form.processing">
                                Подтвердить
                            </BreezeButton>
                        </div>
                    </form>
                </div>
            </div>
            <BreezeFeedback/>
        </div>
    </div>
</template>

<script>
import BreezeButton from '@/Components/Button.vue'
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import useVuelidate from "@vuelidate/core";
import {required, email, helpers} from '@vuelidate/validators'

export default {
    setup: () => ({v$: useVuelidate()}),
    layout: BreezeGuestLayout,

    components: {
        BreezeButton,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link
    },

    props: {
        status: String,
    },
    validations() {
        return {
            form: {
                email: {
                    required: helpers.withMessage('Поле обязательно для заполнения', required),
                    email: helpers.withMessage('Введите email верного формата', email)
                },
            }
        }
    },
    data() {
        return {
            form: this.$inertia.form({
                email: '',
                recaptcha_token: null
            })
        }
    },

    methods: {
        clearError(){
            this.form.errors = [];
        },
        async submit() {
            let self = this;
            const isFormCorrect = await this.v$.$validate()
            grecaptcha.ready(function () {
                grecaptcha.execute(self.$attrs.app.recaptcha_public_key, {action: 'submit'}).then(function (token) {
                    self.form.recaptcha_token = token;
                    if (isFormCorrect) {
                        self.form.post(self.route('password.email'));
                    }
                });
            });
        }
    }
}
</script>
