<template>

    <div class="error-page">
        <img class="error-page__logo" src="/img/logo-basic.svg" width="153" height="24" alt="sellerdata">

        <div class="error-page__body error-page__body_center">
            <div class="error-page__content">
                <h1 class="error-page__title">Технические работы</h1>
                <p>Приносим свои извинения, ведутся технические работы.</p>
            </div>
            <div class="error-page__image">
                <img class="error-page__img" src="/img/503-image.svg" width="290" height="178" alt="">
            </div>
        </div>
    </div>


</template>
