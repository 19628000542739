<template>
    <div class="panels-chart__chart-container">
        <DashboardChartRender
            :query="query"
            :data="data"
            @changed="periodChanged"
            @setSummary="setSummary"
            @setAllPeriods="setAllPeriods"
            @setCurrentPeriod="setCurrentPeriod"
            @setTotalSummary="setTotalSummary"
            @setMonth="setMonth"/>
    </div>
    <div class="panels-chart__chart-summary">
        <div class="panels-chart__summary-nav">
            <div class="header-tabs mx-2">
                <button class="header-tabs__btn" @click="changeTab('day')" :class="currentTab === 'day' ? 'active' : ''" type="button">
                    <span v-if="currentMonth">{{ currentMonth }}</span>
                    <div v-else class="loading-placeholder" role="status">
                        <span class="visually-hidden">Загрузка...</span>
                    </div>
                </button>
            </div>
            <div class="header-tabs mx-2">
                <button class="header-tabs__btn" @click="changeTab('all')" :class="currentTab === 'all' ? 'active' : ''" type="button">
                    <span>Весь период</span>
                </button>
            </div>
        </div>
        <TransactionSummary :summary="currentSummary" v-if="currentTab === 'day'"/>
        <TransactionSummary :summary="totalSummary" v-if="currentTab === 'all'"/>
    </div>
</template>

<script>
import TransactionSummary from "@/Components/Dashboard/TransactionSummary.vue";
import DashboardChartRender from "@/Components/Dashboard/DashboardChartRender.vue";

export default {
    components: {DashboardChartRender, TransactionSummary},
    props: [
        'data',
        'query',
        'changed'
    ],
    data() {
        return {
            currentTab: 'day',
            currentSummary: [],
            totalSummary: [],
            allPeriodsData: [],
            currentPeriod: [],
            currentMonth: '',
        };
    },
    methods: {
        periodChanged(data) {
            this.changeTab('day');
            this.$emit('changed', data);
        },

        setSummary(data) {
            this.currentSummary = data;
        },
        setTotalSummary(data) {
            this.totalSummary = data;
        },

        setMonth(month) {
            this.currentMonth = month;
        },
        changeTab(tab) {
            if(tab === 'all') {
                this.$emit('changed', this.allPeriodsData);
            }
            else {
                this.$emit('changed', this.currentPeriod);
            }
            this.currentTab = tab;
        },
        setAllPeriods(data){
            this.allPeriodsData = data;
        },
        setCurrentPeriod(data){
            this.currentPeriod = data;
        }
    },
    mounted() {

    }
}
</script>
