<template>
    <div
        class="popover_block"
        v-tippy="{ content: apiKeyScopes}"
    >
        <a
            href="#"
            :class="{popover_link_button: true, success: account.status, error: !account.status}"
            @click.prevent="showModal = true"
        >
            <span class="popover_link_button__ico" :class="statusIcon"></span>
            <span class="button-title">{{ __('global.settings.marketplace.title.' + account.data_source) }}</span>
        </a>
    </div>
    <Modal
        v-if="showModal"
        @close="close"
    >
        <template #title>
            <h5 class="modal-title">Введите новый ключ API</h5>
        </template>
        <template #body>
            <ApiKeyField
                ref="ozonApiKeyField"
                v-if="account.data_source === 'ozon'"
                slug="ozon"
                marketplace="Ozon"
                :show-label="false"
                :submit-error="errors.ozon"
                @field-changed="fieldChanged"
            />
            <ApiKeyField
                ref="wildberriesApiKeyField"
                v-if="account.data_source === 'wildberries'"
                slug="wildberries"
                marketplace="Wildberries"
                :show-label="false"
                :submit-error="errors.wildberries"
                @field-changed="fieldChanged"
            />
        </template>
        <template #buttons>
            <button class="btn-default btn-cancel" type="button" @click.prevent="close">Отменить</button>
            <button
                :disabled="!formPopulated || isFormProcessing"
                class="btn-default btn-regular position-relative"
                @click.prevent="save"
            >
                Сохранить
                <span class="btn-loader" v-show="isFormProcessing">
                    <span class="btn-loader__inner">
                        <span class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                    </span>
                </span>
            </button>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/Components/Common/Modal.vue'
import ApiKeyForm from '@/Components/Account/ApiKeyForm.vue'
import ApiKeyField from '@/Components/Account/ApiKeyField.vue'
import apiKeyValidation from '@/Mixins/apiKeyValidation'

export default {
    name: 'AccountApiKey',
    components: { ApiKeyField, ApiKeyForm, Modal },
    mixins: [apiKeyValidation],
    props: {
        account: {
            type: Object,
            required: true
        }
    },
    emits: ['notification', 'reload'],
    data () {
        return {
            showModal: false,
            isFormProcessing: false,
            apiKeys: {
                ozon: '',
                wildberries: '',
            },
        }
    },
    computed: {
        statusIcon() {
            if (!this.account.status) {
                return 'fm-alert-circle';
            }

            if ((this.account.orders_updating || this.account.orders_updating_first_month || this.account.products_updating || this.account.in_updating)) {
                return 'ring_loader';
            }

            return 'fm-check';
        },
        formPopulated() {
            return this.apiKeys.ozon !== '' || this.apiKeys.wildberries !== '';
        },
        apiKeyScopes() {
            if (this.account.info) {
                let features = [];

                const totalFeatures = {'statistics': 'Статистика', 'advert': 'Реклама', 'contentanalytics': 'Аналитика'};

                const activeFeatures =  this.account.info.features.filter((feature) => {
                    return feature === 'statistics' || feature === 'advert' || feature === 'contentanalytics'
                });

                for (const [key, value] of Object.entries(totalFeatures)) {
                    if (activeFeatures.includes(key)) {
                        features.push(`<b>${value}</b>`);
                    } else {
                        features.push(value);
                    }
                }

                return features.join(', ');
            }

            return '';
        }
    },
    methods: {
        save() {
            this.isFormProcessing = true;

            if (!this.isValid()) {
                this.isFormProcessing = false
                return;
            }

            axios.post(this.route('settings.account-change-key'), {
                accountId: this.account.id,
                apiKey: this.apiKeys[this.account.data_source]
            }).then(() => {
                this.showModal = false;
                this.$emit('notification', 'success', 'Ключ API успешно изменен');
                this.$emit('reload');
                this.close();
            }).catch((error) => {
                this.errors[this.account.data_source] = error.response.data.errors.apiKey[0];
            }).finally(() => {
                this.isFormProcessing = false;
            });
        },
        close() {
            if (this.isFormProcessing) {
                return;
            }

            this.showModal = false;
        },
        fieldChanged() {
            this.resetErrors();

            this.apiKeys[this.account.data_source] = this.account.data_source === 'ozon' ? this.$refs.ozonApiKeyField.getValue() : this.$refs.wildberriesApiKeyField.getValue();
        }
    }
}
</script>
