export const helpers = {
    methods: {
        __copy(message) {
            const el = document.createElement('textarea');
            el.value = message;
            el.setAttribute('readonly', '');

            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

            el.select();

            //el.click();
            //el.focus();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            try{
                this.showNotification('Успешно скопировано');
            }
            catch (e){
                //throw e;
            }
            return false;

        },
        __isInRange(rangeToCheck, range) {
            const { dateFrom: checkFrom, dateTo: checkTo } = rangeToCheck;
            const { dateFrom: currentFrom, dateTo: currentTo } = range;

            const checkFromDate = moment(checkFrom);
            const checkToDate = moment(checkTo);
            const currentFromDate = moment(currentFrom);
            const currentToDate = moment(currentTo);

            return checkFromDate.isSameOrAfter(currentFromDate) && checkToDate.isSameOrBefore(currentToDate);
        },
        __dataToSelect2(obj){
            let res = [];
            if (typeof obj === 'undefined')
                return res;
            $.each(obj, function(index, value){
                res.push({id: index, text: value});
            })
            return res;
        },
    }
}
