<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="productsFilterModal" tabindex="-1" aria-labelledby="productsFilterModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>

                    <h5 class="modal-title" id="filterModalTitle">Товары</h5>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div class="filter-products">
                        <ProductsSelector
                            ref="ProductsSelector"
                            v-bind:products="productsSelected"
                            v-bind:isInline="true"
                            @changed="selectedProductsChanged"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" aria-label="Close" class="btn-default">Закрыть</button>
                    <button @click="apply" data-dismiss="modal" aria-label="Close" class="btn-default btn-regular">Фильтровать</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductsSelector from '@/Components/Products/ProductsSelector'

export default {
    components: {
        ProductsSelector
    },
    props: [
        'query',
        'productsSelected',
    ],
    emits: [
        'apply'
    ],
    data() {
        return {
            filterSection: '',
        };
    },
    methods: {
        apply(){
            this.$emit('apply');
        },
        selectedProductsChanged(data)
        {
            this.productsSelectedLocal = data.productIds;
            this.$emit('selectedProductsChanged', data);
        },

        changeFilterSection(filterSection = ''){
            this.filterSection = filterSection;
            if (filterSection === 'products'){
                this.$refs.ProductsSelector.openMultiselect(true);
                this.$refs.ProductsSelector.mobileEventAdd('productsFilterModal');
            }else {
                this.$refs.ProductsSelector.destroyMobileEventAdd();
            }

        },

        jsInit(){
            let self = this;
            $(document).off('show.bs.modal', '#productsFilterModal');
            $(document).on('show.bs.modal', '#productsFilterModal', function (e) {
                self.$refs.ProductsSelector.openMultiselect(true);
            })
        }
    },
    mounted() {
        this.productsSelectedLocal = this.productsSelected;
        this.jsInit();
    }
}
</script>
