<template>
    <Head title="Admin"/>

    <BreezeAdminLayout :accounting="accounting">
        <template #header-title>
            <h1 class="page_title">Пользователи</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">

                <div class="filter-panel">
                    <div class="filter-panel__inner">
                        <div class="input input_combo input_combo_2">
                            <div class="input__inner">
                                <input id="selectSearchProductRedeems"
                                       v-model="filterQuery.query"
                                       class="input__text-input"
                                       placeholder="Поиск" type="text"
                                       v-on:keyup.enter="searchStart()">
                                <div class="input__actions">
                                    <button v-if="filterQuery.query" aria-label="Сбросить" class="input__btn fm-x"
                                            type="button"
                                            @click="clearFilterSearch()"></button>
                                    <button aria-label="Искать" class="input__btn fm-search" type="button"
                                            @click="searchStart()"></button>
                                </div>
                            </div>
                        </div>
                        <button class="btn-default btn-regular" type="button" @click="downloadExport()">Экспорт</button>

                        <button
                            :class="{active: (filterQuery.dateFrom || filterQuery.dateTo)}"
                            class="dashboard-nav__btn dashboard-nav__btn_products"
                            data-target="#filterDateModal" data-toggle="modal" type="button">
                            <i class="fm-calendar"></i>
                        </button>
                    </div>
                </div>


                <div class="table_outer">
                    <div class="expenses-wrapper">
                        <table v-if="transactions.length > 0"
                               class="table table-fixed table-fw-widget partners-table table-condensed dashboard-table">
                            <thead>
                            <tr>
                                <th class="nowrap" style="text-align: left; width: 20px">
                                    ID
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Дата
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Email
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Фирма
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Сумма
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Карта
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Подписка
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Статус оплаты
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Тип транзакции
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Период
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    INN
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Действия
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="trans in transactions">
                                <td>{{ trans.id }}</td>
                                <td>{{ trans.generated_at }}</td>
                                <td>{{ trans.user_subscription.user.email }}</td>
                                <td>{{ trans.company_name }}</td>
                                <td>{{ trans.amount }}₽</td>
                                <td>{{ trans.original_data ? trans.original_data[0].Pan : '—' }}</td>
                                <td>{{ trans.pack }}</td>
                                <td>{{ trans.status }}</td>
                                <td>{{ trans.payment_type === 'invoice' ? 'Банковский перевод' : 'Оплата картой' }}</td>
                                <td>{{ trans.period === 'yearly' ? 'Год' : 'Месяц' }}</td>
                                <td>{{ trans.inn ?? '—' }}</td>
                                <td><button @click="approveActivation(trans)" v-if="trans.status === 'pending'" class="bg-green-600 cursor-pointer block rounded px-3 py-1 text-white">Активировать</button></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-last-section">
                    <span v-for="link in links">
                        <a v-if="link.url || link.label === '...'" :class="link.active ? 'text-gray-700' : ''"
                           :href="link.url"
                           class="mx-2" @click="selectPage(link.url)" v-html="link.label">
                        </a>
                    </span>
                </div>
            </div>
        </div>

        <div id="filterDateModal" aria-hidden="true" aria-labelledby="filterDateModalTitle"
             class="modal modal-fullscreen modal-fullscreen_filter-modal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-label="Back" class="modal-back" data-dismiss="modal" type="button">
                            <i class="fm-arrow-left"></i>
                        </button>

                        <h5 id="redeemsFilterModalTitle" class="modal-title">Фильтр</h5>

                        <button aria-label="Close" class="modal-close" data-dismiss="modal" type="button">
                            <i class="fm-close"></i>
                        </button>
                    </div>
                    <div class="modal-body scrollbar">
                        <label class="mb-3 block" for="">Выбор по статусу</label>
                        <select v-model="filterQuery.status" class="mb-6">
                            <option v-for="item in optionsStatus"
                                    :checked="item.id === filterQuery.status"
                                    :value="item.id">
                                {{ item.text }}
                            </option>
                        </select>
                        <FilterDateRanges
                            v-bind:current="current"
                            v-bind:dateFrom="dateFromSelected"
                            v-bind:dateTo="dateToSelected"
                            v-bind:datesAreDefault="false"
                            v-bind:periods="dateRangeSets"
                            v-bind:styleType="2"
                            @changedDates="datesChanged"></FilterDateRanges>

                    </div>
                    <div class="modal-footer">
                        <button aria-label="Close" class="btn-default btn-regular" data-dismiss="modal" @click="apply">
                            Фильтровать
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            v-if="showModal"
            @click="close"
        >
            <template #title>
                <h5 class="modal-title">Вы уверенны что хотите активировать эту подписку ?</h5>
            </template>
            <template #body>

            </template>
            <template #buttons>
                <button class="btn-default btn-cancel" type="button" @click.prevent="close">Отменить</button>
                <button
                    class="btn-default btn-regular position-relative"
                    @click.prevent="activate"
                >
                    Активировать
                </button>
            </template>
        </Modal>

    </BreezeAdminLayout>

</template>

<script>
import BreezeAdminLayout from '@/Layouts/Admin.vue'
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, minLength} from '@vuelidate/validators'
import BreezeButton from '@/Components/Button.vue'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import Currency from "@/Components/Common/Currency.vue";
import ProductsSelector from "@/Components/Products/ProductsSelector.vue";
import {Inertia} from "@inertiajs/inertia";
import {pickBy, throttle} from "lodash-es";
import UserRow from "@/Pages/Admin/Users/Components/UserRow.vue";
import FilterDateRanges from "@/Pages/Admin/Common/FilterDateRanges.vue";
import Input from "@/Components/Input.vue";
import Pagination from "@/Components/Common/Pagination.vue";
import Select2 from 'vue3-select2-component'
import Modal from "@/Components/Common/Modal.vue";
import ApiKeyField from "@/Components/Account/ApiKeyField.vue";

export default {

    components: {
        ApiKeyField, Modal,
        Pagination,
        Input,
        FilterDateRanges,
        UserRow,
        ProductsSelector,
        Currency,
        BreezeAdminLayout,
        BreezeButton,
        BreezeCheckbox,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link,
        Select2
    },

    props: [
        'transactions',
        'query',
        'dateFrom',
        'dateTo',
        'links',
        'setActiveKeys',
        'setActiveSub',
        'subType',
        'datesAreDefault',
        'dateFromSelected',
        'dateToSelected',
        'pageSelected',
        'total',
        'status',
        'accounting',
    ],

    data() {
        let dateFormat = 'Y-m-d';
        let dateRangeSets = [
            {
                id: 'custom',
                title: 'Произвольный период',
                dateFrom: null,
                dateTo: null,
            }
        ];
        return {
            optionsStatus: [
                {id: '', text: '-- Все --'},
                {id: 'pending', text: 'В процессе'},
                {id: 'success', text: 'Успешные'},
                {id: 'failed', text: 'Неуспешные'},
                {id: 'refunded', text: 'Возвраты'},
            ],
            current: 'custom',
            filter: {
                dateFrom: null,
                dateTo: null,
                status: '',
                query: '',
            },
            filterQuery: {
                dateFrom: null,
                dateTo: null,
                status: '',
                query: '',
            },
            dateRangeSets: dateRangeSets,
            showModal: false,
            selectedTransactions: {},
        }
    },

    computed: {
        isFilterWorks() {
            return this.dateFrom || this.dateTo;
        }
    },

    beforeMount() {
        this.filterQuery = {
            dateFrom: this.dateFromSelected ?? null,
            dateTo: this.dateToSelected ?? null,
            status: this.status ?? '',
            query: this.query ?? '',
        };
    },

    methods: {
        selectPage(url) {
            if (url === '...') {
                return;
            }
            this.$inertia.get(url);
        },
        downloadExport() {
            let url = '/accounting/transactions/export';

            axios.post(url, {
                dateFrom: this.filterQuery.dateFrom,
                dateTo: this.filterQuery.dateTo,
                status: this.filterQuery.status,
            }, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'report.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {

            });
        },
        clearFilter() {
            this.filterQuery.dateFrom = null;
            this.filterQuery.dateTo = null;
            this.filterQuery.status = '';
            this.filterQuery.query = '';
            this.apply();
        },
        clearFilterSearch() {
            this.filterQuery.query = '';
            this.searchStart();
        },
        searchStart(value) {
            this.$inertia.get(route('admin.transaction.index'), pickBy({
                query: this.filterQuery.query,
            }));
        },
        datesChanged(data) {
            this.filterQuery.dateFrom = data.dateFrom;
            this.filterQuery.dateTo = data.dateTo;
        },
        apply() {
            this.filter = this.filterQuery;

            this.$inertia.get(route('admin.transaction.index'), pickBy({
                dateFrom: this.filter.dateFrom,
                dateTo: this.filter.dateTo,
                status: this.filter.status,
                query: this.filter.query,
            }));
        },

        approveActivation(transaction){
            this.showModal = true;
            this.selectedTransactions = transaction;
        },

        close(){
            this.showModal = false;
        },

        activate(){
            this.showModal = false;
            axios.post(route('accounting.users.subscription.activate'), {
                subscription_id: this.selectedTransactions.id
            }).then(response => {
                this.$inertia.reload();
                alert('Подписка успешно активирована');
            }).catch(error => {
                console.log(error);
            });
        },
    }
}
</script>
