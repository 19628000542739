<template>
    <Head title="Reset Password" />

    <div class="reg_auth_row">
      <div class="logo_block">
        <a :href="$page.props.app.landing_url">
          <img src="/img/logo-auth.svg" alt="logo">
        </a>
      </div>
      <div>
        <Link :href="route('home')" class="auth_link login__link">
          Войти
        </Link>
      </div>
    </div>

    <div class="auth__wrapper">
      <div class="form__outer">
        <div class="auth__block">
          <div class="auth-block-inner">
            <div class="form-title text-center">
              <h6 class="size-16 ls-0_03em">Изменить пароль</h6>
            </div>
            <form id="changePassForm" @submit.prevent="submit">
                <BreezeValidationErrors class="mb-4" />


<!--                <BreezeInput id="email" type="hidden" class="form-control" :class="{ form_error: v$.form.email.$error }" v-model="form.email" placeholder="Электронная почта" autofocus autocomplete="username" />-->


                <div class="form__group password__input input_block">
                    <BreezeLabel for="password" value="Password" style="display:none;" />
                    <BreezeInput id="password" type="password" :class="{ form_error: v$.form.password.$error }" class="form-control" v-model="form.password" placeholder="Пароль" autocomplete="new-password" />
                </div>

                <div class="form__group password__input input_block">
                    <BreezeLabel for="password_confirmation" value="Confirm Password" style="display:none;" />
                    <BreezeInput id="password_confirmation" :class="{ form_error: v$.form.password_confirmation.$error }" type="password" class="form-control" v-model="form.password_confirmation" placeholder="Подтвердите пароль" autocomplete="new-password" />
                </div>

                <div class="login_button_outer">
                    <BreezeButton class="submit_button" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Подтвердить
                    </BreezeButton>
                </div>
            </form>
          </div>
        </div>
        <BreezeFeedback />
      </div>
    </div>
</template>

<script>
import BreezeButton from '@/Components/Button.vue'
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import { Head, Link } from '@inertiajs/inertia-vue3';
import { email, required, minLength, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    layout: BreezeGuestLayout,

    components: {
        BreezeButton,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link
    },

    props: {
        //email: String,
        token: String,
    },

    data() {
        return {
            form: this.$inertia.form({
                token: this.token,
                //email: this.email,
                password: '',
                password_confirmation: '',
            })
        }
    },
    validations () {
      return {
        form: {
          //email: { required, email },
          password: {
            required,
            minLengthValue: minLength(6),
          },
          password_confirmation: {
            required,
            minLengthValue: minLength(6),
            sameAsPassword: sameAs(this.form.password)
          },
        }
      }
    },
    methods: {
        async submit() {
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
              this.form.post(this.route('password.update'), {
                onFinish: () => this.form.reset('password', 'password_confirmation'),
              })
            }
        }
    },
    setup: () => ({ v$: useVuelidate() }),
}
</script>
