<template>
    <div v-show="show" class="loading-wrapper">
        <div class="loading-wrapper__inner">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'show',
    ],
}
</script>
