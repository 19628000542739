<template>
    <Head title="Admin"/>

    <BreezeAdminLayout>
        <template #header-title>
            <h1 class="page_title">Пользователи</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">

                <div class="filter-panel">
                    <div class="filter-panel__inner">
                        <div class="input input_combo input_combo_2">
                            <div class="input__inner">
                                <input id="selectSearchProductRedeems"
                                       v-model="filterQuery.query"
                                       class="input__text-input"
                                       placeholder="Поиск" type="text"
                                       v-on:keyup.enter="searchStart()">
                                <div class="input__actions">
                                    <button v-if="filterQuery.query" aria-label="Сбросить" class="input__btn fm-x"
                                            type="button"
                                            @click="clearFilterSearch()"></button>
                                    <button aria-label="Искать" class="input__btn fm-search" type="button"
                                            @click="searchStart()"></button>
                                </div>
                            </div>
                        </div>

                        <button class="btn-default btn-regular" type="button" @click="downloadExport()">Экспорт
                            email-ов
                        </button>
                        <button :class="isFilterWorks ? 'btn-regular' : ''" class="btn-default ml-4" type="button"
                                @click="clearFilter()">
                            Офистить фильтр
                        </button>

                        <button
                            :class="{active: (filterQuery.query || filterQuery.dateFrom || filterQuery.dateTo || filterQuery.setActiveKeys)}"
                            class="dashboard-nav__btn dashboard-nav__btn_products"
                            data-target="#filterDateModal" data-toggle="modal" type="button">
                            <i class="fm-calendar"></i>
                        </button>
                    </div>
                </div>


                <div class="table_outer">
                    <div class="expenses-wrapper">
                        <table v-if="users"
                               class="table table-fixed table-fw-widget partners-table table-condensed dashboard-table">
                            <thead>
                            <tr>
                                <th class="nowrap" style="text-align: left;">
                                    Почта
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Подписка
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Статус подписки
                                </th>
                                <th style="text-align: left;">
                                    Окончания/продления подписки
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Последний вход
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Действие
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(user, key) in users" :key="key">
                                <UserRow :user="user" :hasInvoice="hasInvoice" @subscription-activated="subActivated"/>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-last-section">
                    <span v-for="link in links">
                        <a v-if="link.url || link.label === '...'" :class="link.active ? 'text-gray-700' : ''"
                           :href="link.url"
                           class="mx-2" @click="selectPage(link.url)" v-html="link.label">
                        </a>
                    </span>
                </div>
            </div>
        </div>

        <div id="filterDateModal" aria-hidden="true" aria-labelledby="filterDateModalTitle"
             class="modal modal-fullscreen modal-fullscreen_filter-modal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-label="Back" class="modal-back" data-dismiss="modal" type="button">
                            <i class="fm-arrow-left"></i>
                        </button>

                        <h5 id="redeemsFilterModalTitle" class="modal-title">Фильтр</h5>

                        <button aria-label="Close" class="modal-close" data-dismiss="modal" type="button">
                            <i class="fm-close"></i>
                        </button>
                    </div>
                    <div class="modal-body scrollbar">
                        <div class="mb-4">
                            <label class="block" for="">Выбор по ключам</label>
                            <select v-model="filterQuery.setActiveKeys">
                                <option v-for="item in optionsActiveKeys"
                                        :checked="item.id === this.filterQuery.setActiveKeys"
                                        :value="item.id">
                                    {{ item.text }}
                                </option>
                            </select>

                            <label class="mt-3 block" for="">Выбор по статусу подписки</label>
                            <select v-model="filterQuery.setActiveSub">
                                <option v-for="item in optionsActiveSubscriptions"
                                        :checked="item.id === this.filterQuery.setActiveSub"
                                        :value="item.id">
                                    {{ item.text }}
                                </option>
                            </select>


                            <label class="mt-3 block" for="">Тип подписки</label>
                            <select v-model="filterQuery.subType">
                                <option v-for="item in optionsSubscription"
                                        :checked="item.id === this.filterQuery.subType"
                                        :value="item.id">
                                    {{ item.text }}
                                </option>
                            </select>

                            <div class="mt-4">
                            <input class="input__checkbox" type="checkbox"
                                   @change="setActiveInvoice"
                                   :checked="hasInvoice"
                                   id="hasInvoice">
                            <label class="input__checkbox-label"
                                   for="hasInvoice">Есть неоплаченый Invoice</label>
                            </div>
                        </div>
                        <div class="text-center mb-1">Последние входы в диапазоне</div>
                        <FilterDateRanges
                            v-bind:current="current"
                            v-bind:dateFrom="dateFromSelected"
                            v-bind:dateTo="dateToSelected"
                            v-bind:datesAreDefault="false"
                            v-bind:periods="dateRangeSets"
                            v-bind:styleType="2"
                            @changedDates="datesChanged"></FilterDateRanges>

                    </div>
                    <div class="modal-footer">
                        <button aria-label="Close" class="btn-default btn-regular" data-dismiss="modal" @click="apply">
                            Фильтровать
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </BreezeAdminLayout>

</template>

<script>
import BreezeAdminLayout from '@/Layouts/Admin.vue'
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, minLength} from '@vuelidate/validators'
import BreezeButton from '@/Components/Button.vue'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import Currency from "@/Components/Common/Currency.vue";
import ProductsSelector from "@/Components/Products/ProductsSelector.vue";
import {Inertia} from "@inertiajs/inertia";
import {pickBy, throttle} from "lodash-es";
import UserRow from "@/Pages/Admin/Users/Components/UserRow.vue";
import FilterDateRanges from "@/Pages/Admin/Common/FilterDateRanges.vue";
import Input from "@/Components/Input.vue";
import Pagination from "@/Components/Common/Pagination.vue";
import Select2 from 'vue3-select2-component'
import Checkbox from "@/Components/Checkbox.vue";

export default {

    components: {
        Checkbox,
        Pagination,
        Input,
        FilterDateRanges,
        UserRow,
        ProductsSelector,
        Currency,
        BreezeAdminLayout,
        BreezeButton,
        BreezeCheckbox,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link,
        Select2
    },

    props: [
        'users',
        'query',
        'dateFrom',
        'dateTo',
        'links',
        'setActiveKeys',
        'setActiveSub',
        'subType',
        'datesAreDefault',
        'dateFromSelected',
        'dateToSelected',
        'pageSelected',
        'total',
        'hasInvoice'
    ],

    data() {
        let dateFormat = 'Y-m-d';
        let dateRangeSets = [
            {
                id: 'custom',
                title: 'Произвольный период',
                dateFrom: null,
                dateTo: null
            }
        ];

        let filter = {
            query: '',
            setActiveKeys: '',
            setActiveSub: '',
            dateFrom: null,
            dateTo: null,
            subType: '',
            hasInvoice: false,
        };

        return {
            optionsActiveKeys: [
                {id: '', text: '-- Все --'},
                {id: 'active', text: 'Активные ключи'},
                {id: 'deactivated', text: 'Неактивные ключи'}
            ],
            optionsActiveSubscriptions: [
                {id: '', text: '-- Все --'},
                {id: 'active_rebill_not_trial', text: 'Активные подписки'},
                {id: 'active_trial', text: 'Пробные подписки'},
                {id: 'active_no_rebill_no_trial_no_next_payment', text: 'Бесконечные'},
                {id: 'active_and_free', text: 'Бесплатные'},
                {id: 'active_no_rebill', text: 'Отмененные подписки'},
                {id: 'not_active', text: 'Неактивные подписки'}
            ],
            optionsSubscription: [
                {id: '', text: '-- Все --'},
                {id: 'free', text: 'Free'},
                {id: 'standard', text: 'Standart'},
                {id: 'business', text: 'Business'}
            ],
            current: 'custom',
            filter: filter,
            filterQuery: filter,
            dateRangeSets: dateRangeSets,
        }
    },

    computed: {
        isFilterWorks() {
            return this.query || this.setActiveKeys;
        }
    },

    beforeMount() {
        this.filterQuery = {
            query: this.query,
            setActiveKeys: this.setActiveKeys ?? '',
            setActiveSub: this.setActiveSub ?? '',
            dateFrom: this.dateFromSelected ?? null,
            dateTo: this.dateToSelected ?? null,
            subType: this.subType ?? '',
            hasInvoice: this.hasInvoice ?? false,
        };
    },

    methods: {
        selectPage(url) {
            if (url === '...') {
                return;
            }
            this.$inertia.get(url);
        },
        subActivated() {
            alert('Подписка активирована');
            this.$inertia.reload();
        },
        setActiveInvoice() {
            this.filterQuery.hasInvoice = !this.filterQuery.hasInvoice;
        },
        downloadExport() {
            let url = '/admin/users/export';

            axios.post(url, {
                query: this.filterQuery.query,
                setActiveKeys: this.filterQuery.setActiveKeys,
                dateFrom: this.filterQuery.dateFrom,
                dateTo: this.filterQuery.dateTo,
            }, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'email_report.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {

            });
        },
        clearFilterSearch() {
            this.filterQuery.query = '';
            this.searchStart();
        },
        clearFilter() {
            this.filterQuery.query = '';
            this.filterQuery.dateFrom = null;
            this.filterQuery.dateTo = null;
            this.filterQuery.setActiveKeys = false;
            this.filterQuery.setActiveSub = '';
            this.filterQuery.subType = '';
            this.filterQuery.hasInvoice = false;
            this.apply();
        },
        searchStart(value) {
            this.$inertia.get(route('admin.users.index'), pickBy({
                query: this.filterQuery.query,
            }));
        },
        datesChanged(data) {
            this.filterQuery.dateFrom = data.dateFrom;
            this.filterQuery.dateTo = data.dateTo;
            // this.queryFilter = data;
        },
        apply() {
            this.filter = this.filterQuery;

            this.$inertia.get(route('admin.users.index'), pickBy({
                query: this.filter.query,
                setActiveKeys: this.filter.setActiveKeys,
                activeSub: this.filter.setActiveSub,
                dateFrom: this.filter.dateFrom,
                dateTo: this.filter.dateTo,
                subType: this.filter.subType,
                hasInvoice: this.filter.hasInvoice,
            }));
        },
        setActiveKeys() {
            this.filterQuery.setActiveKeys = !this.filterQuery.setActiveKeys
        }
    }
}
</script>
