<template>
    <Tippy content="Копировать">
        <button class="copy-btn" @click="copyToClipboard">
            <span class="fm-copy"/>
        </button>
    </Tippy>
</template>

<script>
import { Tippy } from 'vue-tippy'

export default {
    name: 'CopyButton',
    components: { Tippy },
    props: {
        textToCopy: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isClipboardApi: false,
        }
    },
    mounted() {
        this.isClipboardApi = !!navigator.clipboard;
    },
    methods: {
        copyToClipboard() {
            if (this.isClipboardApi) {
                navigator.clipboard.writeText(this.textToCopy);
                return;
            }

            const textarea = document.createElement('textarea');
            textarea.textContent = this.textToCopy;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
        }
    },
}
</script>
