<template>
    <h3 class="settings__block__title">Эл. почта</h3>

    <div class="size-15 over__ellipse">{{ $page.props.auth.user.email }}</div>

    <div v-if="!$page.props.auth.data.emailConfirmed" class="settings__confirm-section">
        <h4 class="settings__block__title">Подтверждение по электронной почте</h4>
        <div class="size-15 pb-1">Электронная почта не подтверждена</div>
        <p>Пожалуйста, подтвердите свой адрес электронной почты, чтобы мы могли отправлять вам важные сообщения,
            касающиеся вашей учетной записи Seller Data.</p>
        <button type="button" @click="resendEmail" :class="{disabled: isResendingEmail}"
                class="btn-default btn-regular">Отправить повторно
        </button>
    </div>

    <div class="input">
        <div class="input__inner input__inner_info">
            <input class="input__checkbox" type="checkbox" id="s-updates_subscription" @change="saveSettings"
                   v-model="form.updates_subscription">
            <label class="input__checkbox-label" for="s-updates_subscription">
                Получать почтовую рассылку от sellerdata
            </label>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: this.$inertia.form({
                title: '',
                'updates_subscription': false
            }),
            isResendingEmail: false,
        }
    },
    mounted() {
        this.resendEmailTimerProcess();
    },
    methods: {
        resendEmail: function () {
            if (this.isResendingEmail)
                return false;
            let self = this;
            self.isResendingEmail = true;
            axios.get(this.route('sendConfirmEmail'), {})
                .then(function (response) {
                    localStorage.setItem('isResendingEmail', moment().unix());
                    self.resendEmailTimerProcess();
                })
                .catch(function (error) {
                    self.showNotification(error.response.data.message);
                });
        },
        resendEmailTimerProcess: function () {
            let self = this;
            let timerStartedAt = Number(localStorage.getItem('isResendingEmail'));
            if (!timerStartedAt)
                return false;
            let timerEndedAt = timerStartedAt + 60;
            let secondsLeft = timerEndedAt - moment().unix();
            if (secondsLeft > 0) {
                this.isResendingEmail = true;
                this.resendingTimer = setTimeout(function () {
                    self.isResendingEmail = false;
                    localStorage.removeItem('isResendingEmail');
                }, secondsLeft * 1000);
            } else
                localStorage.removeItem('isResendingEmail');

        },
        saveSettings() {
            let self = this;
            this.form.post(this.route('settings.update'), {
                preserveScroll: true,
                onSuccess: function (response) {
                    self.showNotification('Данные сохранены');
                },
                onError: function (errors) {
                    //if (errors.modal)
                    //    $('#errorKey').modal();
                },
            });
        },
        showNotification(message, type = 'info') {
            this.$emit('showNotification', message, type);
        },
    }
};
</script>
