<template>
    <div :class="cssClasses">
        {{ printableTitle }} <span v-if="showWarning" class="fm-alert-triangle" v-tippy="'Наименование товара будет отображено как только товар будет опубликован'"/>
    </div>
</template>

<script>
export default {
    name: 'ProductTitle',
    props: {
        'title': String,
        'cssClasses': {
            default: 'item__title'
        },
        'warning': {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showWarning() {
            return this.warning && this.title === null;
        },
        printableTitle() {
            return this.title ?? 'Без наименования';
        }
    }
}
</script>
