<template>
    <div v-if="loadingData" class="loading-wrapper h-full">
        <div class="loading-wrapper__inner h-full">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
    <div class="h-full-on-mobile">
        <canvas id="chart" class="cursor-pointer"></canvas>
    </div>
</template>

<script>
import TransactionSummary from '@/Components/Dashboard/TransactionSummary.vue'
import Chart from 'chart.js/auto'

export default {
    components: {TransactionSummary},
    props: [
        'data'
    ],
    emits: [
        'setSummary',
        'setTotalSummary',
        'setMonth',
        'changed',
        'setAllPeriods',
        'setCurrentPeriod'
    ],
    data() {
        return {
            dataByPeriods: [],
            dataSold: [],
            dataSales: [],
            dataProfit: [],
            dataRefound: [],
            dataOrders: [],
            dataDates: [],
            dataTooltip: [],
            loadingData: true,
            maxQuntity: 0,
            minQuntity: 0,
            maxSum: 0,
            minSum: 0,

        };
    },
    methods: {

        setAllPeriods() {
            this.$emit('changed', this.dataByPeriods[this.dataByPeriods.length - 1].period);
        },

        reloadData() {
            this.dataByPeriods = this.data;

            this.dataSales = this.dataSold = this.dataProfit = this.dataRefound = this.dataOrders = this.dataDates = [];

            let dataSales = [], dataProfit = [], dataRefound = [], dataOrders = [], dataDates = [], dataTooltip = [],
                dataSold = [];

            this.$emit('setSummary', this.dataByPeriods[0].summary);
            this.$emit('setTotalSummary', this.dataByPeriods[this.dataByPeriods.length - 1].summary);
            this.$emit('setAllPeriods', this.dataByPeriods[this.dataByPeriods.length - 1].period);
            this.$emit('setMonth', this.dataByPeriods[0].period.title_chart ?? this.dataByPeriods[0].period.title);
            this.$emit('setCurrentPeriod', this.dataByPeriods[0].period);

            this.dataByPeriods = this.dataByPeriods.reverse();

            this.dataByPeriods.forEach((item, index) => {
                if (index !== 0) {
                    dataSales.push(item.summary.realSalesAmount);
                    dataProfit.push(item.summary.profit);
                    dataRefound.push(item.summary.refundQuantity);
                    dataSold.push(item.summary.quantity);
                    dataOrders.push(item.summary.ordersQuantity);
                    dataDates.push(item.period.title);
                    if (item.period.title_chart !== '') {
                        dataTooltip.push(item.period.title_chart);
                    }
                }
            });

            this.dataSales = dataSales;
            this.dataProfit = dataProfit;
            this.dataRefound = dataRefound;
            this.dataOrders = dataOrders;
            this.dataSold = dataSold;
            this.dataDates = dataDates;
            this.dataTooltip = dataTooltip;

            this.maxSum = Math.max.apply(null, this.dataSales) > Math.max.apply(null, this.dataProfit) ? Math.max.apply(null, this.dataSales) : Math.max.apply(null, this.dataProfit);
            this.minSum = Math.min.apply(null, this.dataSales) < Math.min.apply(null, this.dataProfit) ? Math.min.apply(null, this.dataSales) : Math.min.apply(null, this.dataProfit);

            this.maxQuntity = Math.max.apply(null, this.dataOrders) > Math.max.apply(null, this.dataSold) ? Math.max.apply(null, this.dataOrders) : Math.max.apply(null, this.dataSold);
            this.minQuntity = Math.min.apply(null, this.dataOrders) < Math.min.apply(null, this.dataSold) ? Math.min.apply(null, this.dataOrders) : Math.min.apply(null, this.dataSold);

            let ignoreMin = false;
            if (this.minQuntity >= 0 && this.minSum >= 0) {
                ignoreMin = true;
                this.minQuntity = this.minSum = 0;
            }
            let dividerPlus = 1;
            let dividerMinus = 1;
            if (this.maxSum > Math.abs(this.minSum)) {
                dividerMinus = Math.floor(this.maxSum / Math.abs(this.minSum));
                if (dividerMinus === 0 || dividerMinus === 1) {
                    dividerMinus = 1;
                } else if (dividerMinus === 2) {
                    dividerMinus = 1.2;
                } else {
                    dividerMinus = 1.4;
                }
            } else if (this.maxSum < Math.abs(this.minSum)) {
                dividerPlus = Math.floor(Math.abs(this.minSum) / this.maxSum);
                if (dividerPlus === 0 || dividerPlus === 1) {
                    dividerPlus = 1;
                } else if (dividerPlus === 2) {
                    dividerPlus = 1.2;
                } else {
                    dividerPlus = 1.4;
                }
            }

            if (this.maxSum === 0 && this.minSum === 0) {
                this.maxSum = 10000;
                this.minSum = -10000;
            } else {
                if (this.maxSum > Math.abs(this.minSum)) {
                    if (!ignoreMin) {
                        this.minSum = -Math.ceil(Math.round(Math.ceil(this.maxSum * 1.1 / parseInt('1'+'0'.repeat(String(Math.ceil(this.maxSum)).length-1)))) * parseInt('1'+'0'.repeat(String(Math.ceil(this.maxSum)).length-1)) / dividerMinus);
                    }
                    this.maxSum = Math.round(Math.ceil(this.maxSum * 1.1 / parseInt('1'+'0'.repeat(String(Math.ceil(this.maxSum)).length-1)))) * parseInt('1'+'0'.repeat(String(Math.ceil(this.maxSum)).length-1));
                } else {
                    this.maxSum = Math.ceil(Math.round(Math.ceil(Math.abs(this.minSum) * 1.1 / parseInt('1'+'0'.repeat(String(Math.abs(Math.ceil(this.minSum))).length-1)))) * parseInt('1'+'0'.repeat(String(Math.abs(Math.ceil(this.minSum))).length-1)) / dividerPlus);
                    if (!ignoreMin) {
                        this.minSum = -Math.round(Math.ceil(Math.abs(this.minSum) * 1.1 / parseInt('1'+'0'.repeat(String(Math.abs(Math.ceil(this.minSum))).length-1)))) * parseInt('1'+'0'.repeat(String(Math.abs(Math.ceil(this.minSum))).length-1));
                    }
                }

            }

            if (this.maxQuntity === 0 && this.minQuntity === 0) {
                this.maxQuntity = Math.ceil(100 / dividerPlus);
                this.minQuntity = Math.ceil(-100 / dividerMinus);
            } else {
                if (this.maxQuntity < 50) {
                    this.maxQuntity = Math.ceil(100 / dividerPlus);
                    if (!ignoreMin) {
                        this.minQuntity = Math.ceil(-100 / dividerMinus);
                    }
                } else {
                    if (this.maxSum > Math.abs(this.minSum)) {
                        if (!ignoreMin) {
                            this.minQuntity = Math.ceil((-Math.round(this.maxQuntity * 2 / parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) * parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) / dividerMinus);
                        }
                        this.maxQuntity = Math.ceil((Math.round(this.maxQuntity * 2 / parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) * parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) / dividerPlus);
                    } else {
                        if (!ignoreMin) {
                            this.minQuntity = Math.ceil((-Math.round(this.maxQuntity * 2 / parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) * parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) / dividerMinus);
                        }
                        this.maxQuntity = Math.ceil((Math.round(this.maxQuntity * 2 / parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) * parseInt('1'+'0'.repeat(String(this.maxQuntity).length-1))) / dividerPlus);
                    }
                }
            }

            this.init();
            this.loadingData = false;
        },
        isMobileDevice() {
            if ($(window).width() > 960) {
                return true;
            }
            return false;
        },
        init() {
            function randomScalingFactor() {
                return Math.round(Math.random() * 10000);
            }

            var barChartData = {
                //labels: this.dataDates,
                datasets: [
                    {
                        type: 'line',
                        label: 'Заказано',
                        backgroundColor: '#4E91FF',
                        borderColor: '#4E91FF',
                        borderWidth: 2.5,
                        data: this.dataOrders,
                        yAxisID: 'y1',
                        pointRadius: 5,
                        pointHoverRadius: 5,
                        pointBackgroundColor: '#fff',
                        pointHoverBackgroundColor: '#4E91FF',
                        pointBorderWidth: 2.5,
                        pointHoverBorderWidth: 2.5
                    },
                    {
                        type: 'line',
                        label: 'Продано',
                        backgroundColor: '#51b578',
                        borderColor: '#51b578',
                        borderWidth: 2.5,
                        data: this.dataSold,
                        yAxisID: 'y1',
                        pointRadius: 5,
                        pointHoverRadius: 5,
                        pointBackgroundColor: '#fff',
                        pointHoverBackgroundColor: '#4E91FF',
                        pointBorderWidth: 2.5,
                        pointHoverBorderWidth: 2.5
                    },
                    {
                        type: 'line',
                        label: 'Возвращено',
                        backgroundColor: '#F55244',
                        borderColor: '#F55244',
                        borderWidth: 2.5,
                        data: this.dataRefound,
                        yAxisID: 'y1',
                        pointRadius: 5,
                        pointHoverRadius: 5,
                        pointBackgroundColor: '#fff',
                        pointHoverBackgroundColor: '#F55244',
                        pointBorderWidth: 2.5,
                        pointHoverBorderWidth: 2.5
                    },
                    {
                        type: 'bar',
                        label: 'Продажи',
                        backgroundColor: '#99ACF6',
                        data: this.dataSales,
                        yAxisID: 'y',
                    }, {
                        type: 'bar',
                        label: 'Прибыль',
                        backgroundColor: '#EF93A0',
                        data: this.dataProfit,
                        axis: 'y',
                    },

                ]
            };

            const ctx = document.getElementById('chart').getContext("2d");
            let self = this;
            const chart = new Chart(ctx, {
                type: 'bar',
                data: barChartData,
                options: {
                    'onClick': function (evt, item) {
                        self.$emit('setSummary', self.dataByPeriods[item[0].index + 1].summary);
                        self.$emit('setMonth', self.dataByPeriods[item[0].index + 1].period.title_chart ?? self.dataByPeriods[item[0].index].period.title);
                        self.$emit('changed', self.dataByPeriods[item[0].index + 1].period);
                        self.$emit('setCurrentPeriod', self.dataByPeriods[item[0].index + 1].period);
                    },
                    title: {
                        display: true,
                        text: "Chart.js Bar Chart - Stacked"
                    },
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                        line: {
                            tension: 0
                        }
                    },
                    stacked: false,
                    scales: {
                        y: {
                            beginAtZero: false,
                            type: 'linear',
                            display: true,
                            position: 'left',
                            min: this.minSum,
                            max: this.maxSum,
                            grid: {
                                display: false,
                            },
                            ticks: {
                                callback: function (val, index) {
                                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ₽';
                                },
                            }
                        },
                        y1: {
                            gridLines: {
                                drawBorder: false,
                            },
                            beginAtZero: false,
                            type: 'linear',
                            display: true,
                            position: 'right',
                            min: this.minQuntity,
                            max: this.maxQuntity,
                        },
                        x: {
                            type: 'category',
                            labels: this.dataDates,
                            grid: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 0,
                                minRotation: 0,
                                display: this.isMobileDevice(),
                            }
                        }

                    },
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                pointStyleWidth: 13,
                                boxHeight: 9,
                                boxWidth: 9,
                            }
                        },
                        tooltip: {
                            backgroundColor: '#fff',
                            titleColor: '#232323',
                            bodyColor: '#555',
                            footerColor: '#555',
                            usePointStyle: true,
                            borderWidth: 2,
                            callbacks: {
                                title: function (tooltipItem) {
                                    return self.dataTooltip[tooltipItem[0].dataIndex] ?? '';
                                },
                            }
                        }
                    }
                }
            });
        }
    },
    mounted() {


    },

    watch: {
        data: function (value) {
            this.reloadData();
        },
    },
}
</script>
