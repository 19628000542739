<template>
    <Teleport to="body">
        <div class="modal" style="display: block" @click.self.prevent="close" :class="modalClasses">
            <div class="modal-dialog modal-dialog-centered" :class="dialogClasses">
                <div class="modal-content">
                    <div class="modal-header">
                        <slot name="header">
                            <button class="modal-back" type="button" aria-label="Back" @click.prevent="close">
                                <i class="fm-arrow-left"></i>
                            </button>
                            <div class="modal-header__inner">
                                <slot name="title"></slot>
                            </div>
                            <button type="button" class="modal-close" aria-label="Close" @click.prevent="close">
                                <i class="fm-close"></i>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body" :class="bodyClasses">
                        <slot name="body"></slot>
                    </div>
                    <slot name="footer" v-if="$slots['footer'] || $slots['buttons']">
                        <div class="modal-footer" :class="footerClasses">
                            <slot name="buttons"></slot>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show"></div>
    </Teleport>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        bodyClasses: {
            type: String,
            default: ''
        },
        modalClasses: {
            type: String,
            default: 'modal-basic'
        },
        dialogClasses: {
            type: String,
            default: ''
        },
        footerClasses: {
            type: String,
            default: ''
        },
    },
    mounted () {
        document.querySelector('body').classList.add('modal-open');
        setTimeout(() => {
            this.tippyTooltips();
        }, 100);
    },
    unmounted () {
        document.querySelector('body').classList.remove('modal-open')
    },
    emits: ['close'],
    methods: {
        close () {
            this.$emit('close');
        },
        tippyTooltips() {
            const typpyInstance = tippy('[data-tippy-content]', {
                touch: true,
                trigger: 'mouseenter focus',
                // trigger: 'click',
                // hideOnClick: false,
                maxWidth: 340,
                //placement: 'bottom',
                arrow: false,
                //zIndex: 7
            });
        },
    }
}
</script>
