<template>
    <div class="info-banner-outer warning">
        <div class="info-banner">
            <div class="icon__block"><span class="fm-icon-info"></span></div>
            <div class="info-banner-text">
                <p>
                    Данные по еженедельному отчету от Wildberries за {{dateFrom}} - {{dateTo}} еще не получены,
                    поэтому расчет за данный период производится на основе оперативных данных.
                    Как только отчет будет получен, данное уведомление пропадет.
                    Если вы хотите получать уведомления о получении еженедельных отчетов, активируйте данную опцию в настройках sellerdata
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WildBerriesDataNotReady",

    computed:{
        dateFrom(){
            return moment().subtract(7, 'days').startOf('isoWeek').format('DD.MM.YYYY');
        },
        dateTo(){
            return moment().subtract(7, 'days').endOf('isoWeek').format('DD.MM.YYYY');
        }
    }
}
</script>


