export default {
    data() {
        return {
            errors: {
                global: '',
                ozon: '',
                wildberries: '',
            }
        }
    },
    methods: {
        resetErrors() {
            this.errors = {
                global: '',
                ozon: '',
                wildberries: '',
            }
        },
        isValid() {
            if (this.apiKeys.ozon === '' && this.apiKeys.wildberries === '') {
                this.errors.global = 'Вы должны добавить хотя бы один API-ключ';
                return false;
            }

            if (this.apiKeys.wildberries && !this.isJWT(this.apiKeys.wildberries)) {
                this.errors.wildberries = 'Неверный формат ключа';
                return false;
            }

            if (this.apiKeys.ozon && this.apiKeys.ozon.length > 1) {
                const ozonCredentials = this.apiKeys.ozon.split(':');

                if (ozonCredentials.length !== 2) {
                    this.errors.ozon = 'Необходимо ввести Client ID и API key';
                    return false;
                }

                if (!ozonCredentials[0]) {
                    this.errors.ozon = 'Необходимо ввести Client ID';
                    return false;
                }

                if (!this.isValidUUID(ozonCredentials[1])) {
                    this.errors.ozon = 'Неверный формат API key';
                    return false;
                }
            }

            return true;
        },
        isJWT(jwt) {
            return String(jwt)
                .match(/eyJ[A-Za-z0-9-_]+\.eyJ[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/]*/g);
        },
        isValidUUID(uuid) {
            const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
            return uuidRegex.test(uuid);
        },
    }
}
