require('./bootstrap');

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { translations } from './Mixins/translations'
import numeral from 'numeral'
import { helpers } from './Mixins/helpers'
import { filterDateRangeSets } from './Mixins/filterDateRangeSets'
import moment from 'moment'

import VueTippy from 'vue-tippy'
import 'tippy.js/themes/light.css'

import { register } from 'swiper/element/bundle'

register();

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

numeral.register('locale', 'cur_RUB', {
    delimiters: { thousands: ' ', decimal: ',' },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    currency: { symbol: '₽' }
});

numeral.register('locale', 'cur_AZN', {
    delimiters: { thousands: ' ', decimal: ',' },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    currency: { symbol: '₼' }
});

numeral.register('locale', 'cur_AMD', {
    delimiters: { thousands: ' ', decimal: ',' },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    currency: { symbol: '֏' }
});

numeral.register('locale', 'cur_BYN', {
    delimiters: { thousands: ' ', decimal: ',' },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    currency: { symbol: 'Br' }
});

numeral.register('locale', 'cur_KZT', {
    delimiters: { thousands: ' ', decimal: ',' },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    currency: { symbol: '₸' }
});

numeral.register('locale', 'cur_KGS', {
    delimiters: { thousands: ' ', decimal: ',' },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    currency: { symbol: 'с' }
});


numeral.register('locale', 'sys_per', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    currency: {
        symbol: '%'
    }
});

moment.locale('ru');

const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        const myApp = createApp({
            render: () => h(app, props)
        })
            .use(plugin)
            .use(VueTippy,{
                defaultProps: {
                    duration: [500, 0],
                    placement: 'bottom',
                    allowHTML: true,
                    //interactive: true,
                },
            })
            .mixin({ methods: { route } })
            .mixin(translations)
            .mixin(helpers)
            .mixin(filterDateRangeSets);

        myApp.directive('click-outside', {
            mounted(el, binding, vnode) {
                el.clickOutsideEvent = function(event) {

                     /**
                      * Hack for .prev, .next - is for datepicker calendar buttons
                      */

                    if (!(el === event.target || el.contains(event.target) || $(event.target).parent().hasClass('prev') || $(event.target).parent().hasClass('next'))) {
                        //console.log(el, event.target)
                        binding.value(event, el);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent);
            },
            unmounted(el) {
                document.body.removeEventListener('click', el.clickOutsideEvent);
            }
        });

        const vm = myApp.mount(el);
        vm.$inertia.on('navigate', (event) => {
            //$('.modal').modal('hide');
            //$('.modal-backdrop').remove();
            //$('body').removeClass('modal-open');
            // if (event.detail.closeModal) {
            //     $('#myModal').modal('hide');
            // }
        });
        return vm;
    },
});


InertiaProgress.init({ color: '#4B5563' });
