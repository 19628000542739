<template>
    <div class="dashboard_panel dashboard_panel_dashboard">
        <div class="dashboard-nav">
            <ul class="dashboard-nav__list">
                <li v-for="(item, key, index) in summary" :key="key" class="dashboard-nav__item">
                    <button class="dashboard-nav__link" type="button" :class="{active: currentIndex === index}" @click="goToSlide(index)">{{ item.title }}</button>
                </li>
            </ul>

            <div class="dashboard-nav__btn-container">
                <div class="dashboard-nav__search">
                    <div class="search_outer desktop_select">
                        <ProductsSelector
                            style="margin-bottom:0;"
                            :products="filter.products"
                            @changed="selectProducts"
                        />
                    </div>

                    <div class="top_filter_row__column" v-if="warehouseList.length > 1">
                        <div class="dropdown">
                            <button type="button" class="index_select_button" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">

                                <span v-if="wareHouseSelected.length == 0">Все склады</span>
                                <span v-if="wareHouseSelected.length > 0">Склады: {{ wareHouseSelected.length }}</span>
                                <span class="fm-chevron-down"></span>
                            </button>
                            <div class="dropdown-menu dropdown-menu_form scrollbar">
                                <form>
                                    <div class="input" v-for="(item) in warehouseList">
                                        <div class="input__inner">
                                            <input class="input__checkbox" type="checkbox"
                                                   :checked="wareHouseSelected.includes(item.key)"
                                                   :id="'w-c-'+item.key" @change="addToSelected(item.key)">
                                            <label class="input__checkbox-label"
                                                   :for="'w-c-'+item.key">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="filter_button top_filter_row__column">
                        <button
                            @click="applyFilter()"
                            :disabled="!selectionChanged"
                            type="button"
                            :class="{'active': selectionChanged}"
                            class="index_filter_button"
                            data-element=".filter_button"
                            data-toggle="false"
                        ><span class="fm-filter"></span> Фильтр</button>
                    </div>

                    <div class="dashboard-nav__clear-filter-button"
                         v-if="ifFilterNotEmpty">
                        <button class="btn-default" type="button" @click="filterClear"><span
                            class="fm-x"></span>Сбросить
                        </button>
                    </div>
                </div>

                <button
                    class="dashboard-nav__btn dashboard-nav__btn_mob"
                    :class="{'active': filter.products.length > 0}"
                    type="button"
                    data-toggle="modal"
                    data-target="#FilterModalWarehouse"
                >
                    <i class="fm-filter"></i>
                </button>
            </div>
        </div>

        <div class="panels-carousel-wrapper slider-panels slider-panels_warehouse">
            <swiper-container init="false" class="panels-carousel">
                <swiper-slide v-for="(item, key) in summary">
                    <div class="day_item_outer">
                        <div class="day_block_item">
                            <div class="block_item_head">
                                <div class="block_item_title">{{ item.title }}</div>
                                <div v-show="!loading && !item.stable" class="block_item_head__tooltip" v-tippy="__(`hints.${key}.alert`)">
                                    <span class="fm-alert-triangle"></span>
                                </div>
                            </div>
                            <div v-if="loading" class="loading-wrapper">
                                <div class="loading-wrapper__inner">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="item_content">
                                    <div class="item_content_inner" v-tippy="__(`hints.${key}.warehouse`)">
                                        <div class="item_content_title">Склад</div>
                                        <div class="item_content_data">
                                            <Currency :value="item.warehouse"/>
                                        </div>
                                    </div>
                                    <div class="item_content_inner" v-tippy="__(`hints.${key}.available`)">
                                        <div class="item_content_title">Доступно</div>
                                        <div class="item_content_data">
                                            <Currency :value="item.available"/>
                                        </div>
                                    </div>
                                    <div class="item_content_inner" v-tippy="__(`hints.${key}.reserve`)">
                                        <div class="item_content_title">Резерв</div>
                                        <div class="item_content_data">
                                            <Currency :value="item.reserved"/>
                                        </div>
                                    </div>
                                    <div class="item_content_inner" v-tippy="__(`hints.${key}.intermediateWarehouse`)">
                                        <div class="item_content_title">Промеж. склад</div>
                                        <div class="item_content_data">
                                            <Currency :value="item.intermediateWarehouse"/>
                                        </div>
                                    </div>
                                    <div class="item_content_inner" v-tippy="__(`hints.${key}.purchase`)">
                                        <div class="item_content_title">Закупка</div>
                                        <div class="item_content_data">
                                            <Currency :value="item.purchase"/>
                                        </div>
                                    </div>
                                    <div class="item_content_inner" v-tippy="__(`hints.${key}.total`)">
                                        <div class="item_content_title">Итого</div>
                                        <div class="item_content_data">
                                            <Currency :value="item.warehouse + item.intermediateWarehouse + item.purchase"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>

        <Teleport to="body">
            <ProductsFilterModal
                ref="productsFilterModal-warehouse"
                :query="{}"
                :productsSelected="filter.products"
                @apply="applyFilter()"
                @selectedProductsChanged="selectProducts"
            />
            <WarehouseFilterModal
                ref="FilterModalWarehouse"
                :query="{}"
                :productsSelected="this.currentProductSelection"
                :warehouseSelectedElements="this.filter.warehouses"
                :warehouseList="warehouseList"
                @apply="applyFilter()"
                @filterClear="filterClear"
                @selectedWarehouse="selectWarehouses"
                @selectedProductsChanged="selectProducts"/>
        </Teleport>

    </div>
</template>

<script>
import Currency from '@/Components/Common/Currency.vue'
import ProductsSelector from '@/Components/Products/ProductsSelector.vue'
import _ from 'lodash'
import ProductsFilterModal from '@/Components/Warehouse/ProductsFilterModal.vue'
import WarehouseFilterModal from "@/Components/Warehouse/WarehouseFilterModal.vue";

export default {
    name: 'WarehouseSummary',
    components: {WarehouseFilterModal, ProductsFilterModal, ProductsSelector, Currency },
    props: {
        externalDataLoading: Boolean,
        filter: Object,
        warehouseList: Array,
    },
    emits: ['filter-submitted','filter-clear'],
    computed: {
        loading() {
            return this.externalDataLoading || this.summaryLoading;
        },
        selectionChanged() {
            return !_.isEqual(this.currentProductSelection, this.filter.products) || !_.isEqual(this.wareHouseSelected, this.filter.warehouses);
        },
        ifFilterNotEmpty() {
            return this.currentProductSelection.length > 0 || this.wareHouseSelected.length > 0;
        },
    },
    data() {
        return {
            summaryLoading: true,
            productsList: [],
            wareHouseSelected:[],
            slider: null,
            currentIndex: 0,
            summary: {
                primeCost: {
                    title: 'Себестоимость',
                    stable: 0,
                    warehouse: 0,
                    available: 0,
                    reserved: 0,
                    intermediateWarehouse: 0,
                    purchase: 0,
                    total: 0,
                },
                profit: {
                    title: 'Потенциальные продажи',
                    stable: 1,
                    warehouse: 0,
                    available: 0,
                    reserved: 0,
                    intermediateWarehouse: 0,
                    purchase: 0,
                    total: 0,
                },
                potential: {
                    title: 'Потенциальная прибыль',
                    stable: 1,
                    warehouse: 0,
                    available: 0,
                    reserved: 0,
                    intermediateWarehouse: 0,
                    purchase: 0,
                    total: 0,
                },
            },
            currentProductSelection: [],
        }
    },
    methods: {
        addToSelected(item) {
            if (this.wareHouseSelected.includes(item)) {
                this.wareHouseSelected = this.wareHouseSelected.filter(i => i !== item);
            } else {
                this.wareHouseSelected.push(item);
            }
        },
        applyFilter(){
            this.filter.products = this.currentProductSelection;
            this.filter.warehouses = this.wareHouseSelected;
            this.$emit('filter-submitted', this.filter);
        },
        filterClear(){
            this.currentProductSelection = [];
            this.wareHouseSelected = [];
            this.$emit('filter-clear');
        },
        goToSlide(index) {
            this.slider.slideTo(index);
        },
        selectProducts({productIds}) {
            this.currentProductSelection = productIds
        },
        selectWarehouses(warehouseIds) {
            this.wareHouseSelected = warehouseIds
        },
        load() {
            this.summaryLoading = true;
            axios.get('/warehouse/summary', {
                params: {
                    products: this.filter.products,
                    warehouses: this.filter.warehouses,
                }
            })
                .then(response => {
                    this.summary = _.merge(this.summary, response.data);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.summaryLoading = false
            });
        },
        carousel() {
            // swiper element
            const swiperContainer = document.querySelector('swiper-container');

            // swiper parameters
            const swiperParams = {
                slidesPerView: 1,
                centerInsufficientSlides: true,

                breakpoints: {
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 10,
                        slidesOffsetBefore: 20,
                        slidesOffsetAfter: 20,
                    },
                    1081: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                    },
                },

                injectStyles: [
                    `
                    @media (min-width: 1081px) {
                        :host(.panels-carousel) .swiper {
                            overflow: visible;
                        }
                    }

                    ::slotted(swiper-slide) {
                        height: auto;
                    }
                    `,
                ],
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperContainer, swiperParams);

            // and now initialize it
            swiperContainer.initialize();

            swiperContainer.swiper.on('slideChange', (event) => {
                this.currentIndex = event.activeIndex;
            });
            this.slider = swiperContainer.swiper;
        },
    },
    mounted () {
        this.productsList = this.filter.products;
        if (this.slider === null) {
            this.carousel();
        }

        this.wareHouseSelected = this.filter.warehouses ?? [];
        this.currentProductSelection = this.filter.products;
    },
    watch: {
        externalDataLoading: function (val) {
            if (!val) {
                this.load();
            }
        },
    },
}
</script>
