<template>
    <span class="currency">{{formattedValue}}</span>
</template>

<script>
import numeral from 'numeral'
import { store } from '@/store'

export default {
    name: 'Currency',
    props: {
        value: {
            required: true
        },
    },
    data() {
        return {
            store
        }
    },
    computed: {
        formattedValue() {
            if (this.value !== null && !isNaN(this.value)) {
                if (numeral.locales[`cur_${this.store.currency}`]) {
                    numeral.locale(`cur_${this.store.currency}`);
                } else {
                    numeral.locale('cur_rub');
                }

                return numeral(this.value).format('0,0.00 $');
            }

            return '';
        }
    },
}
</script>

<style scoped>

</style>
