<template>
    <div class="day_item_outer" >
        <div
            class="day_block_item"
            :class="currentRange && currentRange.id === item.period.id ? currentClass + ' active': currentClass"
        >
            <button class="day_block_item__period-btn" type="button" aria-label="Choose this period" @click="changePeriod(item.period,currentRange && currentRange.id === item.period.id)"></button>
            <div class="block_item_head">
                <div class="block_item_title">{{ item.period.title }}</div>
                <div v-if="dateFrom !== dateTo" class="block_item_date">{{ dateFrom }} до {{ dateTo }}</div>
                <div v-if="dateFrom === dateTo" class="block_item_date">{{ dateFrom }}</div>
                <div v-if="!item.summary.stable" class="block_item_head__tooltip" v-tippy="`Проверенные данные доступны до ${reportDateEnd}. Данные с ${salesDateStart} основаны на ежедневных отчетах и могут быть откорректированы в дальнейшем`">
                    <span class="fm-icon-attention"></span>
                </div>
            </div>
            <div v-if="loadingPeriods" class="loading-wrapper">
                <div class="loading-wrapper__inner">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div v-else class="item_content">
                <div class="item_content_inner" v-tippy="__('hints.dashboard.realSalesAmountPeriod')">
                    <div class="item_content_title">Продажи</div>
                    <div class="item_content_data">
                        <Currency :value="item.summary.realSalesAmount" /> / {{ item.summary.quantity }} шт.
                    </div>
                </div>
                <div class="item_content_inner" v-tippy="__('hints.dashboard.ordersTotal')">
                    <div class="item_content_title">Заказы</div>
                    <div class="item_content_data">
                        <div v-if="isPeriodOrdersLoading(item.period) && !ignoreLoadingOrders" class="loading-placeholder" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <span v-else><Currency :value="item.summary.ordersTotal"/> / {{ item.summary.ordersQuantity }} шт.</span>
                    </div>
                </div>
                <div class="item_content_inner" v-tippy="__('hints.dashboard.refundsTotal')">
                    <div class="item_content_title">Возвраты</div>
                    <div class="item_content_data">
                        <Currency :value="item.summary.refundsAmount" /> / {{ item.summary.refundQuantity }} шт.
                    </div>
                </div>
                <div class="item_content_inner">
                    <div class="item_content_title">
                        <button
                            class="item_content_title__info fm-icon-attention"
                            type="button"
                            v-if="showPayoutWarning"
                            v-tippy="payoutWarning"
                        />
                        <span v-tippy="__('hints.dashboard.payout')">Сумма выплат</span>
                    </div>
                    <div class="item_content_data"><Currency :value="item.summary.payout"/></div>
                </div>
                <div class="item_content_inner" >
                    <div class="item_content_title">
                        <button
                            class="item_content_title__info fm-icon-attention"
                            type="button"
                            v-if="showProfitWarning"
                            v-tippy="profitWarning"
                        />
                        <span v-tippy="__('hints.dashboard.profit')">Прибыль</span>
                    </div>
                    <div class="item_content_data"><Currency :value="item.summary.profit"/></div>
                </div>
            </div>
            <div v-show="!loadingPeriods" class="block_item_footer">
                <button class="block_item_footer__btn" @click.prevent="showModal = true">Больше</button>
            </div>
        </div>
    </div>

    <Modal
        v-if="showModal"
        @close="showModal = false"
        modalClasses="modal-fullscreen"
    >
        <template #title>
            <h5 class="modal-title">{{ item.period.title }}</h5>
            <div v-if="dateFrom !== dateTo" class="modal-subtitle">{{ dateFrom }} до {{ dateTo }}</div>
            <div v-if="dateFrom === dateTo" class="modal-subtitle">{{ dateFrom }}</div>
        </template>
        <template #body>
            <div class="info-list">
                <TransactionSummary
                    :summary="item.summary"
                    :show-payout-warning="showPayoutWarning"
                    :payout-warning="payoutWarning"
                    :show-profit-warning="showProfitWarning"
                    :profit-warning="profitWarning"
                />
            </div>
        </template>
    </Modal>
</template>

<script>

import Currency from '@/Components/Common/Currency.vue'
import Percent from '@/Components/Common/Percent.vue'
import TransactionSummary from '@/Components/Dashboard/TransactionSummary.vue'
import Modal from '@/Components/Common/Modal.vue'
import moment from 'moment'
import { store } from '@/store'

export default  {
    props: [
        'item',
        'index',
        'currentRange',
        'currentMonthRange',
        'loadingPeriods',
        'ignoreLoadingOrders'
    ],
    components: {
        Modal,
        TransactionSummary,
        Percent,
        Currency,
    },
    emits: ['changed'],
    data() {
        const expensesChangeDate = moment('2024-01-29');

        return {
            classes: ['current_day', 'yesterday', 'current_month', 'past_month'],
            ordersOpened: false,
            showModal: false,
            store,
            expensesChangeDate
        };
    },
    computed: {
        payoutWarning() {
            if (this.expensesChangeDate.isSameOrAfter(this.item.period.dateTo)) {
                return this.__('hints.dashboard.wb.warn.payout_inaccurate_legacy');
            }

            return this.__('hints.dashboard.wb.warn.payout_inaccurate');
        },
        showPayoutWarning() {
            if (this.expensesChangeDate.isSameOrAfter(this.item.period.dateTo)) {
                return !this.loadingPeriods && (this.item.summary.expenses_missing || !this.item.summary.stable);
            }

            return !this.loadingPeriods && !this.item.summary.stable;
        },
        profitWarning() {
            const massages = [];

            if (this.item.summary.inaccurate) {
                massages.push(this.__('hints.dashboard.wb.warn.profit_inaccurate_no_costs'));
            }

            if (!this.item.summary.stable) {
                massages.push(this.__('hints.dashboard.wb.warn.profit_inaccurate_no_expenses'));
            }

            if (this.expensesChangeDate.isSameOrAfter(this.item.period.dateTo) && this.item.summary.expenses_missing) {
                massages.push(this.__('hints.dashboard.wb.warn.profit_inaccurate_no_expenses_legacy'));
            }

            if(!this.store.account.is_campaign_expenses_synced){
                // massages.push(this.__('hints.dashboard.profitAlert'));
            }

            return massages.join('<hr>');
        },
        isSupported: function () {
            return this.store.account.data_source === 'wildberries';
        },
        showProfitWarning() {
            return this.item.summary.inaccurate
                || !this.item.summary.stable
                || (this.expensesChangeDate.isSameOrAfter(this.item.period.dateTo) && this.item.summary.expenses_missing);
        },
        dateFrom() {
            return moment(this.item.period.dateFrom).format('DD.MM.YYYY');
        },
        dateTo() {
            return moment(this.item.period.dateTo).format('DD.MM.YYYY');
        },
        currentClass() {
            return this.classes[this.index];
        },
        reportDateEnd() {
            return moment(this.store.stableDataEndDate).format('DD.MM.YYYY');
        },
        salesDateStart() {
            return moment(this.store.stableDataEndDate).add(1, 'days').format('DD.MM.YYYY');
        }
    },
    methods: {
        isPeriodOrdersLoading(period){
            if (this.$page.props.auth.data.orders_updating && this.$page.props.auth.data.orders_updating_first_month)
                return true;
            return this.$page.props.auth.data.orders_updating && !this.__isInRange(period, this.currentMonthRange) && !this.$page.props.auth.data.orders_updating_first_month;
        },
        changePeriod(period, samePeriod) {
            if(!samePeriod) {
                this.$emit('changed', period);
            }
        },
    },
    mounted() {
        if (this.item.period.id === this.currentRange.id) {
            this.$emit('changed', this.item.period);
        }
    }
}
</script>
