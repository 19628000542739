<template>
    <div class="filter-periods">
        <div v-if="styleType === 2" class="periodSelectBlocks">
            <template v-for="(period, key) in periods">
                <div v-if="period.id === 'custom'"
                     :id="'data-custom_range_'+randomIndex"
                     :class="{active: !datesAreDefaultLocal && currentRange.id === period.id}"
                     class="periodSelectBlock periodSelectBlock_custom">
                    <i class="periodSelectBlock__ico fm-check"></i>
                    <span>{{ period.title }}</span>
                </div>
                <div v-else
                     class="periodSelectBlock"
                     :class="{active: currentRange.id === period.id}"
                     @click="select(period)">
                    <i class="periodSelectBlock__ico fm-check"></i>
                    <span>{{ period.title }}</span>
                </div>
            </template>
        </div>
        <div v-else id="periodSelectDefault" :class="{ 'dropMenu-opened': opened }" class="dropDown-parent" v-click-outside="onClickOutside">
            <div v-on:click="open()" class="periodSelectInput input-dropDown">
                <label><span class="fm-calendar"></span></label>
                <div class="dropDownData">{{ currentRange.title }}</div>
            </div>
            <div class="dropdown--menu">
                <ul class="dropList">
                    <template v-for="period in periods">
                        <li v-if="period.id == 'custom'" data-custom_range>
                            <span>{{ period.title }}</span>
                        </li>
                        <li v-else>
                            <span v-on:click="select(period)">{{ period.title }}</span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: [
        'periods',
        'dateFrom',
        'dateTo',
        'current',
        'datesAreDefault',
        'styleType'
    ],
    data() {
        return {
            randomIndex:Math.floor(Math.random() * 100) + 1,
            opened: false,
            currentRange: this.current,
            datesAreDefaultLocal: this.datesAreDefault,
            from: null,
            to: null
        };
    },
    emits: ['changedDates'],
    methods: {
        open() {
            this.opened = !this.opened;
        },

        onClickOutside() {
            this.opened = false;
        },

        select(range) {
            this.currentRange = range;
            this.open();
            this.$emit('changedDates', {dateFrom: range.dateFrom, dateTo: range.dateTo,activeRange: range.id})
        },
        getPeriodById(id){
            let p = null;
            this.periods.forEach((period) => {
                if (period.id === id)
                    p = period;
            });
            return p;
        },
        sendEmit(data){
            this.$emit('changedDates', data)
        },
        dashboardCb(start, end) {
            this.from = start.format('YYYY-MM-DD');
            this.to = end.format('YYYY-MM-DD');
            this.sendEmit({dateFrom: this.from, dateTo: this.to,activeRange: 'custom'})
            this.currentRange = this.getPeriodById('custom');
            this.datesAreDefaultLocal = false;
            //$('#reportRange span').html(start.format('D MMMM YYYY').toLowerCase() + ' - ' + end.format('D MMMM YYYY').toLowerCase());
        },
        initCustomRange() {
            let customRange = $('#data-custom_range_'+this.randomIndex);
            let self = this;
            let start = this.$props.datesAreDefault ? moment() : moment(self.from);
            let end = this.$props.datesAreDefault ? null : moment(self.to);

            // function dashboardCb(start, end) {
            //     self.from = start.format('YYYY-MM-DD');
            //     self.to = end.format('YYYY-MM-DD');
            //     self.sendEmit({dateFrom: self.from, dateTo: self.to,activeRange: 'custom'})
            //     self.currentRange = self.getPeriodById('custom');
            //     self.datesAreDefaultLocal = false;
            //     //$('#reportRange span').html(start.format('D MMMM YYYY').toLowerCase() + ' - ' + end.format('D MMMM YYYY').toLowerCase());
            // }

            customRange.daterangepicker({
                startDate: start,
                endDate: end,
                parentEl: customRange.parent(),
                opens: 'right',
                drops: 'down',
                autoApply: true,

                locale: {
                    direction: 'ltr',
                    format: 'DD.MM.YYYY',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    fromLabel: 'с',
                    toLabel: 'по',
                    customRangeLabel: 'Произвольный период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    firstDay: 1
                },
                timePicker: false,
            }, this.dashboardCb);

            //dashboardCb(start, end);
        }
    },
    mounted() {
        this.from = this.$page.props.dateFrom;
        this.to = this.$page.props.dateTo;
        this.initCustomRange();
        this.periods.forEach((period) => {
            if (this.currentRange.id === period.id && this.currentRange.id != 'custom'){
                this.currentRange = period;

            }
        })
        if (this.currentRange.id === 'custom') {
            this.currentRange = this.getPeriodById('custom');
            this.datesAreDefaultLocal = false;
        }

        if(this.currentRange === undefined) {
            this.currentRange = this.periods[0];
        }

    }
}
</script>
