<template>
    <div v-if="!isHidden" class="top_hidden_text with_video">
        <div v-if="showVideo" class="video_block"
             v-bind:style="{ 'background': 'url(' + __('global.hint.'+ currentRoute +'.poster') + ') no-repeat center center / cover' }">
            <a class="play_link" data-toggle="modal" data-target="#videoModal">
                <span class="play_outer"></span>
            </a>
        </div>
        <div class="video_text">
            {{ __("global.hint." + currentRoute + ".text") }}
        </div>
        <button @click="hidden" class="hideText fm-x" type="button" aria-label="Закрыть"></button>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isHidden: false,
            currentRoute: 'dashboard',
            showVideo: true
        };
    },
    methods: {
        hidden() {
            this.isHidden = true;
            localStorage.setItem('hint_' + this.currentRoute, 'hidden');
        }
    },
    mounted() {
        this.currentRoute = this.route().current();

        // if (this.currentRoute === 'settings')
        //     this.showVideo = false;
        if (this.__("global.hint." + this.currentRoute + ".video") === ''
            || this.__("global.hint." + this.currentRoute + ".video") === "global.hint." + this.currentRoute + ".video"
        )
            this.showVideo = false;

        //temporary hidden
        //this.isHidden = true;
        this.isHidden = !!localStorage.getItem('hint_' + this.currentRoute);
    }
}
</script>
