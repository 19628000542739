<template>
    <Head title="Welcome"/>

    <BreezeAuthenticatedLayout>
        <template #header-title>
            <h1 class="page_title">{{ __("global.hint.welcome.title") }}</h1>
        </template>

    <div class="dash__board__container">
        <div class="main_container">
            <AccountConnected />
            <section class="welcome_title_section">
                <h1 class="section_title">Добро пожаловать в sellerdata!</h1>
                <p>Ваш аккаунт SellerData был успешно подключен.</p>
                <p>Очень скоро Ваш дэшборд будет заполнен данными</p>
            </section>
            <section class="welcome_intro">
                <div class="intro_inner">
                    <h2>Для начала работы в sellerdata мы <span>рекомендуем сделать следующие 3 шага:</span> </h2>
                    <ul class="intro_steps">
                        <li>
                            <div class="step-icon">
                                <span class="count_icon">1</span>
                            </div>
                            <div class="step_text">
                                Посмотрите наше вводное видео по<br> панели инструментов
                            </div>
                            <div class="video_block" v-bind:style="{ 'background': 'url(' + __('global.welcome.vid1.poster') + ') no-repeat center center / cover' }">
                                <a class="play_link" :data-mfp-src='__("global.welcome.vid1.video")'>
                                    <span class="play_outer"></span>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="step-icon">
                                <span class="count_icon">2</span>
                            </div>
                            <div class="step_text">
                                Введите себестоимость Ваших продуктов на странице <a href="/products">“Товары”</a>
                            </div>
                            <div class="video_block" v-bind:style="{ 'background': 'url(' + __('global.welcome.vid2.poster') + ') no-repeat center center / cover' }">
                                <a class="play_link" :data-mfp-src='__("global.welcome.vid2.video")'>
                                    <span class="play_outer"></span>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="step-icon">
                                <span class="count_icon">3</span>
                            </div>
                            <div class="step_text">
                                Введите Ваши расходы на странице <a href="/expenses">“Расходы”</a> <span class="c-dark_grey">(Опционально)</span>
                            </div>
                            <div class="video_block" v-bind:style="{ 'background': 'url(' + __('global.welcome.vid3.poster') + ') no-repeat center center / cover' }">
                                <a class="play_link" :data-mfp-src='__("global.welcome.vid3.video")'>
                                    <span class="play_outer"></span>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="step-icon">
                                <img src="img/bx_bx-chat.svg" alt="chat">
                            </div>
                            <div class="step_text large-text">
                                Вопросы? Ознакомьтесь с другими обучающими материалами <a href="https://www.youtube.com/channel/UCATz9OsGPl2u11V3mJtU9Dg/featured" target="_blank">здесь</a>, или напишите
                                <a href="mailto:support@sellerdata.ru" target="_blank">письмо</a>!
                            </div>
                        </li>
                    </ul>
                    <div class="welcome_button_section">
                        <a :href="this.route('dashboard')" class="btn-default btn-regular">Перейти в дэшборд</a>
                    </div>
                </div>
            </section>
        </div>
    </div>
    </BreezeAuthenticatedLayout>
</template>

<script>

import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import AccountConnected from "@/Components/Common/AccountConnected";
import { Head } from '@inertiajs/inertia-vue3';

export default {
    components: {
        AccountConnected,
        BreezeAuthenticatedLayout,
        Head,
    },
    props: [
        'user',
    ],
    mounted() {
        $('[data-mfp-src]').magnificPopup({type: 'iframe'});
    }
}
</script>
