<template>
    <div class="info-banner-outer warning">
        <div class="info-banner">
            <div class="icon__block"><span class="fm-icon-info"></span></div>
            <div class="info-banner-text">
                <p>
                    По понедельникам до 16:00 по МСК Wildberries временно закрывает доступ к данным по ключу в связи
                    с расчетом итоговых показателей за прошлую неделю. Это необходимо для формирования еженедельного
                    финансового отчета.
                    Как только данные появятся, мы их сразу же загрузим. До этого времени вы можете просматривать
                    оперативные данные, загруженные в нашу систему. Обратите внимание, что оперативные данные не полные
                    и могут отличаться от итоговых расчетных данных.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WildBerriesTechPause",
}
</script>
