<template>
    <BreezeAuthenticatedLayout @setAccountInfo="setAccountInfo">
        <div class="dash__board__container">
            <div class="main_container">
                <BreezeDashboardInfo/>

                <WarehouseSummary
                    ref="warehouseSummary"
                    :external-data-loading="externalDataLoading"
                    :warehouse-list="warehouseList"
                    :filter="filter"
                    @filter-submitted="filterSubmitted"
                    @filter-clear="filterClear"
                />

                <WarehouseProductsTable
                    :external-data-loading="externalDataLoading"
                    :filter="filter"
                    @page-changed="pageChanged"
                    @product-updated="productUpdated"
                    @notification="showNotificationFromObject"
                    @sort-data="sortData"
                />
            </div>
        </div>

        <transition name="fade">
            <div id="notificationMessage" v-if="notification.show" :class="notification.type" class="notification-message is-display-block notification-message_offset">
                <span>{{ notification.message }}</span>
            </div>
        </transition>

    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import BreezeDashboardInfo from '@/Components/Dashboard/DashboardInfo.vue'
import ProductsFilterModal from '@/Components/Warehouse/ProductsFilterModal.vue'
import WarehouseProductsTable from '@/Components/Warehouse/WarehouseProductsTable.vue'
import WarehouseSummary from '@/Components/Warehouse/WarehouseSummary.vue'

export default {
    name: 'Warehouse',
    components: {
        WarehouseSummary,
        WarehouseProductsTable,
        BreezeDashboardInfo,
        BreezeAuthenticatedLayout,
        ProductsFilterModal,
    },
    props: {
        warehouseList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            externalDataLoading: true,

            filter: {
                products: [],
                page: 1,
                warehouses: [],
                orderBy: 'quantity',
                desc: 1,
            },

            queryModified: false,

            productsData: {},
            productsPage: 1,

            productsSelected: [],
            notification: {
                show: false,
                message: 'Успешно скопировано',
                type:'info',
            },
        };
    },
    computed: {
        filterClientDataNS() {
            return `account-${this.$page.props.auth.user.user_account.account_id}/${this.$options.name}/filter`.toUpperCase();
        },
    },
    methods: {
        filterClear() {
            this.filter.products = [];
            this.filter.page = 1;
            this.filter.warehouses = [];

            localStorage.setItem(this.filterClientDataNS, JSON.stringify(this.filter));
        },
        setAccountInfo({products_updating}){
            this.externalDataLoading = !!products_updating;
        },
        productUpdated() {
            this.$refs.warehouseSummary.load();
        },
        pageChanged(data) {
            this.filter.page = data;
        },
        sortData: function (data) {
            const filter = this.filter;

            if (filter.orderBy === data.column) {
                filter.desc = filter.desc === 1 ? 0 : 1;
            } else {
                filter.desc = 1;
            }

            filter.orderBy = data.column;
            filter.page = 1;

            this.filter = filter;
        },
        filterSubmitted(filterData) {
            const filter = filterData
            filter.page = 1;

            this.filter = filter;
            this.productUpdated();
        },
        showNotification: function (message,type = 'info') {
            let self = this;
            this.notification.show = true;
            this.notification.message = message;
            this.notification.type = type;
            setTimeout(function () {
                self.notification.show = false;
            }, 3000);
        },
        showNotificationFromObject(data){
            this.showNotification(data.message,data.type);
        }
    },
    created () {
        const clientFilterData = localStorage.getItem(this.filterClientDataNS);
        if (clientFilterData) {
            const filterData = JSON.parse(clientFilterData);

            filterData.page = 1;

            this.filter = filterData;
        }
    },
    watch: {
        filter: {
            handler: function (val) {
                localStorage.setItem(this.filterClientDataNS, JSON.stringify(val));
            },
            deep: true,
        },
    }
}
</script>
