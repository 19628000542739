<template>
    <a
        class="item__link"
        href="#"
        @click.prevent="showEditModal = true"
    >
        Себест.:
        <div v-if="costEdited" class="loading-placeholder">
            <span class="visually-hidden">Loading...</span>
        </div>
        <span v-else><Currency :value="cost"/></span>
    </a>
    <template v-if="showEditModal">
        <Teleport to="body">
            <ProductsCostModal
                :product-i-d="id"
                @closed="costEditFinished"
                @reload="costEditFinished(true)"
            />
        </Teleport>
    </template>
</template>

<script>
import Currency from '@/Components/Common/Currency.vue'
import ProductsCostModal from '@/Components/Products/ProductsCostModal.vue'

export default {
    name: 'ProductCost',
    components: { ProductsCostModal, Currency },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            id: this.product.id,
            cost: this.product.cost,
            showEditModal: false,
            costEdited: false,
        }
    },
    emits: ['product-updated'],
    methods: {
        costEditFinished(updated = false) {
            this.showEditModal = false
            if (updated) {
                this.$emit('product-updated')
                this.costEdited = true
            }
        },
    },
    watch: {
        product: {
            handler() {
                this.cost = this.product.cost
                this.costEdited = false
            },
            deep: true,
        },
    },
}
</script>
