<template>
    <Head title="Настройки"/>

    <BreezeAuthenticatedLayout ref="layout">
        <div class="dash__board__container">
            <div class="main_container">
                <BreezeDashboardInfo></BreezeDashboardInfo>
                <div class="dashboard_panel settings">
                    <div class="dashboard_panel_inner">
                        <h2 class="sep-title">Аккаунты</h2>
                        <div class="settings__accounts">
                            <AccountRow
                                v-for="account in store.accountList"
                                v-if="store.accountList.length"
                                :allowdelete="store.accountList.length > 1 && account.id !== store.currentAccount"
                                :userAccount="account"
                                @notification="showNotification"
                                @reload="reloadPage"
                            />
                            <div v-if="isAvailable('business')">
                                <a class="btn-default btn-regular" :href="route('connecting') + '?new'">Добавить магазин</a>
                            </div>
                        </div>

                        <OtherSettingsForm @showNotification="showNotification"></OtherSettingsForm>

                        <div class="settings__block">
                            <EmailSettingsForm @showNotification="showNotification"></EmailSettingsForm>
                        </div>

                        <div class="settings__block">
                            <h3 class="settings__block__title">Безопасность</h3>
                            <a class="dark_link" href="javascript:;" @click="changePasswordRequest">Изменить пароль</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div id="notificationMessage" v-if="notification.show" :class='notification.type'
                 class="notification-message is-display-block">
                <span>{{ notification.message }}</span>
            </div>
        </transition>
    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import {Head} from '@inertiajs/inertia-vue3';
import BreezeInput from '@/Components/Input.vue'
import BreezeDashboardInfo from '@/Components/Dashboard/DashboardInfo.vue'
import Inputmask from "inputmask";
import AccountRow from "@/Components/Settings/AccountRow.vue";
import Select2 from 'vue3-select2-component';
import OtherSettingsForm from "@/Components/Settings/OtherSettingsForm.vue";
import EmailSettingsForm from "@/Components/Settings/EmailSettingsForm.vue";
import { store } from '@/store';

export default {
    name: 'Settings',
    components: {
        EmailSettingsForm,
        OtherSettingsForm,
        AccountRow,
        BreezeAuthenticatedLayout,
        BreezeInput,
        Head,
        Inputmask,
        BreezeDashboardInfo,
        Select2,
    },
    data() {
        return {
            form: this.$inertia.form({
                title: '',
                'updates_subscription': false
            }),
            errors: {
                name: null
            },
            notification: {
                show: false,
                message: 'Успешно скопировано',
                type: 'info',
            },
            resendingTimer: null,
            accountList: [],
            store,
        }
    },
    mounted() {
        this.form.title = this.$page.props.auth.data.current_account.title ?? 'Основой аккаунт';
        this.form.updates_subscription = !!this.$page.props.auth.data.updates_subscription;

        this.resendEmailTimerProcess();
        this.tippyTooltips();

        if (document.location.hash === '#changeApiKey') {
            this.$refs.apiKeyForm.click();
        }

    },
    watch: {
        store: {
            handler: function (val) {
                this.accountList = val.accountList;
            },
            deep: true
        }
    },
    methods: {
        isAvailable: function (packageType) {
            return this.store.subscription.trial || store.subscription.type === packageType;
        },
        hideZeroCost(cost, event) {
            if (cost == 0) {
                event.target.value = '';
            }
        },
        changePasswordRequest: function () {
            this.$inertia.post(this.route('settings.changePassword'), {}, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {

                },
                onError: (error) => {
                    console.log(error)
                }
            })
            /*axios.post(this.route('settings.changePassword'), {})
                .then(function (response) {

                })
                .catch(function (error) {
                    self.showNotification(error.response.data.message);
                });*/
        },
        resendEmail: function () {
            if (this.isResendingEmail)
                return false;
            let self = this;
            self.isResendingEmail = true;
            axios.get(this.route('sendConfirmEmail'), {})
                .then(function (response) {
                    localStorage.setItem('isResendingEmail', moment().unix());
                    self.resendEmailTimerProcess();
                })
                .catch(function (error) {
                    self.showNotification(error.response.data.message);
                });
        },
        resendEmailTimerProcess: function () {
            let self = this;
            let timerStartedAt = Number(localStorage.getItem('isResendingEmail'));
            if (!timerStartedAt)
                return false;
            let timerEndedAt = timerStartedAt + 60;
            let secondsLeft = timerEndedAt - moment().unix();
            if (secondsLeft > 0) {
                this.isResendingEmail = true;
                this.resendingTimer = setTimeout(function () {
                    self.isResendingEmail = false;
                    localStorage.removeItem('isResendingEmail');
                }, secondsLeft * 1000);
            } else
                localStorage.removeItem('isResendingEmail');

        },
        saveSettings() {
            let self = this;
            this.form.post(this.route('settings.update'), {
                preserveScroll: true,
                onSuccess: function (response) {
                    self.showNotification('Данные сохранены');
                },
                onError: function (errors) {
                    //if (errors.modal)
                    //    $('#errorKey').modal();
                },
            });
        },
        reloadPage() {
            this.$refs.layout.fetchAccountInfo()
            // Inertia.reload({preserveState: true, preserveScroll: true});
        },
        formatDate(dateString) {
            return moment(dateString).format('DD.MM.YYYY');
        },
        formatDateSubmit(dateString) {
            let splitted = dateString.split('.');
            return splitted[2] + '-' + splitted[1] + '-' + splitted[0];
        },
        showNotification: function (message, type = 'info') {
            let self = this;
            this.notification.show = true;
            this.notification.message = message;
            this.notification.type = type;
            setTimeout(function () {
                self.notification.show = false;
            }, 3000);
        },
        tippyTooltips() {
            const typpyInstance = tippy('[data-tippy-content]', {
                trigger: 'mouseenter click',
                maxWidth: 340,
                placement: 'bottom',
                arrow: false,
                zIndex: 7
            });
        },
    }
}
</script>
