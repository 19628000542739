<template>
    <div class="table_outer">
        <div class="table-tabs">
            <ul class="nav nav-pills">
                <li class="table-tabs__actions">
                    <div class="group-options-wrapper">
                        <span class="group-options-title" v-show="!(groupBy === 'noGrouping' || groupBy === '')">Группировать по</span>

                        <div id="group-by-sku" class="group-options" :disabled="loading">
                            <Select2
                                v-model="groupBy"
                                @select="changeSalesOrdering"
                                :options="salesGroupOptions"
                                :settings="{ theme: 'sd_select_narrow', minimumResultsForSearch: -1, prompt: 'bySku', placeholder: 'Группировать по', allowClear: false, width: '100%', dropdownParent: '#group-by-sku', templateSelection: formatOrderingState}"
                            />
                        </div>
                    </div>
                </li>
            </ul>
            <div class="expenses-wrapper" :class="{'no_items': products.length === 0 && !loading}">
                <TableLoader :show="loading"/>
                <TableNoData :show="products.length === 0 && !loading"/>

                <table v-if="products.length > 0 && !loading" class="table table-condensed table-hover table-fw-widget warehouse-table">
                    <thead>
                        <tr>
                            <th style="width: 20%; min-width: 100px;">Товары</th>
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="quantity"
                                name="Кол-во на складе"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.quantity')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="quantity_reserved"
                                name="Кол-во в резерве"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.quantity_reserved')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="warehouse_cost"
                                name="Себестоимость склада"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.warehouse_cost')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="sales_speed"
                                name="Скорость продаж (ед. в день)"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.sales_speed')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="quantity_days"
                                name="Остаток на складе в днях"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.quantity_days')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="intermediate_warehouse"
                                name="Кол-во на промежуточном складе"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.intermediate_warehouse')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="purchase"
                                name="Объем закупки"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.purchase')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="order_days"
                                name="Срок выполнения заказа"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.order_days')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="next_order_days"
                                name="Дней до следующего заказа"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.next_order_days')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="recommend_to_order"
                                name="Рекомендуемое кол-во для дозаказа"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.recommend_to_order')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <SortableTh
                                style="width: 5%; min-width: 50px;"
                                sort-key="roi"
                                name="ROI"
                                :filter="filter"
                                :tooltip="__('hints.warehouse.table.roi')"
                                @sort-data="$emit('sort-data', $event)"
                            />
                            <th style="width: 1%; min-width: 50px;"><span class="hide-xs">Действия</span></th>
                        </tr>
                    </thead>
                    <tbody>
                            <WarehouseProductsTableRow
                                :groupBy="groupBy"
                                v-for="product in products"
                                :key="product.id"
                                :product="product"
                                @productUpdated="productUpdated"
                                @show-more="onShowMore"
                                @notification="$emit('notification',$event)"
                            />
                    </tbody>
                </table>
            </div>

            <div v-if="pagination.length > 3 && !loading" class="table-last-section">
                <Pagination
                    :current-page="paginatedProducts.current_page"
                    :total-pages="paginatedProducts.last_page"
                    :next="paginatedProducts.next_page_url"
                    :previous="paginatedProducts.prev_page_url"
                    :links="pagination"
                    @page-selected="loadPage"
                />
            </div>
        </div>

        <template v-if="showProductDetailsModal">
            <Teleport to="body">
                <ProductDetailsModal
                    :product="productDetailsModalData"
                    @close="showProductDetailsModal = false"
                    @product-updated="productUpdated"
                    @notification="$emit('notification',$event)"
                />
            </Teleport>
        </template>
    </div>
</template>

<script>
import WarehouseProductsTableRow from '@/Components/Warehouse/WarehouseProductsTableRow'
import TableLoader from '@/Components/Common/TableLoader.vue'
import TableNoData from '@/Components/Common/TableNoData.vue'
import SortableTh from '@/Components/Common/SortableTh.vue'
import ProductDetailsModal from '@/Components/Warehouse/ProductDetailsModal.vue'
import Pagination from '@/Components/Common/Pagination.vue'
import Modal from '@/Components/Common/Modal.vue'
import Select2 from 'vue3-select2-component'

export default {
    name: 'WarehouseProductsTable',
    components: {
        Modal,
        Pagination,
        ProductDetailsModal,
        SortableTh,
        TableLoader,
        TableNoData,
        WarehouseProductsTableRow,
        Select2,
    },
    props: {
        filter: {
            type: Object,
            required: true,
        },
        externalDataLoading: Boolean,
    },
    data() {
        return {
            paginatedProducts: {},
            loading: true,
            showProductDetailsModal: false,
            productDetailsModalData: {},
            groupBy: this.filter.groupBy ? 'bySku' : 'noGrouping',
            salesGroupOptions: [{id: 'noGrouping', text: 'Не группировать'}, {id: 'bySku', text: 'Артикулу'}],
        };
    },
    computed: {
        products() {
            return this.loading ? [] : this.paginatedProducts.data;
        },
        pagination() {
            return this.loading ? [] : this.paginatedProducts.links;
        }
    },
    emits: ['page-changed', 'product-updated', 'sort-data','notification'],
    methods: {
        productUpdated() {
            this.loadProducts(false);
            this.$emit('product-updated');
        },
        loadPage(page) {
            this.$emit('page-changed', page);
        },
        loadProducts(loading = true) {
            this.loading = loading;

            axios.get('/warehouse/products', {
                params: this.filter,
            })
                .then(response => {
                    this.paginatedProducts = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        onShowMore(product) {
            this.productDetailsModalData = product;
            this.showProductDetailsModal = true;
        },
        formatOrderingState(state) {
            var $state = 'Группировать по'
            if (!state.id) {
                return state.text;
            } else if (state.id === 'noGrouping') {
                return $state;
            } else {
                return state.text.toLowerCase();
            }
        },
        changeSalesOrdering(data) {
            this.filter.groupBy = data.id === 'bySku' ? 1 : 0;
            this.loadProducts();
        },
    },
    watch: {
        products: function (val) {
            if (this.showProductDetailsModal) {
                this.productDetailsModalData = val.find((product) => {
                    return product.id === this.productDetailsModalData.id;
                });
            }
        },
        filter: {
            handler: function () {
                this.loadProducts();
            },
            deep: true,
        },
        externalDataLoading: function (val) {
            if (!val) {
                this.loadProducts();
            }
        },
    },

    mounted() {
        if (typeof window.isDashboardProductsTableJsMounted == 'undefined'){
            window.isDashboardProductsTableJsMounted = true;
        }
    },
}
</script>
